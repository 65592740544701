import { gql } from "../../__generated__/gql";

export const RefreshSubscriptionUser = gql(/* Graphql */ `
    query RefreshSubscriptionUser($where: UserWhereUniqueInput!) {
        user(where: $where) {
            id
            subscriptionType
            subscriptionInterval
        }
    }
`);
