import { observable } from "mobx";
import { EnumAiFeedbackEntity } from "../__generated__/graphql";
import Attribute from "./decorators/Attribute";
import PersistModel from "./PersistModel";

class AiFeedback extends PersistModel {
  @Attribute
  @observable
  aiContext?: any | null;

  @Attribute
  @observable
  userId: string;

  @Attribute
  @observable
  toolId: any;

  @Attribute
  @observable
  runId: string;

  @Attribute
  @observable
  feedback: any;

  @Attribute
  @observable
  entity: EnumAiFeedbackEntity;

  @Attribute
  @observable
  entityId?: string | null;

  @Attribute
  @observable
  parentEntityId?: string | null;
}

export default AiFeedback;
