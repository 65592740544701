import { action, autorun, computed, observable } from "mobx";
import { generateRandomColor500 } from "../colors-list";
import { CalendarView, EditorConnectionStatus } from "../types";
import LocalStorage from "../utils/LocalStorage";
import { theme } from "../utils/theme";
import {
  InstantResourcesVisibility,
  defaultInstantResourcesVisibility,
} from "../components/DifferentiatedResourceGenerator/outputOptions";

export enum Theme {
  Light = "light",
  Dark = "dark",
}

const STORE_NAME = "UI";

let appSidebarHidden = window.location.search.includes(
  "appSidebarVisible=false"
);

export default class UiStore {
  @observable
  webBrowsing: boolean = false;

  @observable
  calendarView: CalendarView = "week";

  @observable
  activeChatId?: string;

  @observable
  activeToolId?: string;

  @observable
  activeResourceId?: string;

  @observable
  theme: Theme = Theme.Light;

  @observable
  mainSidebarWidth: number;

  @observable
  secondarySidebarWidth: number;

  @observable
  secondarySidebarCollapsed: boolean;

  @observable
  mainSidebarResizing: boolean;

  @observable
  plannerColorsMap: { [key: string]: string } = {};

  @observable
  courseColorsMap: { [key: string]: string } = {};

  @observable
  userColorsMap: { [key: string]: string } = {};

  @observable
  editorConnectionStatus: EditorConnectionStatus;

  @observable
  noScrollBounceRef: HTMLDivElement | null = null;

  @observable
  collaborationColor = generateRandomColor500();

  @observable
  instantResourcesVisibility: InstantResourcesVisibility[] = [];

  @observable
  showOnboardingChecklist = false;

  @action
  setActiveChatId = (id: string) => {
    this.activeChatId = id;
  };

  @action
  setShowOnboardingChecklist = (show: boolean) => {
    this.showOnboardingChecklist = show;
  };

  // ------------------ Mobile ------------------

  @observable
  showMainSidebarMobile = false;

  @observable
  showSecondarySidebarMobile = false;

  @observable
  pageTitleMobile = "Ask Alayna";

  @observable
  secondarySidebarButtonTextMobile = "Chat History";

  @observable
  showSecondarySidebarButtonMobile = true;

  @action
  setWebBrowsing = (webBrowsing: boolean) => {
    this.webBrowsing = webBrowsing;
  };

  @action
  setShowMainSidebarMobile = (show: boolean) => {
    this.showMainSidebarMobile = show;
  };

  @action
  setShowSecondarySidebarMobile = (show: boolean) => {
    this.showSecondarySidebarMobile = show;
  };

  @action
  setSecondarySidebarButtonTextMobile = (text: string) => {
    this.secondarySidebarButtonTextMobile = text;
  };

  @action
  setPageTitleMobile = (title: string) => {
    this.pageTitleMobile = title;
  };

  @action
  setShowSecondarySidebarButtonMobile = (show: boolean) => {
    this.showSecondarySidebarButtonMobile = show;
  };

  // ------------------ Mobile ------------------

  @action
  setActiveToolId = (id: string | undefined) => {
    this.activeToolId = id;
  };

  @action
  setActiveResourceId = (id: string | undefined) => {
    this.activeResourceId = id;
  };

  @action
  setInstantResourcesVisibility = (
    visibility: InstantResourcesVisibility[]
  ) => {
    this.instantResourcesVisibility = visibility;
  };

  @observable
  showMobileSidebar = false;

  constructor() {
    // Rehydrate
    const data: Partial<UiStore> = LocalStorage.retrieve(STORE_NAME) || {};

    // Set the values for the keys we have in storage
    this.calendarView = data.calendarView || "week";
    this.activeChatId = data.activeChatId;
    this.mainSidebarWidth = theme.mainSidebarWidth;
    this.secondarySidebarWidth = theme.secondarySidebarWidth;
    this.plannerColorsMap = data.plannerColorsMap || {};
    this.userColorsMap = data.userColorsMap || {};
    this.courseColorsMap = data.courseColorsMap || {};
    this.webBrowsing = data.webBrowsing || false;
    this.instantResourcesVisibility =
      data.instantResourcesVisibility || defaultInstantResourcesVisibility;

    autorun(() => {
      LocalStorage.save(STORE_NAME, this.toLocalLocalStorageData);
    });
  }

  @action
  setMainSidebarWidth = (width: number) => {
    this.mainSidebarWidth = width;
  };

  @action
  setSecondarySidebarWidth = (width: number) => {
    this.secondarySidebarWidth = width;
  };

  @action
  setMainSidebarResizing = (resizing: boolean) => {
    this.mainSidebarResizing = resizing;
  };

  @action
  toggleMobileSidebar = () => {
    this.showMobileSidebar = !this.showMobileSidebar;
  };

  @action
  hideMobileSidebar = () => {
    this.showMobileSidebar = false;
  };

  @action
  toggleMainSidebar = () => {
    this.showMainSidebarMobile = !this.showMainSidebarMobile;
  };

  @computed
  get toLocalLocalStorageData() {
    return {
      calendarView: this.calendarView,
      activeChatId: this.activeChatId,
      mainSidebarWidth: this.mainSidebarWidth,
      secondarySidebarWidth: this.secondarySidebarWidth,
      plannerColorsMap: this.plannerColorsMap,
      userColorsMap: this.userColorsMap,
      courseColorsMap: this.courseColorsMap,
      webBrowsing: this.webBrowsing,
      instantResourcesVisibility: this.instantResourcesVisibility,
    };
  }

  @action
  setNoScrollBounceRef = (ref: HTMLDivElement | null) => {
    this.noScrollBounceRef = ref;
  };

  @action
  getPlannerColor = (id: string) => {
    // Check if the color is already in the map
    if (this.plannerColorsMap[id]) {
      return this.plannerColorsMap[id];
    }
    // If not, generate a new color and add it to the map
    const color = generateRandomColor500();

    this.plannerColorsMap[id] = color;

    return color;
  };

  @action
  getCourseColor = (id: string) => {
    // Check if the color is already in the map
    if (this.courseColorsMap[id]) {
      return this.courseColorsMap[id];
    }
    // If not, generate a new color and add it to the map
    const color = generateRandomColor500();

    this.courseColorsMap[id] = color;

    return color;
  };

  @action
  setCalendarView = (view: CalendarView) => {
    this.calendarView = view;
  };

  @action
  getUserColor = (id: string) => {
    // Check if the color is already in the map
    if (this.userColorsMap[id]) {
      return this.userColorsMap[id];
    }
    // If not, generate a new color and add it to the map
    const color = generateRandomColor500();

    this.userColorsMap[id] = color;

    return color;
  };

  @action
  setEditorConnectionStatus = (status: EditorConnectionStatus) => {
    this.editorConnectionStatus = status;
  };

  @action
  clear = () => {
    this.calendarView = "week";
    this.activeChatId = undefined;
    this.activeToolId = undefined;
    this.activeResourceId = undefined;
    this.mainSidebarWidth = theme.mainSidebarWidth;
    this.secondarySidebarWidth = theme.secondarySidebarWidth;
    this.secondarySidebarCollapsed = false;
    this.plannerColorsMap = {};
    this.userColorsMap = {};
    this.courseColorsMap = {};
    this.webBrowsing = false;
  };
}
