import { observable } from "mobx";
import Attribute from "./decorators/Attribute";

import { EnumAiToolParticipantPermissions } from "../__generated__/graphql";
import PersistModel from "./PersistModel";

// Create an Enum for "curriculum-creator" | "instant-resources" | "image-generator"
export enum EnumAiToolType {
  CURRICULUM_CREATOR = "curriculum-creator",
  INSTANT_RESOURCES = "instant-resources",
  IMAGE_GENERATOR = "image-generator",
}

class AiToolParticipant extends PersistModel {
  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  aiToolId: string;

  @Attribute
  @observable
  aiToolType: EnumAiToolType;

  @Attribute
  @observable
  owner?: boolean | null;

  @Attribute
  @observable
  permissions: EnumAiToolParticipantPermissions[];

  @Attribute
  @observable
  userId: string;

  @Attribute
  @observable
  firstName?: string | null;

  @Attribute
  @observable
  lastName?: string | null;

  @Attribute
  @observable
  email?: string | null;

  @Attribute
  @observable
  avatarUrl?: string | null;
}

export default AiToolParticipant;
