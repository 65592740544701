import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend"; // Ensure to install i18next-http-backend
// import LanguageDetector from "i18next-browser-languagedetector"; // Ensure to install i18next-browser-languagedetector for language detection

export function unicodeBCP47toCLDR(locale: string) {
  return locale.replace("-", "_").replace("und", "root");
}

export const initializeI18n = (options?: InitOptions) => {
  i18n
    .use(Backend) // Use the Backend plugin for lazy loading
    .use(initReactI18next)
    .init({
      // resources,
      backend: {
        // Configuration of the backend plugin to load translation files
        loadPath: (languages: string[]) => {
          console.log("languages", languages);
          console.log(
            "unicodeBCP47toCLDR(languages[0])",
            unicodeBCP47toCLDR(languages[0])
          );

          const languageCode =
            unicodeBCP47toCLDR(languages[0]) === "dev"
              ? "En"
              : unicodeBCP47toCLDR(languages[0]);

          return `/translations/${languageCode}.json`;
        },
        // addPath: '/translations/{{lng}}.json', // Uncomment and adjust if your server supports adding missing translations
      },
      // fallbackLng: "En", // default language
      // lng: "En", // default language
      interpolation: {
        escapeValue: false, // React already protects from XSS
      },
      ...options, // allows passing custom init options
    });

  return i18n;
};
