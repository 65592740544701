import { Link } from "react-router-dom";
import { Container } from "./Container";
import { NavLink } from "./NavLink";

const FACEBOOK_GROUP = "https://www.facebook.com/groups/alaynaforeducators/";
const YOUTUBE_PAGE = "https://www.youtube.com/channel/UCQ93ZZBOcAdmgoME9W8abQw";
const LINKED_IN_PAGE = "https://www.linkedin.com/company/alayna/";
const X_Page = "https://twitter.com/joinalayna";
const TIKTOK_PAGE = "https://www.tiktok.com/@alaynaai";

export function Footer() {
  return (
    <footer className="bg-slate-50">
      <Container>
        <div className="py-8 sm:py-16 flex flex-col sm:flex-row justify-between">
          {/* Logo */}
          <div className="flex justify-center sm:justify-start mb-8 sm:mb-0">
            <img
              loading="lazy"
              src={"/imgs/logos/logo.png"}
              alt="Alayna AI"
              className="h-8 w-auto"
            />
          </div>

          {/* Resources and Schools & Districts Navigation */}
          <div className="flex gap-8 justify-center">
            {/* Resources column */}
            <div className="flex-col">
              <h4 className="text-md font-semibold pb-1 ml-2">Resources</h4>
              <nav
                aria-label="Resources links"
                className="flex-col flex text-md"
              >
                <NavLink to="https://www.alayna.us/use-cases">
                  Use Cases
                </NavLink>
                <NavLink to="https://www.alayna.us/testimonials">
                  Testimonials
                </NavLink>
                <NavLink to="https://www.alayna.us/tutorials">
                  Tutorials
                </NavLink>
                <NavLink to="https://www.alayna.us/pricing">Pricing</NavLink>
                <NavLink to={FACEBOOK_GROUP}>Facebook Group</NavLink>
                <NavLink to="https://www.alayna.us/blog">Blog</NavLink>
              </nav>
            </div>

            {/* Schools & Districts column */}
            <div className="flex-col">
              <h4 className="text-md font-semibold pb-1 ml-2">
                For Schools & Districts
              </h4>
              <nav
                aria-label="Schools & Districts links"
                className="flex-col flex text-md"
              >
                <NavLink to="https://www.canva.com/design/DAF5XKzIa_o/w8uqUIQL-wLbxH9s3q3Ixg/view?utm_content=DAF5XKzIa_o&utm_campaign=designshare&utm_medium=link&utm_source=editor">
                  PD Presentation
                </NavLink>
                <NavLink to="https://calendly.com/hrishi-vora/alayna-intro">
                  Book A Demo
                </NavLink>
                <NavLink to="https://www.alayna.us/privacy">
                  Privacy Policy
                </NavLink>
                <NavLink to="https://www.alayna.us/terms">Terms of Use</NavLink>
              </nav>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center border-t border-slate-400/10 py-6 sm:flex-row-reverse sm:justify-between sm:py-10">
          {/* Social icons */}
          <div className="flex gap-x-6">
            <Link
              to={YOUTUBE_PAGE}
              className="group"
              aria-label="Alayna on YouTube"
            >
              <img
                loading="lazy"
                src={"/imgs/logos/youtube.png"}
                className="h-10 w-10"
                alt="YouTube"
              />
            </Link>
            <Link
              to={FACEBOOK_GROUP}
              className="group"
              aria-label="Alayna on Facebook"
            >
              <img
                loading="lazy"
                src={"/imgs/logos/facebook.png"}
                className="h-10 w-10"
                alt="Facebook"
              />
            </Link>
            <Link
              to={LINKED_IN_PAGE}
              className="group"
              aria-label="Alayna on LinkedIn"
            >
              <img
                loading="lazy"
                src={"/imgs/logos/linkedin.png"}
                className="h-10 w-10"
                alt="LinkedIn"
              />
            </Link>
            <Link to={X_Page} className="group" aria-label="Alayna on X">
              <img
                loading="lazy"
                src={"/imgs/logos/x.png"}
                className="h-10 w-10"
                alt="X"
              />
            </Link>
            <Link
              to={TIKTOK_PAGE}
              className="group"
              aria-label="Alayna on TikTok"
            >
              <img
                loading="lazy"
                src={"/imgs/logos/tiktok.png"}
                className="h-10 w-10"
                alt="TikTok"
              />
            </Link>
          </div>
          {/* Copyright text */}
          <div className="flex items-center mt-4 sm:mt-0 sm:text-left space-x-4">
            <p className="text-center text-sm text-slate-500">
              Copyright &copy; {new Date().getFullYear()} Cues Technologies Inc.
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
}
