import { ApolloClient } from "@apollo/client";
import { action, autorun, computed, observable } from "mobx";
import { v4 as uuidV4 } from "uuid";

export default class BlogStore {
  @observable
  isLoading = false;

    // Session ID will be used to identify which blog the user is currently working on
  @observable
  autofillFieldStatusMap: {
    [key: string]: string;
  } = {};

  @observable
  autofillFieldTokenMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};

  @observable
  autofillFieldCompleteMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};

  @observable
  autofillFieldRunIDMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};

  @action
  setAutofillFieldStatusMap = async (
    sessionId: string,
    fieldId: string,
    userId: string,
    status: string,
    runId?: string
  ) => {
    const updateFieldStatusMap = {
      ...this.autofillFieldStatusMap,
    };

    const updateFieldTokenMap = {
      ...this.autofillFieldTokenMap,
    };

    const updateFieldCompleteMap = {
      ...this.autofillFieldCompleteMap,
    };

    const updateFieldRunIDMap = {
      ...this.autofillFieldRunIDMap,
    };

    const key = `${sessionId}_${fieldId}`;

    // We remove the status & field => move response to complete field
    if (status === "END") {
      delete updateFieldStatusMap[key];

      const copyStream = updateFieldTokenMap[key];

      delete updateFieldTokenMap[key];

      updateFieldCompleteMap[key] = copyStream;

      this.autofillFieldTokenMap = updateFieldTokenMap;
      this.autofillFieldCompleteMap = updateFieldCompleteMap;

      // Update the runID map
      if (runId) {
        updateFieldRunIDMap[key] = runId;
        this.autofillFieldRunIDMap = updateFieldRunIDMap;
      }
    } else {
      updateFieldStatusMap[key] = status;
    }

    this.autofillFieldStatusMap = updateFieldStatusMap;
  };

  @action
  setAutofillFieldTokenMap = async (
    sessionId: string,
    fieldId: string,
    userId: string,
    tokenCount: number,
    token: string
  ) => {
    const updateAutofillTokenMap = {
      ...this.autofillFieldTokenMap,
    };

    const key = `${sessionId}_${fieldId}`;

    // It may not exist
    const updateTokenMap = this.autofillFieldTokenMap[key]
      ? { ...this.autofillFieldTokenMap[key] }
      : {};

    // Now we set the token
    updateTokenMap[tokenCount] = token;

    // Now we update main autofill map with token map
    updateAutofillTokenMap[key] = updateTokenMap;

    this.autofillFieldTokenMap = updateAutofillTokenMap;
  };


  @action
  clearAutofillFieldCompleteMap = () => {
    this.autofillFieldCompleteMap = {};
  };

  @action
  clearAutofillFieldRunIDMap = () => {
    this.autofillFieldRunIDMap = {};
  };



  @action
  clear = () => {

  };
}
