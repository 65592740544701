import { createQueryString } from "./createQueryString";

export function signupRoute(): string {
  return "/signup";
}

export function loginRoute(): string {
  return "/login";
}

export function homeRoute(): string {
  return "/";
}

export function onboardingRoute(): string {
  return "/onboarding";
}

export function conversationRoute(chatId: string): string {
  return `/c/${chatId}`;
}

export function toolsRoute(categoryId?: string): string {
  return categoryId ? `/tools/${categoryId}` : "/tools";
}

export function toolInitRoute(toolId: string): string {
  return `/tool/${toolId}`;
}

export function activeToolRoute(toolId: string): string {
  return `/t/${toolId}`;
}

export function instantResourcesRoute(): string {
  return "/instant-resources";
}

export function instantResourcesOutputRoute(toolId: string): string {
  return `/instant-resources/${toolId}`;
}

export function imageGeneratorRoute(): string {
  return "/image-generator";
}

export function slidesGeneratorRoute(): string {
  return "/slides";
}

export function imageGeneratorOutputRoute(toolId: string): string {
  return `/image-generator/${toolId}`;
}

export function searchRoute(): string {
  return "/search";
}

export function gradingRoute(): string {
  return "/grading";
}

export function grading(): string {
  return "/grading";
}

export function tutorialsRoute(): string {
  return "/tutorials";
}

export function facebookTeacherCommunityRoute(): string {
  return "https://www.facebook.com/groups/alaynaforeducators/";
}

export function recentsRoute(): string {
  return "/recent";
}

export function driveRoute(driveId: string): string {
  return `/drive/${driveId}`;
}

export function settingsRoute(setting: String): string {
  return `/settings/${setting}`;
}

export function accountProfileRoute(): string {
  return "/settings/profile";
}

// Resource related URLs
export function newResourceRoute(
  args: {
    resourceType: string;
    title?: string;
    driveId?: string;
    folderId?: string;
    // Add other fields such as plannerId, etc.
  } = {
    resourceType: "ProseMirrorDoc",
  }
): string {
  return args ? `/resource/new?${createQueryString(args)}` : "/resource/new";
}

export function editResourceRoute(resourceId: string) {
  return `/resource/${resourceId}/edit`;
}

export function shareResourceRoute(urlId: string) {
  return `/share/r/${urlId}`;
}

// Demo ROUTES

export function demoChatRoute(): string {
  return "/demo/chat";
}

export function demoInstantResourcesRoute(): string {
  return "/demo/instant-resources";
}

export function demoInstantResourcesOutputRoute(toolId: string): string {
  return `/demo/instant-resources/${toolId}`;
}

export function demoAdvancedToolsRoute(): string {
  return "/demo/tools";
}

export function demoImageGeneratorRoute(): string {
  return "/demo/image-generator";
}

export function demoImageGeneratorOutputRoute(toolId: string): string {
  return `/demo/image-generator/${toolId}`;
}

export function demoGradingRoute(): string {
  return "/demo/grading";
}

export function demoConversationRoute(chatId: string): string {
  return `/demo/c/${chatId}`;
}

export function demoToolsRoute(categoryId?: string): string {
  return categoryId ? `/demo/tools/${categoryId}` : "/demo/tools";
}

export function demoToolInitRoute(toolId: string): string {
  return `/demo/tool/${toolId}`;
}

export function demoActiveToolRoute(toolId: string): string {
  return `/demo/t/${toolId}`;
}
