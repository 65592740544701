import { ApolloClient } from "@apollo/client";
import AbilitiesStore from "./AbilitiesStore";
import AiFavoriteStore from "./AiFavoriteStore";
import AiFeedbackStore from "./AiFeedbackStore";
import AiToolParticipantsStore from "./AiToolParticipantsStore";
import AiToolsStore from "./AiToolsStore";
import AiToolStepsStore from "./AiToolStepsStore";
import AssetsStore from "./AssetsStore";
import AuthStore from "./AuthStore";
import BotConversationsEntriesStore from "./BotConversationEntryStore";
import BotConversationParticipantsStore from "./BotConversationParticipantStore";
import BotConversationsStore from "./BotConversationsStore";
import DemoConversationsEntriesStore from "./DemoConversationEntryStore";
import DemoConversationStore from "./DemoConversationStore";
import DemoStore from "./DemoStore";
import DemoAiToolStepsStore from "./DemoToolStepsStore";
import DemoAiToolsStore from "./DemoToolStore";
import DrivesStore from "./DriveStore";
import FavoritesStore from "./FavoritesStore";
import ResourceCollabStore from "./ResourceCollabStore";
import ResourceStore from "./ResourceStore";
import SharedResourceStore from "./SharedResourceStore";
import UiStore from "./UiStore";
import UsersStore from "./UsersStore";
import BlogStore from "./BlogStore";
import AuditLogsStore from "./AuditLogsStore";

export default class RootStore {
  abilities: AbilitiesStore;
  assets: AssetsStore;
  aiFeedback: AiFeedbackStore;
  aiFavorites: AiFavoriteStore;
  aiTools: AiToolsStore;
  aiToolStepsStore: AiToolStepsStore;
  aiToolParticipantsStore: AiToolParticipantsStore;
  conversationEntries: BotConversationsEntriesStore;
  conversations: BotConversationsStore;
  conversationParticipants: BotConversationParticipantsStore;
  drives: DrivesStore;
  favorites: FavoritesStore;
  resources: ResourceStore;
  resourceCollab: ResourceCollabStore;
  users: UsersStore;
  auth: AuthStore;
  ui: UiStore;
  sharedResource: SharedResourceStore;
  demo: DemoStore;
  demoConversations: DemoConversationStore;
  demoConversationEntries: DemoConversationsEntriesStore;
  demoAiTools: DemoAiToolsStore;
  demoAiToolStepsStore: DemoAiToolStepsStore;
  blogStore: BlogStore;
  auditLogs: AuditLogsStore;

  constructor(apolloClient: ApolloClient<any>) {
    this.abilities = new AbilitiesStore(this, apolloClient);
    this.assets = new AssetsStore(this, apolloClient);
    this.aiFeedback = new AiFeedbackStore(this, apolloClient);
    this.aiFavorites = new AiFavoriteStore(this, apolloClient);
    this.aiToolStepsStore = new AiToolStepsStore(this, apolloClient);
    this.aiToolParticipantsStore = new AiToolParticipantsStore(
      this,
      apolloClient
    );
    this.aiTools = new AiToolsStore(this, apolloClient);
    this.favorites = new FavoritesStore(this, apolloClient);
    this.resources = new ResourceStore(this, apolloClient);
    this.resourceCollab = new ResourceCollabStore();
    this.conversationEntries = new BotConversationsEntriesStore(
      this,
      apolloClient
    );
    this.conversations = new BotConversationsStore(this, apolloClient);
    this.conversationParticipants = new BotConversationParticipantsStore(
      this,
      apolloClient
    );
    this.auth = new AuthStore(this, apolloClient);
    this.drives = new DrivesStore(this, apolloClient);
    this.users = new UsersStore(this, apolloClient);
    this.ui = new UiStore();
    this.sharedResource = new SharedResourceStore(this, apolloClient);
    this.demo = new DemoStore(apolloClient);
    this.demoConversations = new DemoConversationStore(this, apolloClient);
    this.demoConversationEntries = new DemoConversationsEntriesStore(
      this,
      apolloClient
    );
    this.demoAiTools = new DemoAiToolsStore(this, apolloClient);
    this.demoAiToolStepsStore = new DemoAiToolStepsStore(this, apolloClient);
    this.blogStore = new BlogStore();
    this.auditLogs = new AuditLogsStore(this, apolloClient);
  }

  async logout() {
    this.abilities.clear();
    this.assets.clear();
    this.aiToolParticipantsStore.clear();
    this.aiToolStepsStore.clear();
    this.aiTools.clear();
    this.aiFeedback.clear();
    this.conversations.clear();
    this.conversationEntries.clear();
    this.conversationParticipants.clear();
    this.drives.clear();
    this.favorites.clear();
    this.resources.clear();
    this.resourceCollab.clear();
    this.users.clear();
    this.ui.clear();
    this.sharedResource.clear();
    // this.demo.clear();
    this.demoConversations.clear();
    this.demoConversationEntries.clear();
    this.demoAiTools.clear();
    this.demoAiToolStepsStore.clear();
    this.blogStore.clear();
    this.auditLogs.clear();
  }
}
