import { computed, observable } from "mobx";
import { EnumUserDistrictRoles } from "../__generated__/graphql";
import Attribute from "./decorators/Attribute";
import DeletableModel from "./DeletableModel";

class DistrictUser extends DeletableModel {
  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  email: string;

  @Attribute
  @observable
  username: string;

  @Attribute
  @observable
  firstName: string;

  //   @Attribute
  //   @observable
  //   middleName: string | null;

  @Attribute
  @observable
  lastName: string;

  @Attribute
  @observable
  avatarUrl: string | null;

  @Attribute
  @observable
  districtRoles: EnumUserDistrictRoles[];

  //   @Attribute
  //   @observable
  //   edlinkId: string | null;

  //   @Attribute
  //   @observable
  //   gradeLevels: EnumUserGradeLevels[] | null;

  @Attribute
  @observable
  districtId: string;

  //   @Attribute
  //   @observable
  //   schools: string[];

  @computed
  get fullName(): string {
    return this.firstName + " " + this.lastName;
  }

  @computed
  get initial(): string {
    return this.firstName[0];
  }
}

export default DistrictUser;
