import * as Slider from "@radix-ui/react-slider";
import invariant from "invariant";
import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { compressImage } from "../../utils/compressImage";
import { dataUrlToBlob, ResourcePreset, uploadFile } from "../../utils/upload";
import Modal from "../Dialog";

export type AvatarUploadProps = {
  onSuccess: (url: string) => void | Promise<void>;
  onError: (error: string) => void;
  buttonLabel?: string;
  children: React.ReactNode;
};

export default function AvatarUpload({
  onSuccess,
  onError,
  buttonLabel = "Upload",
  children,
}: AvatarUploadProps) {
  const [isUploading, setIsUploading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [file, setFile] = useState<File | string>("");

  const { t } = useTranslation();

  const avatarEditorRef = React.useRef<AvatarEditor>(null);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
      setIsEditing(true);
    }
  };

  const handleCrop = () => {
    setIsUploading(true);
    setTimeout(() => {
      uploadAvatar();
    }, 100);
  };

  const uploadAvatar = async () => {
    if (!avatarEditorRef.current) {
      return;
    }

    const canvas = avatarEditorRef.current?.getImage();

    invariant(canvas, "Canvas is not defined");

    // Check if file is of type File
    if (typeof file === "string") {
      return;
    }

    const imageBlob = dataUrlToBlob(canvas.toDataURL());

    try {
      const compressed = await compressImage(imageBlob, {
        maxHeight: 512,
        maxWidth: 512,
      });

      const attachment = await uploadFile(compressed, {
        name: file.name,
        preset: ResourcePreset.Avatar,
      });
      onSuccess(attachment.resourceUrl);
    } catch (err: any) {
      console.log("error", err);
      onError(err.message);
    } finally {
      setIsUploading(false);
      setIsEditing(false);
    }
  };

  const handleClose = () => {
    setIsUploading(false);
    setIsEditing(false);
  };

  const renderEditing = () => {
    return (
      <Modal
        open={isEditing}
        onClose={() => {
          handleClose();
        }}
        onSubmit={() => {
          handleCrop();
        }}
        title={"Edit Picture"}
        buttonLabel={buttonLabel}
        disabled={isUploading}
      >
        <div className="mb-4 flex w-full justify-center">
          <AvatarEditor
            ref={avatarEditorRef}
            image={file}
            width={160}
            height={160}
            border={25}
            borderRadius={150}
            color={[0, 0, 0, 0.6]} // RGBA
            scale={zoomLevel}
            rotate={0}
          />
        </div>
        <div className="mt-4 flex w-full justify-center">
          <Slider.Root
            className="relative flex h-5 w-[300px] touch-none select-none items-center"
            value={[zoomLevel * 100]}
            max={100}
            step={1}
            onValueChange={(value) => {
              setZoomLevel(value[0] / 100);
            }}
            aria-label="Volume"
          >
            <Slider.Track className="bg-blackA10 relative h-[3px] grow rounded-full">
              <Slider.Range className="absolute h-full rounded-full bg-gray-200" />
            </Slider.Track>
            <Slider.Thumb className="shadow-blackA7 hover:bg-blue3 focus:shadow-blackA8 block h-5 w-5 rounded-[10px] bg-white shadow-[0_2px_10px] focus:shadow-[0_0_0_5px] focus:outline-none" />
          </Slider.Root>
        </div>
      </Modal>
    );
  };

  if (isEditing) {
    return renderEditing();
  }

  return (
    <Dropzone
      onDropAccepted={onDrop}
      accept={{
        "image/*": [".png", ".jpeg", ".jpg"],
      }}
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {children}
        </div>
      )}
    </Dropzone>
  );
}
