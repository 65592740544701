import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, computed, observable } from "mobx";
import {
  AiToolProcessLink,
  ElaTextLevelPassage,
} from "../graphql/aiTools/aiTools.mutation";
import {
  DemoAiChatInterfaceInput,
  DemoAiChatInterfaceSuggestionResponse,
  DemoAiToolFieldAutofill,
  DemoAiToolFinalOutput,
  DemoGenerateStepOutput,
} from "../graphql/demo/demo.mutations";
import { GetDemoAiToolSteps } from "../graphql/demo/demo.queries";
import AiToolStep from "../models/AiToolStep";
import DemoAiToolStep from "../models/DemoAiToolStep";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class DemoAiToolStepsStore extends BaseStore<DemoAiToolStep> {
  @observable
  isLoading = false;

  @observable
  isLoadingAiToolSteps = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, DemoAiToolStep, apolloClient);
    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  @action
  async aiToolProcessLink(
    id: string,
    link: string,
    topic: string,
    gradeLevel: string
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: AiToolProcessLink,
        variables: {
          args: {
            id,
            link,
            topic,
            gradeLevel,
          },
        },
      });

      if (
        !res.data ||
        res.data.aiToolProcessLink === undefined ||
        res.data.aiToolProcessLink === null
      ) {
        throw new Error("No data returned from processing link.");
      }

      return res.data.aiToolProcessLink;
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @action
  async fetchAiToolSteps(toolId: string) {
    this.isLoadingAiToolSteps = true;

    try {
      const res = await this.apolloClient.query({
        query: GetDemoAiToolSteps,
        variables: {
          toolId,
        },
      });

      if (!res.data || !res.data.demoAiToolSteps) {
        throw new Error("Failed to fetch Data. Please try again.");
      }

      const aiToolSteps = res.data.demoAiToolSteps;

      aiToolSteps.forEach((entry: any) => {
        console.log("Entry fetched from backend", entry);

        invariant(entry.aiToolId, "Entry must have a conversation id.");

        const sanitizedEntry = {
          id: entry.id,
          data: entry.data,
          aiToolId: entry.aiToolId,
          stepId: entry.stepId,
          createdAt: entry.createdAt,
          updatedAt: entry.updatedAt,
        };

        this.add(sanitizedEntry);
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoadingAiToolSteps = false;
    }
  }

  @action
  async aiToolFieldAutofill(
    id: string,
    aiToolId: string,
    aiStepId: string,
    fieldId: string,
    clientId: string
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: DemoAiToolFieldAutofill,
        variables: {
          where: {
            id,
            aiToolId,
            aiStepId,
            fieldId,
            clientId,
          },
        },
      });

      if (!res.data || !res.data.demoAiToolFieldAutofill) {
        throw new Error("Failed to generate response. Please try again.");
      }
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @action
  async aiToolGenerateStepOutput(
    id: string,
    aiToolId: string,
    aiStepId: string,
    clientId: string
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: DemoGenerateStepOutput,
        variables: {
          where: {
            id,
            aiToolId,
            aiStepId,
            clientId,
          },
        },
      });

      if (!res.data || !res.data.demoGenerateStepOutput) {
        throw new Error("Failed to generate response. Please try again.");
      }
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @action
  async aiToolFinalOutput(
    id: string,
    aiToolId: string,
    aiStepId: string,
    clientId: string
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: DemoAiToolFinalOutput,
        variables: {
          where: {
            id,
            aiToolId,
            aiStepId,
            clientId,
          },
        },
      });

      if (!res.data || !res.data.demoAiToolFinalOutput) {
        throw new Error("Failed to generate response. Please try again.");
      }
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @action
  async aiChatInterfaceInput(
    id: string,
    aiToolId: string,
    aiStepId: string,
    query: string,
    clientId: string
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: DemoAiChatInterfaceInput,
        variables: {
          where: {
            id,
            aiToolId,
            aiStepId,
            query,
            clientId,
          },
        },
      });

      if (!res.data || !res.data.demoAiChatInterfaceInput) {
        throw new Error("Failed to generate response. Please try again.");
      }

      return res.data.demoAiChatInterfaceInput;
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @action
  async aiChatInterfaceSuggestionResponse(
    id: string,
    aiToolId: string,
    aiStepId: string,
    accept: boolean,
    clientId: string
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: DemoAiChatInterfaceSuggestionResponse,
        variables: {
          where: {
            id,
            aiToolId,
            aiStepId,
            accept,
            clientId,
          },
        },
      });

      if (!res.data || !res.data.demoAiChatInterfaceSuggestionResponse) {
        throw new Error("Failed to accept changes. Please try again.");
      }

      return res.data.demoAiChatInterfaceSuggestionResponse;
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @action
  async elaTextLevelChange(
    id: string,
    textLevelId: string,
    makeActive?: boolean
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: ElaTextLevelPassage,
        variables: {
          args: {
            id,
            textLevelId,
            makeActive,
          },
        },
      });

      if (!res.data || !res.data.textLevelPassage) {
        throw new Error("No data returned from ela text level passage");
      }

      return res.data.textLevelPassage;
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @computed
  get sortedData(): AiToolStep[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }
}
