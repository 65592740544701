// Render a 404 page for all pages that don't match a route

// Import Link from react-router-dom
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonLarge from "../components/ButtonLarge";
import { homeRoute } from "../utils/routeHelper";

export default function NoMatch() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-blue11 text-base font-semibold">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {/* {t("NoMatch.name")} */}
          Page not found
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          {/* {t("NoMatch.description")} */}
          Sorry, we couldn’t find the page you’re looking for.
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <ButtonLarge
            type="button"
            // buttonText={t("NoMatch.buttonText")}
            buttonText={"Go back home"}
            onClick={() => {
              navigate("/");
            }}
            theme="primary"
          />

          <ButtonLarge
            type="button"
            // buttonText={t("NoMatch.contactSupport")}
            buttonText={"Contact support"}
            onClick={() => {
              // @ts-ignore
              window!.Intercom("show");
            }}
            theme="secondary"
          />
        </div>
      </div>
    </div>
  );
}
