import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, computed, observable } from "mobx";
import {
  AiChatInterfaceInput,
  AiChatInterfaceSuggestionResponse,
  AiToolFieldAutofill,
  AiToolFinalOutput,
  AiToolProcessLink,
  ElaTextLevelPassage,
  GenerateStepOutput,
} from "../graphql/aiTools/aiTools.mutation";
import { GetAiToolSteps } from "../graphql/aiTools/aiTools.queries";
import AiToolStep from "../models/AiToolStep";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class AiToolStepsStore extends BaseStore<AiToolStep> {
  @observable
  isLoading = false;

  @observable
  isLoadingAiToolSteps = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, AiToolStep, apolloClient);
    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  @action
  async aiToolProcessLink(
    id: string,
    link: string,
    topic: string,
    gradeLevel: string
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: AiToolProcessLink,
        variables: {
          args: {
            id,
            link,
            topic,
            gradeLevel,
          },
        },
      });

      if (
        !res.data ||
        res.data.aiToolProcessLink === undefined ||
        res.data.aiToolProcessLink === null
      ) {
        throw new Error("No data returned from processing link.");
      }

      return res.data.aiToolProcessLink;
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @action
  async fetchAiToolSteps(toolId: string) {
    if (!this.rootStore.auth.user || !this.rootStore.auth.user.id) {
      return null;
    }

    this.isLoadingAiToolSteps = true;

    try {
      const res = await this.apolloClient.query({
        query: GetAiToolSteps,
        variables: {
          where: {
            aiTool: {
              id: toolId,
            },
          },
        },
      });

      if (!res.data || !res.data.aiToolSteps) {
        throw new Error("Failed to fetch Data. Please try again.");
      }

      const aiToolSteps = res.data.aiToolSteps;

      aiToolSteps.forEach((entry: any) => {
        console.log("Entry fetched from backend", entry);

        invariant(entry.aiTool, "Entry must have a conversation id.");

        const sanitizedEntry = {
          id: entry.id,
          data: entry.data,
          aiToolId: entry.aiTool.id,
          stepId: entry.stepId,
          createdAt: entry.createdAt,
          updatedAt: entry.updatedAt,
        };

        this.add(sanitizedEntry);
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoadingAiToolSteps = false;
    }
  }

  @action
  async aiToolFieldAutofill(
    id: string,
    aiToolId: string,
    aiStepId: string,
    fieldId: string
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: AiToolFieldAutofill,
        variables: {
          where: {
            id,
            aiToolId,
            aiStepId,
            fieldId,
          },
        },
      });

      if (!res.data || !res.data.aiToolFieldAutofill) {
        throw new Error("Failed to generate response. Please try again.");
      }
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @action
  async aiToolGenerateStepOutput(
    id: string,
    aiToolId: string,
    aiStepId: string
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: GenerateStepOutput,
        variables: {
          where: {
            id,
            aiToolId,
            aiStepId,
          },
        },
      });

      if (!res.data || !res.data.generateStepOutput) {
        throw new Error("Failed to generate response. Please try again.");
      }
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @action
  async aiToolFinalOutput(id: string, aiToolId: string, aiStepId: string) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: AiToolFinalOutput,
        variables: {
          where: {
            id,
            aiToolId,
            aiStepId,
          },
        },
      });

      if (!res.data || !res.data.aiToolFinalOutput) {
        throw new Error("Failed to generate response. Please try again.");
      }
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @action
  async aiChatInterfaceInput(
    id: string,
    aiToolId: string,
    aiStepId: string,
    query: string
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: AiChatInterfaceInput,
        variables: {
          where: {
            id,
            aiToolId,
            aiStepId,
            query,
          },
        },
      });

      if (!res.data || !res.data.aiChatInterfaceInput) {
        throw new Error("Failed to generate response. Please try again.");
      }

      return res.data.aiChatInterfaceInput;
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @action
  async aiChatInterfaceSuggestionResponse(
    id: string,
    aiToolId: string,
    aiStepId: string,
    accept: boolean
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: AiChatInterfaceSuggestionResponse,
        variables: {
          where: {
            id,
            aiToolId,
            aiStepId,
            accept,
          },
        },
      });

      if (!res.data || !res.data.aiChatInterfaceSuggestionResponse) {
        throw new Error("Failed to accept changes. Please try again.");
      }

      return res.data.aiChatInterfaceSuggestionResponse;
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @action
  async elaTextLevelChange(
    id: string,
    textLevelId: string,
    makeActive?: boolean
  ) {
    try {
      const res = await this.apolloClient.mutate({
        mutation: ElaTextLevelPassage,
        variables: {
          args: {
            id,
            textLevelId,
            makeActive,
          },
        },
      });

      if (!res.data || !res.data.textLevelPassage) {
        throw new Error("No data returned from ela text level passage");
      }

      return res.data.textLevelPassage;
    } catch (e) {
      throw e;
    } finally {
    }
  }

  @computed
  get sortedData(): AiToolStep[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }
}
