import { action, computed, observable } from "mobx";
import Attribute from "./decorators/Attribute";
import PersistModel from "./PersistModel";

class AuditLog extends PersistModel {
  @observable
  id: string;

  @Attribute
  @observable
  actionId: string;

  @Attribute
  @observable
  actionType: string;

  @Attribute
  @observable
  details: any; // In case of Attachment, this will be the null but incase of Embed this will be the Block Properties (e.g. url, title, etc.)

  @Attribute
  @observable
  userId: string;
}

export default AuditLog;
