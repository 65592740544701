import { observable } from "mobx";
import Attribute from "./decorators/Attribute";
import PersistModel from "./PersistModel";

class Favorite extends PersistModel {
  @observable
  id: string;

  @Attribute
  @observable
  resourceId: string;
}

export default Favorite;
