import { gql } from "../../__generated__/gql";

export const InitAiTool = gql(`
  mutation InitAiTool($data: InitToolInput!) {
    initAiTool(data: $data) {
      aiToolParticipants {
        aiTool {
          id
          toolId
        }
        createdAt
        owner
        id 
        permissions
        updatedAt
        user {
          id
          firstName
          lastName
          email
          avatarUrl
        }
      }
      aiToolSteps {
        aiTool {
          id
        }
        createdAt
        data
        id 
        stepId
        updatedAt
      }
      createdAt
      id
      data
      initData
      title 
      toolId
      updatedAt
      urlId
      version
    }
  }
`);

export const UpdateAiTool = gql(`
  mutation UpdateAiTool($data: AiToolUpdateInput!, $where: AiToolWhereUniqueInput!) {
    updateAiTool(data: $data, where: $where) {
      aiToolParticipants {
        aiTool {
          id
          toolId
        }
        createdAt
        owner
        id 
        permissions
        updatedAt
        user {
          id
          firstName
          lastName
          email
          avatarUrl
        }
      }
      aiToolSteps {
        aiTool {
          id
        }
        createdAt
        data
        id 
        stepId
        updatedAt
      }
      createdAt
      id
      data
      initData
      title 
      toolId
      updatedAt
      urlId
      version
    }
  }
`);

export const AiToolFieldAutofill = gql(`
  mutation AiToolFieldAutofill($where: AiAutofillInput!) {
    aiToolFieldAutofill(where: $where) 
  }
`);

export const GenerateStepOutput = gql(`
  mutation GenerateStepOutput($where: GenerateStepOutputInput!) {
    generateStepOutput(where: $where) 
  }
`);

export const CompleteToolStep = gql(`
  mutation CompleteToolStep($data: CompleteToolStepInput!) {
    completeToolStep(data: $data)  {
      aiToolParticipants {
        aiTool {
          id
          toolId
        }
        createdAt
        owner
        id 
        permissions
        updatedAt
        user {
          id
          firstName
          lastName
          email
          avatarUrl
        }
      }
      aiToolSteps {
        aiTool {
          id
        }
        createdAt
        data
        id 
        stepId
        updatedAt
      }
      createdAt
      id
      data
      initData
      title 
      toolId
      updatedAt
      urlId
      version
    }
  }
`);

export const AiToolProcessLink = gql(`
  mutation AiToolProcessLink($args: ProcessLinkInput!) {
    aiToolProcessLink(args: $args) 
  }
`);

export const AiToolFinalOutput = gql(`
  mutation AiToolFinalOutput($where: FinalStepOutputInput!) {
    aiToolFinalOutput(where: $where) 
  }
`);

export const AiChatInterfaceInput = gql(`
  mutation AiChatInterfaceInput($where: AiChatInterfaceInput!) {
    aiChatInterfaceInput(where: $where) 
  }
`);

export const AiChatInterfaceSuggestionResponse = gql(`
  mutation AiChatInterfaceSuggestionResponse($where: AiChatInterfaceSuggestionResponseInput!) {
    aiChatInterfaceSuggestionResponse(where: $where) 
  }
`);

export const ConvertMarkdownToPDF = gql(`
  mutation ConvertMarkdownToPDF($markdown: String!, $fileName: String!) {
    convertMarkdownToPDF(markdown: $markdown, fileName: $fileName)
  }
`);

export const ElaTextLevelPassage = gql(`
  mutation ElaTextLevelPassage($args: TextLevelPassageInput!) {
    textLevelPassage(args: $args) 
  }
`);

export const ResourceGeneratorEdit = gql(`
  mutation ResourceGeneratorEdit($where: AiResourceGeneratorEditInput!) {
    resourceGeneratorEdit(where: $where) 
  }
`);

export const CreateAiFavorite = gql(`
  mutation CreateAiFavorite($data: AiFavoriteCreateInput!) {
    createAiFavorite(data: $data) {
      id
      createdAt
      entityId
      entityType
      updatedAt
      user {
        id
      }
    }
  }
`);

export const DeleteAiFavorite = gql(`
  mutation DeleteAiFavorite($where: AiFavoriteWhereUniqueInput!) {
    deleteAiFavorite(where: $where) {
      id
      createdAt
      entityId
      entityType
      updatedAt
      user {
        id
      }
    }
  }
`);
