import { useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export function NavLink({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) {
  // Inside your Header function or outside, if you want it reusable
  const scrollToSection = (sectionId: string) => {
    // Extract the actual ID by removing the leading '/#'
    const id = sectionId.startsWith("/#") ? sectionId.slice(2) : sectionId;
    const section = document.querySelector(`#${id}`); // Use the extracted ID to form a valid selector
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const navigate = useNavigate();

  const isInternalLink = to.startsWith("/#");
  // In your NavLink component or similar logic
  const handleClick = useCallback(
    (e: any) => {
      if (isInternalLink) {
        e.preventDefault();
        // Check if the current path is not the homepage
        if (window.location.pathname !== "/") {
          // Use React Router's navigate function or history.push to redirect to "/"
          // Then, scroll to section
          navigate("/"); // Assuming you have access to React Router's history object
          setTimeout(() => scrollToSection(to), 0); // Delay scrolling until after the navigation
        } else {
          // Already on the homepage, just scroll to the section
          scrollToSection(to);
        }
      }
    },
    [to]
  );

  // Use useEffect to handle scrolling when the component mounts and there's a hash in the URL
  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.replace(/^\/?#/, "");
      scrollToSection(`#${id}`);
    }
  }, []);

  return (
    <Link
      to={to}
      onClick={handleClick}
      className="inline-block rounded-lg px-2 py-1 text-slate-700 hover:bg-slate-100 hover:text-slate-900"
    >
      {children}
    </Link>
  );
}
