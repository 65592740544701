import { action, computed, observable } from "mobx";
import { EnumBotConversationEntrySpeaker } from "../__generated__/graphql";
import Attribute from "./decorators/Attribute";
import DeletableModel from "./DeletableModel";

class DemoConversationEntry extends DeletableModel {
  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  conversationId: string;

  @Attribute
  @observable
  entry: any;

  @Attribute
  @observable
  speaker: EnumBotConversationEntrySpeaker;

  @Attribute
  @observable
  userId?: string | null;

  @observable
  streamResponse?: string | null = null;

  @observable
  status?: string;

  @action
  setStatus = (status: string) => {
    this.status = status;
  };

  @action
  setStreamingResponse = (token: string) => {
    this.streamResponse = this.streamResponse
      ? (this.streamResponse += token)
      : token;
  };

  @action
  doneStreamingResponse = () => {
    this.streamResponse = null;
  };

  @computed
  get isStreamingResponse(): boolean {
    return !!this.streamResponse;
  }
}

export default DemoConversationEntry;
