import { action, computed, observable, toJS } from "mobx";
import Attribute from "./decorators/Attribute";
import DeletableModel from "./DeletableModel";
import DemoConversationEntry from "./DemoConversationEntry";

class DemoConversation extends DeletableModel {
  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  title: string;

  @Attribute
  @observable
  data?: any;

  @Attribute
  @observable
  urlId: string;

  @observable
  status?: string | null;

  // Object with multiple ids for each entry <-> mapped to a object with token counts to string
  @observable
  streamResponseMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};

  // Modify this to order based on tokens
  @action
  setEntryStatus = async (entryId: string, status?: string) => {
    this.status = status || null;

    const { conversationEntries } = this.store.rootStore;

    const fetchEntry = await conversationEntries.get(entryId);

    if (!fetchEntry) {
      return;
    }

    fetchEntry.setStatus(status || null);
  };

  @action
  setEntryStreamResponse = async (
    entryId: string,
    token: string,
    tokenCount: number
  ) => {
    if (!this.streamResponseMap[entryId]) {
      const newMap = {
        [tokenCount]: token,
      };

      this.streamResponseMap = {
        ...this.streamResponseMap,
        [entryId]: newMap,
      };
    } else {
      const updateExistingTokenMap = {
        ...this.streamResponseMap[entryId],
      };

      updateExistingTokenMap[tokenCount] = token;

      this.streamResponseMap = {
        ...this.streamResponseMap,
        [entryId]: updateExistingTokenMap,
      };
    }
  };

  @action
  setEntryStreamResponseEnded = async (entryId: string) => {
    this.streamResponseMap = {};
  };

  @computed
  get entries(): DemoConversationEntry[] {
    const { demoConversationEntries } = this.store.rootStore;

    console.log(
      "All messages in store",
      demoConversationEntries.sortedData.map((e: DemoConversationEntry) =>
        toJS(e.toGQLAttributes())
      )
    );

    return demoConversationEntries.sortedData.filter(
      (entry: DemoConversationEntry) => entry.conversationId === this.id
    );
  }
}

export default DemoConversation;
