import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, computed, observable, runInAction } from "mobx";
import { NewDemoConversation } from "../graphql/demo/demo.mutations";
import { GetDemoConversation } from "../graphql/demo/demo.queries";
import BotConversation from "../models/BotConversation";
import DemoConversation from "../models/DemoConversation";
import { DemoConversationInput } from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class DemoConversationStore extends BaseStore<DemoConversation> {
  @observable
  isLoading = false;

  @observable
  isLoadingCourseResources = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, DemoConversation, apolloClient);
    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  setBotConversations = (botConversations: BotConversation[]) => {
    botConversations.forEach((botConversation) => {
      this.add(botConversation);
    });
  };

  @action
  fetchByUrlId = (urlId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetDemoConversation,
          variables: {
            conversationId: urlId,
          },
        })
        .then((res) => {
          const conversation = res.data.demoConversation;

          invariant(conversation, "Conversation not found");

          const sanitizeBotConversation = {
            createdAt: conversation.createdAt,
            data: conversation.data,
            deletedAt: conversation.deletedAt,
            id: conversation.id,
            title: conversation.title,
            updatedAt: conversation.updatedAt,
            urlId: conversation.urlId,
          };

          // Fetch the entries for this conversation
          this.rootStore.demoConversationEntries.fetchDemoConversationEntries(
            conversation.id
          );

          this.add(sanitizeBotConversation);

          resolve(true);
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
        });
    });
  };

  @action
  async createNewConversation(
    data: DemoConversationInput
  ): Promise<DemoConversation> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: NewDemoConversation,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.newDemoConversation) {
        throw new Error("No data returned from newDemoConversation mutation");
      }

      const convo = res.data.newDemoConversation;

      const sanitizedBotConversation = {
        createdAt: convo.createdAt,
        data: convo.data,
        id: convo.id,
        title: convo.title,
        urlId: convo.urlId,
        updatedAt: convo.updatedAt,
      };

      return this.add(sanitizedBotConversation);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @computed
  get sortedData(): DemoConversation[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }

  getByUrlParam = (urlId: string): DemoConversation | undefined => {
    return this.sortedData.find((calendar) => urlId.endsWith(calendar.urlId));
  };
}
