//
import _ from "lodash";
import { DateTime } from "luxon";
import { action, computed, observable, set } from "mobx";
import ResourceStore from "../stores/ResourceStore";
import { StatusType } from "../types";
import { EnumResourceResourceType } from "../__generated__/graphql";
import Attribute from "./decorators/Attribute";
import PersistModel from "./PersistModel";

interface StatusMap {
  [key: string]: StatusType;
}

interface ResourceScheduleMap {
  [sectionId: string]: string[];
}

interface TrackingMap {
  [sectionId: string]: string[];
}

// Only modify these variables from the front-end
const FRONT_END_ATTRIBUTES = ["title"];

class Resource extends PersistModel {
  @observable
  isUpdating: boolean = false;

  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  urlId: string;

  @Attribute
  @observable
  title: string;

  @Attribute
  @observable // Experimenting with this since template loads with undefined
  content: any;

  @Attribute
  @observable
  driveId?: string | null;

  @Attribute
  @observable
  resourceType: EnumResourceResourceType;

  @Attribute
  @observable
  userId: string;

  @Attribute
  @observable
  parents?: string[] | null;

  @Attribute
  @observable
  thumbnail?: string | null;

  @Attribute
  @observable
  lastModifiedById?: string | null;

  @Attribute
  @observable
  archivedAt?: string | null;

  @Attribute
  @observable
  deletedAt?: string | null;

  @Attribute
  @observable
  publishedAt?: string | null;

  @Attribute
  @observable
  publishedById?: string | null;

  @Attribute
  @observable
  user?: any | null;

  @Attribute
  @observable
  version: number; // Backend Slate.js data version

  @Attribute
  @observable
  editorVersion: number; // Front-end editor version

  @Attribute
  @observable
  canEdit: boolean;

  @Attribute
  @observable
  canMoveWithinDrive: boolean;

  @Attribute
  @observable
  canMoveOutsideDrive: boolean;

  @Attribute
  @observable
  canTrash: boolean;

  store: ResourceStore;

  constructor(data: any, store: ResourceStore) {
    super(data, store);
    this.store = store;
  }

  // Basic document logic

  @action
  save = async () => {
    const updateAttributes = this.toGQLAttributes();

    const updateResourceData = _.omitBy(
      _.pick(updateAttributes, FRONT_END_ATTRIBUTES),
      _.isNull
    );

    this.isUpdating = true;

    try {
      const updatedResource = await this.store.save(
        {
          ...updateResourceData,
          id: this.id,
        },
        true
      );

      // Update attributes from update call
      set(this, { ...updatedResource });

      // refresh the attributes store
      this.attributesStore = this.toGQLAttributes();

      return updatedResource;
    } catch (e) {
      throw e;
    } finally {
      this.isUpdating = false;
    }
  };

  @computed
  get isArchived() {
    return !!this.archivedAt;
  }

  @computed
  get isPublished() {
    return !!this.publishedAt;
  }

  @computed
  get isDeleted() {
    return !!this.deletedAt;
  }

  @computed
  get pageTitle() {
    return this.title === "" ? "Untitled" : this.title;
  }

  @observable
  aiAssistantStatusMap: {
    [key: string]: string;
  } = {};

  @observable
  aiAssistantTokenMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};

  @observable
  aiAssistantCompleteMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};

  @action
  setAiAssistantStatusMap = async (status: string) => {
    const updateFieldStatusMap = {
      ...this.aiAssistantStatusMap,
    };

    const updateFieldTokenMap = {
      ...this.aiAssistantTokenMap,
    };

    const updateFieldCompleteMap = {
      ...this.aiAssistantCompleteMap,
    };

    const key = this.id;

    // We remove the status & field => move response to complete field
    if (status === "END") {
      delete updateFieldStatusMap[key];

      const copyStream = updateFieldTokenMap[key];

      delete updateFieldTokenMap[key];

      updateFieldCompleteMap[key] = copyStream;

      this.aiAssistantTokenMap = updateFieldTokenMap;
      this.aiAssistantCompleteMap = updateFieldCompleteMap;
    } else {
      updateFieldStatusMap[key] = status;
    }

    this.aiAssistantStatusMap = updateFieldStatusMap;
  };

  @action
  setAiAssistantTokenMap = async (tokenCount: number, token: string) => {
    const updateAutofillTokenMap = {
      ...this.aiAssistantTokenMap,
    };

    const key = this.id;

    // It may not exist
    const updateTokenMap = this.aiAssistantTokenMap[key]
      ? { ...this.aiAssistantTokenMap[key] }
      : {};

    // Now we set the token
    updateTokenMap[tokenCount] = token;

    // Now we update main autofill map with token map
    updateAutofillTokenMap[key] = updateTokenMap;

    this.aiAssistantTokenMap = updateAutofillTokenMap;
  };

  @action
  clearAiAssistantCompleteMap = () => {
    this.aiAssistantCompleteMap = {};
  };

  @computed
  get isFavorite() {
    const findFavorite = this.store.rootStore.favorites.sortedData.find(
      (favorite) => {
        return favorite.resourceId === this.id;
      }
    );

    return findFavorite !== undefined;
  }

  @action
  updateFromJson = (data: any) => {
    set(this, { ...data, newlyCreated: false, updatedAt: data.updatedAt });
    this.attributesStore = this.toGQLAttributes();
  };

  @action
  setTitle = (title: string) => {
    this.title = title;
  };

  @action
  updatedNow = () => {
    this.updatedAt = new Date().toISOString();
  };

  @action
  publish = () => {
    return this.store.publish(this.id);
  };

  @action
  unpublish = () => {
    return this.store.unpublish(this.id);
  };

  @action
  favorite = () => {
    return this.store.favorite(this.id);
  };

  @action
  archive = () => {
    return this.store.archive(this.id);
  };

  @action
  permanentlyDelete = () => {
    return this.store.delete(this.id);
  };

  @action
  unfavorite = () => {
    return this.store.unfavorite(this.id);
  };

  @action
  restore = () => {
    return this.store.restore(this.id);
  };

  hasBeenModified(): boolean {
    const attributes = this.toGQLAttributes();

    if (Object.keys(attributes).length === 0) {
      console.warn("Object has no @Attributes");
    }

    return (
      JSON.stringify(_.pick(this.attributesStore, FRONT_END_ATTRIBUTES)) !==
      JSON.stringify(_.pick(attributes, FRONT_END_ATTRIBUTES))
    );
  }

  @computed
  get lastModified(): string {
    return timeToReadableAgo(this.updatedAt);
  }

  @computed
  get createdReadable(): string {
    return timeToReadableAgo(this.createdAt);
  }
}

export function timeToReadableAgo(date: string) {
  const dateObj = new Date(date);

  const now = new Date();
  const seconds = Math.floor((now.getTime() - dateObj.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 7) {
    return DateTime.fromJSDate(dateObj).toFormat("LLL dd, yyyy");
  } else if (days > 0) {
    return `${days}d ago`;
  } else if (hours > 0) {
    return `${hours}h ago`;
  } else if (minutes > 0) {
    return `${minutes}m ago`;
  } else {
    return `just now`;
  }
}
export default Resource;
