import {
  AdjustmentsVerticalIcon,
  ChatBubbleBottomCenterTextIcon,
  ChevronLeftIcon,
  EllipsisHorizontalIcon,
  EnvelopeIcon,
  PaintBrushIcon,
  PlayIcon,
  PlusIcon,
} from "@heroicons/react/24/solid";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CopyIcon,
  DownloadIcon,
  FileIcon,
  FileTextIcon,
  LoopIcon,
  MixerHorizontalIcon,
  Pencil2Icon,
  Share1Icon,
  Share2Icon,
  ShuffleIcon,
  StarFilledIcon,
  StarIcon,
  TokensIcon,
} from "@radix-ui/react-icons";
import clsx from "clsx";

import posthog from "posthog-js";
import { Spinner } from "../LoadingIndicators/Spinner";
import styles from "./index.module.css";

interface ButtonLargeProps {
  theme:
    | "primary"
    | "secondary"
    | "tertiary"
    | "destructive"
    | "constructive"
    | "editor"
    | "ai";
  icon?: string;
  type: "button" | "submit" | "reset" | undefined;
  buttonText?: string;
  onClick?: () => void;
  disabled?: boolean;
  fullWidth?: boolean;
  width?: string;
  showSpinner?: boolean;
  className?: string;
  iconClassName?: string;
  alignLeft?: boolean;
  iconPosition?: "left" | "right";
  rounded?: "full" | "medium" | "none";
  trackingName?: string;
}

export default function ButtonLarge(props: ButtonLargeProps) {
  const {
    icon,
    buttonText = "",
    onClick,
    disabled,
    fullWidth,
    width,
    type,
    theme = "primary",
    className: classNameProp,
    iconClassName: iconClassNameProp,
    alignLeft,
    rounded = "full",
    iconPosition = "left",
    trackingName,
    showSpinner = false,
  } = props;

  // Write switch function for icon later on if needed (e.g. create, plus, delete, etc.);
  // Primary button = Accent color background and border and hover effect
  // Secondary button = No background but border and hover effect
  // Tertiary button = No background and no border and no hover effect (e.g. in app link)
  // Destructive button = Red background and border and hover effect
  // Constructive button = Green background and border and hover effect

  const renderIcon = () => {
    switch (icon) {
      case "chevron-left":
        return <ChevronLeftIcon className={styles.buttonIconLg} />;
      case "chevron-down":
        return (
          <ChevronDownIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "chevron-up":
        return (
          <ChevronUpIcon
            className={clsx(
              styles.buttonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "comments":
        return (
          <ChatBubbleBottomCenterTextIcon className={styles.buttonIconLg} />
        );
      case "options":
        return <EllipsisHorizontalIcon className={styles.buttonIconLg} />;
      case "play":
        return <PlayIcon className={styles.buttonIconLg} />;
      case "plus":
        return <PlusIcon className={styles.buttonIconLg} />;
      case "adjustments":
        return <AdjustmentsVerticalIcon className={styles.buttonIconLg} />;
      case "paint-brush":
        return <PaintBrushIcon className={styles.buttonIconLg} />;
      case "file":
        return <FileIcon className={clsx(styles.buttonIconLg, "h-5 w-5")} />;
      case "templates":
        return <TokensIcon className={clsx(styles.buttonIconLg, "h-5 w-5")} />;
      case "import":
        return (
          <DownloadIcon className={clsx(styles.buttonIconLg, "h-5 w-5")} />
        );
      case "arrow-left":
        return (
          <ArrowLeftIcon className={clsx(styles.buttonIconLg, "h-5 w-5")} />
        );
      case "arrow-right":
        return (
          <ArrowRightIcon className={clsx(styles.buttonIconLg, "h-5 w-5 ")} />
        );
      case "doc":
        return (
          <FileTextIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "edit":
        return (
          <Pencil2Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "mr-1"
            )}
          />
        );
      case "download":
        return (
          <DownloadIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "copy":
        return (
          <CopyIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "star":
        return (
          <StarIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "star-filled":
        return (
          <StarFilledIcon
            className={clsx(
              "h-6 w-6 text-yellow-400",
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "star-remove":
        return (
          <div className="relative h-6 w-6">
            <StarIcon
              className={clsx(
                "h-6 w-6 ",
                iconClassNameProp ? iconClassNameProp : ""
              )}
            />
            {/* Render slash on the star */}
          </div>
        );
      case "share":
        return (
          <Share1Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "export":
        return (
          <Share2Icon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "filter":
        return (
          <MixerHorizontalIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "copied":
        return (
          <CheckIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : "",
              "text-green-400"
            )}
          />
        );
      case "logo":
        return (
          <img
            loading="lazy"
            src={"/imgs/logos/logo-no-text.png"}
            alt="logo"
            className="h-6 w-6"
          />
        );
      case "shuffle":
        return (
          <ShuffleIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "regenerate":
        return (
          <LoopIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      case "facebook":
        return (
          <img
            loading="lazy"
            src={"/imgs/logos/facebook.png"}
            alt="logo"
            className="h-6 w-6"
          />
        );
      case "twitter":
        return (
          <img
            loading="lazy"
            src={"/imgs/logos/x.png"}
            alt="logo"
            className="h-6 w-6"
          />
        );
      case "email":
        return (
          <EnvelopeIcon
            className={clsx(
              styles.radixButtonIcon,
              iconClassNameProp ? iconClassNameProp : ""
            )}
          />
        );
      default:
        return null;
    }
  };

  const renderTheme = () => {
    switch (theme) {
      case "primary":
        return styles.buttonPrimary;
      case "secondary":
        return styles.buttonSecondary;
      case "tertiary":
        return styles.buttonTertiary;
      case "destructive":
        return styles.buttonDestructive;
      case "constructive":
        return styles.buttonConstructive;
      case "ai":
        return styles.buttonAi;
      case "editor":
        return styles.buttonEditor;
      default:
        return styles.buttonPrimary;
    }
  };

  return (
    <button
      onClick={() => {
        if (onClick) {
          if (trackingName) {
            posthog.capture(trackingName, {
              componentType: "button",
            });
          }
          onClick();
        }
      }}
      disabled={disabled}
      type={type}
      className={clsx(
        styles.buttonLarge,
        "flex items-center text-sm font-semibold text-white",
        // fullW,
        renderTheme(),
        theme === "editor" ? "" : "rounded-full",
        classNameProp ? classNameProp : "",
        disabled ? styles.disabled : "",
        alignLeft ? "justify-start" : "justify-center"
      )}
      style={{
        width: fullWidth ? "100%" : width ? `${width}px` : "auto",
        borderRadius:
          rounded === "full"
            ? "9999px"
            : rounded === "medium" || theme === "editor"
            ? "8px"
            : "24px",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      {showSpinner && <Spinner size={24} color={"white"} />}
      {icon && iconPosition === "left" && <span>{renderIcon()}</span>}
      {icon && buttonText && iconPosition === "left" && (
        <span className="w-2" />
      )}
      {buttonText && <span>{buttonText}</span>}
      {icon && buttonText && iconPosition === "right" && (
        <span className="w-1" />
      )}
      {icon && iconPosition === "right" && <span>{renderIcon()}</span>}
    </button>
  );
}
