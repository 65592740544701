import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable } from "mobx";
import {
  CreateFavorite,
  DeleteFavorite,
} from "../graphql/favorite/favorite.mutations";
import { GetFavorites } from "../graphql/favorite/favorite.queries";
import Favorite from "../models/Favorite";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class FavoritesStore extends BaseStore<Favorite> {
  @observable
  isLoading = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Favorite, apolloClient);
    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  //   Add methods for fetching user favorites
  @action
  async fetchFavorites() {
    const userId = this.rootStore.auth.userId;

    if (!userId) {
      return;
    }

    this.isLoading = true;

    try {
      const res = await this.apolloClient.query({
        query: GetFavorites,
        variables: {
          where: {
            user: {
              id: userId,
            },
          },
        },
      });

      if (res && res.data && res.data.favorites) {
        res.data.favorites.forEach((favorite) => {
          invariant(favorite, "Resource could not be fetched");
          invariant(favorite.user, "Resource must have a user.");
          invariant(favorite.resource, "Resource must have a version.");

          this.add({
            id: favorite.id,
            resourceId: favorite.resource.id,
          });
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;
    }
  }

  //  Add methods for creating favorite
  @action
  async createFavorite(resourceId: string): Promise<Favorite> {
    const userId = this.rootStore.auth.userId;

    if (!userId) {
      throw new Error("User must be logged in to create a favorite.");
    }

    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: CreateFavorite,
        variables: {
          data: {
            user: {
              id: userId,
            },
            resource: {
              id: resourceId,
            },
          },
        },
      });

      if (res && res.data && res.data.createFavorite) {
        const favorite = res.data.createFavorite;

        invariant(favorite, "Favorite could not be fetched");
        invariant(favorite.user, "Favorite must have a user.");
        invariant(favorite.resource, "Favorite must have a resource.");

        return this.add({
          id: favorite.id,
          resourceId: favorite.resource.id,
        });
      } else {
        throw new Error("Favorite could not be created.");
      }
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      this.isSaving = false;
    }
  }

  //   Add methods for deleting favorite
  @action
  async deleteFavorite(id: string) {
    this.isSaving = true;
    try {
      await this.apolloClient.mutate({
        mutation: DeleteFavorite,
        variables: {
          where: {
            id,
          },
        },
      });

      return this.remove(id);
    } catch (err) {
      console.error(err);
    } finally {
      this.isSaving = false;
    }
  }
}
