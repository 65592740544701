import { gql } from "../../__generated__/gql";

export const CreateAsset = gql(`
    mutation CreateAsset(
        $data: AssetCreateInput!
    ) {
        createAsset(data: $data) {
            id
            key
            acl
            contentType
            size
            content
            assetType
            user {
                id
            }
            course {
                id
            }
            resource {
                id
            }
            createdAt
            updatedAt
            expiresAt
            deletedAt
            vectorEmbeddings
        }
    }
`);

export const UpdateAsset = gql(`
    mutation UpdateAsset(
        $data: AssetUpdateInput!
        $where: AssetWhereUniqueInput!
    ) {
        updateAsset(data: $data, where: $where) {
            id
            key
            acl
            contentType
            size
            content
            assetType
            user {
                id
            }
            course {
                id
            }
            resource {
                id
            }
            createdAt
            updatedAt
            expiresAt
            deletedAt
            vectorEmbeddings
        }
    }
`);

export const DeleteAsset = gql(`
    mutation DeleteAsset(
        $where: AssetWhereUniqueInput!
    ) {
        deleteAsset(where: $where) {
            id
        }
    }
`);

export const LinkForEmbedding = gql(`
    mutation LinkForEmbedding(
        $data: AssetCreateInput!
    ) {
        linkForEmbedding(data: $data) {
            id
            key
            acl
            contentType
            size
            content
            assetType
            user {
                id
            }
            course {
                id
            }
            resource {
                id
            }
            createdAt
            updatedAt
            expiresAt
            deletedAt
            vectorEmbeddings
        }
    }
`);
