import { XMarkIcon } from "@heroicons/react/20/solid";
import * as Dialog from "@radix-ui/react-dialog";
import { useTranslation } from "react-i18next";

type DialogProps = {
  open: boolean;
  disabled: boolean;
  buttonLabel: string;
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  children: React.ReactNode;
};

export default function Modal({
  open,
  buttonLabel = "Submit",
  onClose,
  onSubmit,
  title,
  children,
  disabled,
}: DialogProps) {
  const { t } = useTranslation();

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-blackA9 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Dialog.Title className="text-slate12 m-0 text-[17px] font-medium">
            {t(title)}
          </Dialog.Title>
          <Dialog.Description className="text-slate11 mt-[10px] mb-5 text-[15px] leading-normal">
            {t(
              "Make changes to your profile here. Click save when you're done."
            )}
          </Dialog.Description>
          {children}
          <div className="mt-[25px] flex justify-end">
            <Dialog.Close asChild>
              <button
                onClick={() => onSubmit()}
                disabled={disabled}
                className="bg-green4 text-green11 hover:bg-green5 focus:shadow-green7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none focus:shadow-[0_0_0_2px] focus:outline-none"
              >
                {t(buttonLabel)}
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button
              className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
              aria-label="Close"
              disabled={disabled}
              onClick={() => onClose()}
            >
              <XMarkIcon className="h-5 w-5 text-gray-600" />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
