import { observable } from "mobx";
import { EnumDriveDriveType } from "../__generated__/graphql";
import Attribute from "./decorators/Attribute";
import PersistModel from "./PersistModel";

class Drive extends PersistModel {
  @observable
  id: string;

  @Attribute
  @observable
  districtId: string;

  @Attribute
  @observable
  userId: string;

  @Attribute
  @observable
  driveType: EnumDriveDriveType;
}

export default Drive;
