import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, computed, observable } from "mobx";
import { NewDemoConversationEntry } from "../graphql/demo/demo.mutations";
import { GetDemoConversationEntries } from "../graphql/demo/demo.queries";
import DemoConversationEntry from "../models/DemoConversationEntry";
import { DemoConversationInput } from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class DemoConversationsEntriesStore extends BaseStore<DemoConversationEntry> {
  @observable
  isLoading = false;

  @observable
  isLoadingConversationEntries = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, DemoConversationEntry, apolloClient);
    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  @action
  async fetchDemoConversationEntries(conversationId: string) {
    if (!this.rootStore.demo.clientId) {
      return null;
    }

    this.isLoadingConversationEntries = true;

    try {
      const res = await this.apolloClient.query({
        query: GetDemoConversationEntries,
        variables: {
          conversationId,
        },
      });

      if (!res.data || !res.data.demoConversationEntries) {
        throw new Error("No data returned from bot conversation entries");
      }

      const demoConversationEntries = res.data.demoConversationEntries;

      demoConversationEntries.forEach((entry: any) => {
        invariant(entry.conversationId, "Entry must have a conversation id.");

        const sanitizedEntry = {
          id: entry.id,
          conversationId: entry.conversationId,
          userId: entry.userId,
          speaker: entry.speaker,
          entry: entry.entry,
          createdAt: entry.createdAt,
          updatedAt: entry.updatedAt,
          deletedAt: entry.deletedAt,
        };

        this.add(sanitizedEntry);
      });
    } catch (e) {
      throw e;
    } finally {
      this.isLoadingConversationEntries = false;
    }
  }

  @action
  async createNewConversationEntry(
    data: DemoConversationInput
  ): Promise<DemoConversationEntry> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: NewDemoConversationEntry,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.newDemoConversationEntry) {
        throw new Error(
          "No data returned from newDemoConversationEntry mutation"
        );
      }

      const entry = res.data.newDemoConversationEntry;

      invariant(entry.conversationId, "Entry must have a conversation id.");
      invariant(entry.speaker, "Entry mush have a speaker.");

      const sanitizedBotConversationEntry = {
        createdAt: entry.createdAt,
        id: entry.id,
        conversationId: entry.conversationId,
        userId: entry.userId,
        speaker: entry.speaker,
        entry: entry.entry,
        updatedAt: entry.updatedAt,
        deletedAt: entry.deletedAt,
      };

      return this.add(sanitizedBotConversationEntry);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @computed
  get sortedData(): DemoConversationEntry[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "asc");
  }

  @action
  entriesForConversation = (
    conversationId: string
  ): DemoConversationEntry[] => {
    return this.sortedData.filter(
      (entry) => entry.conversationId === conversationId
    );
  };
}
