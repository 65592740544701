// src/utils/errorHandler.ts

import { posthog } from "posthog-js";
import { toast } from "react-toastify";
import { NetworkError, OfflineError } from "./errors";

export function initializeGlobalErrorHandling(): void {
  // window.onerror = (
  //   msg: string | Event,
  //   source?: string,
  //   lineno?: number,
  //   colno?: number,
  //   error?: Error
  // ): boolean => {
  //   const extra = !colno ? "" : `\ncolumn: ${colno}`;
  //   const errorMsg = typeof msg === "string" ? msg : msg.toString();
  //   console.error(
  //     `Error: ${errorMsg}\nurl: ${source}\nline: ${lineno}${extra}\nerror: ${error}`
  //   );

  //   if (error instanceof NetworkError) {
  //     toast.error("Network error. Please try again.");
  //   } else if (error instanceof OfflineError) {
  //     toast.error("You are offline. Please check your internet connection.");
  //   } else {
  //     toast.error("An unexpected error occurred. Please try again.");
  //   }

  //   // TODO: Send this error information to your server or error tracking service
  //   posthog.capture("Global Error", {
  //     msg,
  //     source,
  //     lineno,
  //     colno,
  //     error,
  //   });

  //   const suppressErrorAlert = true;
  //   return suppressErrorAlert;
  // };

  window.onunhandledrejection = (e: PromiseRejectionEvent): void => {
    console.error(e);

    if (
      typeof e.reason === "string" &&
      e.reason.includes(
        "TypeError: undefined is not an object (evaluating 'a.M')"
      )
    ) {
      console.log("Ignoring error", e.reason);
      return;
    }

    if (e.reason instanceof NetworkError) {
      toast.error("Network error. Please try again.");
    } else if (e.reason instanceof OfflineError) {
      toast.error("You are offline. Please check your internet connection.");
    } else {
      toast.error("An unexpected error occurred. Please try again.");
    }

    // TODO: Send this error information to your server or error tracking service
    posthog.capture("Unhandled Rejection", {
      reason: e.reason,
    });

    throw new Error(
      e.reason instanceof Error ? e.reason.message : "Unhandled rejection"
    );
  };
}
