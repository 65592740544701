import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { signupRoute } from "../../utils/routeHelper";

const ReferredBy = () => {
  const { userId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      localStorage.setItem("referredBy", userId);
    }
    navigate(signupRoute());
  }, []);

  return null;
};

export default ReferredBy;
