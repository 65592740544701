//
import _ from "lodash";
import { action, computed, observable, set } from "mobx";

import SharedResourceStore from "../stores/SharedResourceStore";
import { EnumResourceResourceType } from "../__generated__/graphql";
import { AssetDisplay, timeToReadableAgo } from "./Asset";
import Attribute from "./decorators/Attribute";
import PersistModel from "./PersistModel";

// Only modify these variables from the front-end
const FRONT_END_ATTRIBUTES = ["title"];

class SharedResource extends PersistModel {
  @observable
  isUpdating: boolean = false;

  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  urlId: string;

  @Attribute
  @observable
  title: string;

  @Attribute
  @observable // Experimenting with this since template loads with undefined
  content: any;

  @Attribute
  @observable
  driveId?: string | null;

  @Attribute
  @observable
  resourceType: EnumResourceResourceType;

  @Attribute
  @observable
  userId: string;

  @Attribute
  @observable
  parents?: string[] | null;

  @Attribute
  @observable
  thumbnail?: string | null;

  @Attribute
  @observable
  lastModifiedById?: string | null;

  @Attribute
  @observable
  archivedAt?: string | null;

  @Attribute
  @observable
  deletedAt?: string | null;

  @Attribute
  @observable
  publishedAt?: string | null;

  @Attribute
  @observable
  publishedById?: string | null;

  @Attribute
  @observable
  user?: any | null;

  @Attribute
  @observable
  version: number; // Backend Slate.js data version

  @Attribute
  @observable
  editorVersion: number; // Front-end editor version

  store: SharedResourceStore;

  constructor(data: any, store: SharedResourceStore) {
    super(data, store);
    this.store = store;
  }

  @computed
  get isArchived() {
    return !!this.archivedAt;
  }

  @computed
  get isPublished() {
    return !!this.publishedAt;
  }

  @computed
  get isDeleted() {
    return !!this.deletedAt;
  }

  @computed
  get pageTitle() {
    return this.title === "" ? "Untitled" : this.title;
  }

  @computed
  get isFavorite() {
    const findFavorite = this.store.rootStore.favorites.sortedData.find(
      (favorite) => {
        return favorite.resourceId === this.id;
      }
    );

    return findFavorite !== undefined;
  }

  @action
  updateFromJson = (data: any) => {
    set(this, { ...data, newlyCreated: false, updatedAt: data.updatedAt });
    this.attributesStore = this.toGQLAttributes();
  };

  @action
  setTitle = (title: string) => {
    this.title = title;
  };

  @action
  updatedNow = () => {
    this.updatedAt = new Date().toISOString();
  };

  hasBeenModified(): boolean {
    const attributes = this.toGQLAttributes();

    if (Object.keys(attributes).length === 0) {
      console.warn("Object has no @Attributes");
    }

    return (
      JSON.stringify(_.pick(this.attributesStore, FRONT_END_ATTRIBUTES)) !==
      JSON.stringify(_.pick(attributes, FRONT_END_ATTRIBUTES))
    );
  }

  @computed
  get displayIcon(): AssetDisplay {
    if (this.resourceType === EnumResourceResourceType.Doc) {
      return {
        icon: "doc-text",
        iconColor: "pink",
      };
    } else if (this.resourceType === EnumResourceResourceType.LessonPlan) {
      return {
        icon: "notes",
        iconColor: "purple",
      };
    } else {
      return {
        icon: "calendar",
        iconColor: "yellow",
      };
    }
  }

  @computed
  get deletedReadable(): string {
    return timeToReadableAgo(
      this.deletedAt || this.archivedAt || new Date().toISOString()
    );
  }

  @computed
  get lastModified(): string {
    return timeToReadableAgo(this.updatedAt || this.createdAt);
  }
}

export default SharedResource;
