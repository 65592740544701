import { gql } from "../../__generated__/gql";

export const GetAITool = gql(`
    query GetAiTool(
        $where: AiToolWhereUniqueInput!
    ) {
        aiTool(where: $where) {
            aiToolParticipants {
                aiTool {
                    id
                    toolId
                }
                createdAt
                owner
                id 
                permissions
                updatedAt
                user {
                    id
                    firstName
                    lastName
                    email
                    avatarUrl
                }
            }
            aiToolSteps {
                aiTool {
                    id
                }
                createdAt
                data
                id 
                stepId
                updatedAt
            }
            createdAt
            id
            data
            initData
            title 
            toolId
            updatedAt
            urlId
            version
        }
    }
`);

export const GetAIToolByUrlId = gql(`
    query GetAiToolByUrlId(
        $where: AiToolUrlIdInput!
    ) {
        aiToolByUrlId(where: $where) {
            aiToolParticipants {
                aiTool {
                    id
                    toolId
                }
                createdAt
                owner
                id 
                permissions
                updatedAt
                user {
                    id
                    firstName
                    lastName
                    email
                    avatarUrl
                }
            }
            aiToolSteps {
                aiTool {
                    id
                }
                createdAt
                data
                id 
                stepId
                updatedAt
            }
            createdAt
            id
            data
            initData
            title 
            toolId
            updatedAt
            urlId
            version
        }
    }
`);

export const GetAiToolSteps = gql(`
    query GetAiToolSteps(
        $where: AiToolStepWhereInput!
    ) {
        aiToolSteps(where: $where) {
            aiTool {
                id
            }
            createdAt
            data
            id 
            stepId
            updatedAt
        }
    }
`);

export const GetAiToolParticipants = gql(`
    query GetAiToolParticipants(
        $where: AiToolParticipantWhereInput!
    ) {
        aiToolParticipants(where: $where) {
            aiTool {
                id
                toolId
            }
            createdAt
            owner
            id 
            permissions
            updatedAt
            user {
                id
                firstName
                lastName
                email
                avatarUrl
            }
        }
    }
`);

export const GetAiFavorites = gql(`
    query GetAiFavorites($where: AiFavoriteWhereInput!) {
        aiFavorites(where: $where) {
            id
            createdAt
            entityId
            entityType
            updatedAt
            user {
                id
            }
        }
    }
`);
