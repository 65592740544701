import { observable } from "mobx";
import { EnumBotConversationParticipantPermissions } from "../__generated__/graphql";
import Attribute from "./decorators/Attribute";
import DeletableModel from "./DeletableModel";

export type ParticipantUser = {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string | null;
};

class BotConversationParticipant extends DeletableModel {
  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  conversationId: string;

  @Attribute
  @observable
  user: ParticipantUser;

  @Attribute
  @observable
  permissions: EnumBotConversationParticipantPermissions[];

  @Attribute
  @observable
  owner: boolean;
}

export default BotConversationParticipant;
