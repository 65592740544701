import { observable } from "mobx";
import Attribute from "./decorators/Attribute";
import PersistModel from "./PersistModel";

class AIFavorite extends PersistModel {
  @observable
  isUpdating: boolean = false;

  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  entityId: string;

  @Attribute
  @observable
  entityType: string;
}

export default AIFavorite;
