import clsx from "clsx";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useStores from "../../hooks/useStores";
import { Spinner } from "../TipTapEditor/primitives/Spinner";
import { onboardingRoute } from "../../utils/routeHelper";

function VerifyEmail(props: any) {
  const { auth } = useStores();
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Something went wrong");
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        if (!userId) {
          setError(true);
          setErrorMessage("Invalid verification link");
          setLoading(false);
          return;
        }
        const res = await auth.verifyEmail(userId);
        console.log(res);
        if (res.success) {
          navigate(onboardingRoute());
        } else {
          setError(true);
          setErrorMessage(res.error || "Something went wrong");
        }
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };
    verifyEmail();
  }, [userId]);

  if (loading) {
    return (
      <div className="h-screen w-full">
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Spinner size={32} color="black" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div
        className={clsx(
          // styles.appBackground,
          "flex min-h-[100vh] flex-col items-center justify-center text-center"
        )}
        style={{ backgroundColor: "#fff" }}
      >
        <div className="space-y-10 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="text-2xl font-semibold text-black">Error</div>
          <p>{errorMessage}</p>
          <button
            className="bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 inline-flex items-center rounded-md border border-transparent px-6 py-2 text-sm font-medium leading-4 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
            onClick={() => {
              navigate("/");
            }}
          >
            Back to Home
          </button>
        </div>
      </div>
    );
  }

  return null;
}
export default observer(VerifyEmail);
