import { useState } from "react";
import useStores from "./useStores";
import invariant from "invariant";

export type UserAuditLogInput = {
  actionType: string;
  actionId: string;
  details: any;
};

function useUserAuditLogs() {
  const { auth, auditLogs } = useStores();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  //   // Fetch audit logs for the current user
  //   const fetchUserAuditLogs = async () => {
  //     try {
  //       setLoading(true);
  //       await auditLogs.fetchAuditLogsForLimits(); // Assuming this fetches for the current user
  //       // You might need to modify `fetchAuditLogsForLimits` to accept a userId if it doesn't already
  //     } catch (e) {
  //       setError(e);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  // Create an audit log for the current user
  const createUserAuditLog = async (data: UserAuditLogInput) => {
    const { userId } = auth;

    invariant(userId, "User must be logged in to create an audit log.");

    try {
      setLoading(true);
      await auditLogs.create({
        ...data,
        user: {
          id: userId,
        },
      });
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  // Automatically fetch user audit logs when the component using this hook mounts
  //   useEffect(() => {
  //     fetchUserAuditLogs();
  //   }, []);

  return { loading, error, createUserAuditLog };
}

export default useUserAuditLogs;
