import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { omit } from "lodash";
import { action, autorun, computed, observable, runInAction } from "mobx";
import {
  CheckEnterpriseOboardingValidity,
  CheckUpdatePasswordTokenValidity,
  GetAuthUser,
  GetInvitesForEmail,
  GetReferralInfo,
  GetReferralLink,
  getStripeCustomerManaementUrl,
  GetUserByEmail,
  VerifyEmailHash,
} from "../graphql/auth/auth.queries";
// import Ability from "../models/Ability";
import User from "../models/User";
import { AuthResponse } from "../types";
import RootStore from "./RootStore";

import { Location } from "react-router-dom";
import {
  AcceptInvite,
  ActivateUserWithPassword,
  ActivateUserWithSSO,
  ChangeActiveUserStatus,
  CompleteOnboarding,
  CompleteReferralAction,
  FinishEnterpriseOnboarding,
  InviteCollaborators,
  IsActivateEligible,
  IsEdlinkUser,
  LoginWithEdlink,
  LoginWithEmailId,
  ReferAFriend,
  ReferralLinkSignUp,
  ReferralSignupCompleted,
  ResendVerificationEmail,
  ResetPasswordWithEmailId,
  SendReferralEmailInvites,
  SetNewPassword,
  SignUpWithEmailId,
  SubscribeToNewsletter,
  UpdateInvite,
  UpdatePasswordWithEmailId,
  UpdateUserDistrictRole,
  UpdateWorkspace,
  VerifyEmail,
} from "../graphql/auth/auth.mutations";
import { UpdateUserDetails } from "../graphql/user/user.mutations";
import AiTool from "../models/AiTool";
import { EnumAiToolType } from "../models/AiToolParticipant";
import BotConversation from "../models/BotConversation";
import { ParticipantUser } from "../models/BotConversationParticipant";
import LocalStorage from "../utils/LocalStorage";
import {
  EnumAiToolParticipantPermissions,
  EnumDistrictWorkspaceType,
  EnumInviteStatus,
  EnumReferralPendingAction,
  EnumUserDistrictRoles,
} from "../__generated__/graphql";
import { RefreshSubscriptionUser } from "../graphql/user/user.queries";

const STORE_NAME = "AUTH";
const NON_REDIRECT_PATHS = ["/", "/dashboard", "/home", "/logout"];

type LocalLocalStorageData = {
  token?: string;
  userId?: string;
  user?: User;
  // district?: District;
  // abilities?: Ability[];
};

type UserDetails = {
  firstName: string;
  lastName: string;
  email: string;
  districtRoles: EnumUserDistrictRoles[];
  avatarUrl?: string;
};

type WorkspaceDetails = {
  id: string;
  name: string;
  workspaceType: EnumDistrictWorkspaceType;
  [key: string]: any;
};

export type VerifyEmailHash = {
  match: boolean;
  isOnboarded: boolean;
};

export default class AuthStore {
  @observable
  user?: User | null;

  //   @observable
  //   org?: Organization | null;

  @observable
  token?: string | null;

  @observable
  userId?: string | null;

  //   @observable
  //   abilities: Ability[] = [];

  @observable
  saving = false;

  @observable
  error: string | null;

  @observable
  isSuspended = false;

  rootStore: RootStore;

  apolloClient: ApolloClient<any>;

  @observable
  loginError: string | null;

  @observable
  signupError: string | null;

  @observable
  resetPasswordError: string | null;

  @observable
  onboardingError: string | null;

  @observable
  isCompletingOnboarding: boolean = false;

  @observable
  isLoggingIn: boolean = false;

  @observable
  isSigningUp: boolean = false;

  @observable
  isResettingPassword: boolean = false;

  // We will essentially use this to force fetch auth on every refresh (isInitialized)
  @observable
  isLoading: boolean = false;

  @observable
  isInitialized: boolean = false;

  @observable
  isUpdatingPassword: boolean = false;

  @observable
  updatePasswordError: string | null;

  @observable
  isSubscribingToNewsletter: boolean = false;

  @observable
  isCheckingActivationValidity: boolean = false;

  @observable
  activationValidityError: string | null;

  @observable
  activatingUser: boolean = false;

  @observable
  activatingUserError: string | null;

  @observable
  refreshingUserSubscription: boolean = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    this.rootStore = rootStore;
    this.apolloClient = apolloClient;

    const data: LocalLocalStorageData = LocalStorage.retrieve(STORE_NAME) || {};

    this.rehydrateStore(data);

    autorun(() => {
      LocalStorage.save(STORE_NAME, this.toLocalLocalStorageData);
    });

    // write a mobx reaction to listen to userId and call fetch
    autorun(() => {
      if (this.userId && this.isInitialized) {
        this.fetch();
      }
    });

    window.addEventListener("storage", (event) => {
      if (event.key === STORE_NAME && event.newValue) {
        const data: LocalLocalStorageData | null | undefined = JSON.parse(
          event.newValue
        );
        // data may be null if key is deleted in localLocalStorage
        if (!data) {
          return;
        }

        if (this.validSession) {
          if (data.userId === null) {
            this.logout();
          }
        } else {
          this.rehydrateStore(data);
        }
      }
    });
  }

  @action
  rehydrateStore(data: LocalLocalStorageData) {
    this.token = data.token;
    this.userId = data.userId;
    this.user = data.user ? new User(data.user, this) : undefined;

    // this.addAbilities(data.abilities);

    if (this.token && this.userId) {
      setTimeout(() => this.fetch(), 0);
    }

    this.isInitialized = true;
  }

  //   addAbilities(abilities?: Ability[]) {
  //     if (abilities) {
  //       // We persist user abilities
  //       this.abilities = abilities;
  //       abilities.forEach((ability) => this.rootStore.abilities.add(ability));
  //     }
  //   }

  @computed
  get validSession(): boolean {
    return !!this.token;
  }

  @computed
  get toLocalLocalStorageData() {
    return {
      token: this.token,
      userId: this.userId,
      // user: this.user,
      //   abilities: this.abilities,
    };
  }

  @action
  fetch = async (): Promise<User | null> => {
    // Fetch the user data
    this.isLoading = true;

    try {
      invariant(
        this.userId,
        "Trying to fetch without Authenticated User without id initiated"
      );

      const { data, error } = await this.apolloClient.query({
        query: GetAuthUser,
        variables: {
          where: {
            id: this.userId,
          },
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      invariant(data, "Could not fetch authenticated user.");

      runInAction(async () => {
        // Add the abilities we fetch for the user using this.addAbilities
        const { user } = data;

        invariant(user, "User must exist");

        const { district } = user;

        const {
          conversations,
          aiTools,
          users,
          drives,
          aiToolParticipantsStore,
          conversationParticipants,
        } = this.rootStore;

        const toAddConversations: BotConversation[] = [];
        const toAddAiTools: AiTool[] = [];

        user.botConversationParticipants.map((participant) => {
          if (!participant.botConversation) return;

          const sanitizedParticipant = {
            id: participant.id,
            user: participant.user as ParticipantUser,
            owner: participant.owner,
            permissions: participant.permissions,
            createdAt: participant.createdAt,
            updatedAt: participant.updatedAt,
          };

          conversationParticipants.add(sanitizedParticipant as any);

          toAddConversations.push(
            conversations.add(participant.botConversation)
          );
        });

        user.aiToolParticipants.map((participant) => {
          if (!participant.aiTool) return;

          invariant(participant.user, "Participant must have a user");

          const aiToolType =
            participant.aiTool.toolId === "ai-differentiated-resource-generator"
              ? EnumAiToolType.INSTANT_RESOURCES
              : participant.aiTool.toolId === "ai-image-generator"
              ? EnumAiToolType.IMAGE_GENERATOR
              : EnumAiToolType.CURRICULUM_CREATOR;

          const sanitizedParticipant = {
            id: participant.id,
            aiToolId: participant.aiTool.id,
            owner: participant.owner,
            permissions:
              participant.permissions as EnumAiToolParticipantPermissions[],
            userId: participant.user.id,
            firstName: participant.user.firstName,
            lastName: participant.user.lastName,
            email: participant.user.email,
            avatarUrl: participant.user.avatarUrl,
            createdAt: participant.createdAt,
            updatedAt: participant.updatedAt,
            aiToolType,
          };

          aiToolParticipantsStore.add(sanitizedParticipant);

          toAddAiTools.push(this.rootStore.aiTools.add(participant.aiTool));
        });

        user.drives.map((drive) => {
          invariant(drive.driveType, "Drive must have a driveType");

          const addDrive = {
            id: drive.id,
            driveType: drive.driveType,
            createdAt: drive.createdAt,
            updatedAt: drive.updatedAt,
            user: drive.user ? drive.user.id : undefined,
            district: drive.district ? drive.district.id : undefined,
          };

          drives.add(addDrive);
        });

        if (district) {
          district.users.map((user) => {
            const districtUser = {
              avatarUrl: user.avatarUrl,
              email: user.email,
              firstName: user.firstName || undefined,
              id: user.id,
              lastName: user.lastName || undefined,
              createdAt: user.createdAt,
              updatedAt: user.updatedAt,
            };

            users.add(districtUser);
          });

          district.drives.map((drive) => {
            invariant(drive.driveType, "Drive must have a driveType");

            const addDrive = {
              id: drive.id,
              driveType: drive.driveType,
              createdAt: drive.createdAt,
              updatedAt: drive.updatedAt,
              user: drive.user ? drive.user.id : undefined,
              district: drive.district ? drive.district.id : undefined,
            };

            drives.add(addDrive);
          });
        }

        conversations.setBotConversations(toAddConversations);
        aiTools.setAiTools(toAddAiTools);

        // Save user without __typename
        if (this.user) {
          this.user.updateFromJson(omit(user, "__typename"));
        } else {
          this.user = new User(omit(user, "__typename"), this);
        }

        // If we came from a redirect then send the user immediately there
        const redirectAfterLogin: string | null = await LocalStorage.retrieve(
          "redirectAfterLogin"
        );

        if (redirectAfterLogin) {
          await LocalStorage.delete("redirectAfterLogin");

          if (!NON_REDIRECT_PATHS.includes(redirectAfterLogin)) {
            window.location.href = redirectAfterLogin;
          }
        }
      });

      return this.user || null;
    } catch (err: any) {
      // Check if user has been suspended
      console.log("Err inside ", err);
      // Else set error
      this.error = err.message;

      return this.user || null;
    } finally {
      this.isLoading = false;
    }
  };

  @action
  clearAuthErrors = () => {
    this.resetPasswordError = null;
    this.loginError = null;
    this.signupError = null;
  };

  // Login and set the userId and token
  @action
  loginUserWithEmail = async (
    email: string,
    password: string
  ): Promise<AuthResponse> => {
    this.isLoggingIn = true;

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: LoginWithEmailId,
          variables: {
            credentials: {
              email,
              password,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.loginWithEmail) {
            const { accessToken, user } = res.data.loginWithEmail;

            if (user) {
              // Store token and user in local storage
              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken;

                setTimeout(() => this.fetch(), 0);
              });

              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Please try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  @action
  getStripeCustomerManagementURL = async (
    customerId: string
  ): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      this.apolloClient
        .query({
          query: getStripeCustomerManaementUrl,
          variables: {
            customerId,
          },
        })
        .then((res) => {
          if (res.data && res.data.getStripeCustomerManagementURL) {
            const url = res.data.getStripeCustomerManagementURL;
            resolve(url);
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Please try again.";
            });
            resolve("");
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message;
          });

          resolve("");
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  @action
  changeActiveUserStatus = async (
    userId: string,
    districtId: string,
    assignSeat: boolean,
    removeFromWorkspace: boolean
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      this.apolloClient

        .mutate({
          mutation: ChangeActiveUserStatus,
          variables: {
            activeUserStatusInput: {
              userId,
              districtId,
              assignSeat,
              removeFromWorkspace,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.changeActiveUserStatus) {
            const success = res.data.changeActiveUserStatus;
            if (success) {
              this.fetch();
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Please try again.";
            });
            resolve(false);
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message;
          });

          resolve(false);
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  @action
  acceptInvite = async (
    email: string,
    inviteId: string,
    password?: string,
    firstName?: string,
    lastName?: string
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: AcceptInvite,
          variables: {
            inviteInput: {
              email,
              inviteId,
              password: password ? password : undefined,
              firstName: firstName ? firstName : undefined,
              lastName: lastName ? lastName : undefined,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.acceptInvite) {
            const success = res.data.acceptInvite;
            if (success) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Please try again.";
            });
            resolve(false);
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message;
          });

          resolve(false);
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  @action
  updateInviteStatus = async (inviteId: string, status: EnumInviteStatus) => {
    return new Promise<boolean>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: UpdateInvite,
          variables: {
            data: {
              status,
            },
            where: {
              id: inviteId,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.updateInvite) {
            const success = res.data.updateInvite;
            if (success) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Please try again.";
            });
            resolve(false);
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message;
          });

          resolve(false);
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  @action
  verifyHashInput = async (
    email: string,
    hash: string
  ): Promise<VerifyEmailHash | null> => {
    return new Promise<VerifyEmailHash | null>((resolve, reject) => {
      this.apolloClient
        .query({
          query: VerifyEmailHash,
          variables: {
            emailHashInput: {
              email,
              hash,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.verifyEmailHash) {
            const response: any = res.data.verifyEmailHash;
            resolve(response);
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Please try again.";
            });
            resolve(null);
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message;
          });

          resolve(null);
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  @action
  getInvitesForEmail = async (email: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetInvitesForEmail,
          variables: {
            where: {
              email: {
                equals: email,
              },
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.invitesForEmail) {
            const invites = res.data.invitesForEmail;
            resolve(invites);
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Please try again.";
            });
            resolve([]);
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message;
          });

          resolve([]);
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  @action
  getUserByEmail = async (email: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetUserByEmail,
          variables: {
            userEmailInput: {
              email,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.getUserByEmail) {
            resolve(res.data.getUserByEmail);
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          runInAction(() => {
            // this.loginError = err.message;
          });

          resolve(null);
        })
        .finally(() => {
          console.log("finally");
          runInAction(() => {
            this.isLoggingIn = false;
            // this.isRegisteredUser = false;
          });
        });
    });
  };

  @action
  updateUserRole = async (
    districtRoles: EnumUserDistrictRoles[],
    userId: string,
    districtId: string
  ): Promise<boolean> => {
    invariant(userId, "Trying to refer friends without userId");

    return new Promise<boolean>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: UpdateUserDistrictRole,
          variables: {
            updateUserDistrictRoleInput: {
              districtRoles,
              userId,
              districtId,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.updateUserDistrictRole) {
            this.fetch();
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          console.log(err);
          runInAction(() => {
            // this.loginError = err.message;
          });

          resolve(false);
        })
        .finally(() => {
          console.log("finally");
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  @action
  inviteCollaborators = async (
    emails: string[],
    userId: string,
    districtId: string,
    districtRoles: EnumUserDistrictRoles[]
  ): Promise<boolean> => {
    invariant(userId, "Trying to refer friends without userId");

    return new Promise<boolean>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: InviteCollaborators,
          variables: {
            collaboratorsInput: {
              emails,
              userId,
              districtId,
              districtRoles,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.inviteCollaborators) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          console.log(err);
          runInAction(() => {
            // this.loginError = err.message;
          });

          resolve(false);
        })
        .finally(() => {
          console.log("finally");
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  @action
  completeOnboarding = async (onboardingInput: any): Promise<AuthResponse> => {
    this.isCompletingOnboarding = true;

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: CompleteOnboarding,
          variables: {
            onboardingInput,
          },
        })
        .then((res) => {
          if (res.data && res.data.finishAIAppOnboarding) {
            // const { accessToken, user } = res.data.loginWithEmail;
            const success = res.data.finishAIAppOnboarding;
            if (success) {
              // We retrieve the updated user object from backend
              runInAction(() => {
                this.fetch();
              });

              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Please try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.onboardingError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isCompletingOnboarding = false;
          });
        });
    });
  };

  // Now write the same action for signUpWithEmail
  @action
  signupUserWithEmail = async (
    email: string,
    password: string
  ): Promise<AuthResponse> => {
    this.isSigningUp = true;
    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: SignUpWithEmailId,
          variables: {
            credentials: {
              email,
              password,
            },
          },
        })
        .then(async (res) => {
          if (res.data && res.data.signUpWithEmailForAI) {
            const { accessToken, user } = res.data.signUpWithEmailForAI;

            if (user) {
              // Store token and user in local storage
              await localStorage.setItem("newUserId", user.id);

              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken;

                setTimeout(() => this.fetch(), 0);
              });

              resolve({
                success: true,
                userId: user.id,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.signupError = "Something went wrong. Please try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.signupError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isSigningUp = false;
          });
        });
    });
  };

  // Reset password

  @action
  resetPasswordWithEmail = async (email: string): Promise<AuthResponse> => {
    this.isResettingPassword = true;
    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: ResetPasswordWithEmailId,
          variables: {
            email,
          },
        })
        .then((res) => {
          if (res.data && res.data.resetPasswordWithEmail) {
            const success = res.data.resetPasswordWithEmail;

            if (success) {
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.resetPasswordError =
                "Something went wrong. Please try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.resetPasswordError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isResettingPassword = false;
          });
        });
    });
  };

  // Login with Google
  @action
  loginWithGoogle = async (location: Location): Promise<AuthResponse> => {
    this.isLoggingIn = true;
    return new Promise<AuthResponse>((resolve, reject) => {
      const url = `${
        import.meta.env.VITE_APP_SERVER_URL
      }/api/auth/google/callback`;
      const endpoint = url + location.search;

      fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(async (data) => {
          if (data.user && !data.error) {
            const { accessToken, user } = data;

            console.log("User from loginWithGoogle", user);

            if (user) {
              await localStorage.setItem("newUserId", user.id);

              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken;
                this.user = new User(omit(user, "__typename"), this);
              });

              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            resolve({
              success: false,
              error: data.message,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });
          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  // Login with Microsoft copy paste from google
  @action
  loginWithMicrosoft = async (location: Location): Promise<AuthResponse> => {
    this.isLoggingIn = true;
    return new Promise<AuthResponse>((resolve, reject) => {
      const url = `${
        import.meta.env.VITE_APP_SERVER_URL
      }/api/auth/microsoft/callback`;
      const endpoint = url + location.search;

      console.log("End point", endpoint);

      fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(async (data) => {
          console.log("Login with Microsoft Data", data);

          if (data.user && !data.error) {
            const { accessToken, user } = data;

            if (user) {
              await localStorage.setItem("newUserId", user.id);

              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken;
                this.user = new User(omit(user, "__typename"), this);
              });
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            resolve({
              success: false,
              error: data.message,
            });
          }
        })
        .catch((err) => {
          console.log("Error in LoginWithMicrosoft", err);

          runInAction(() => {
            this.loginError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });
          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  @action
  updatePassword = async (
    token: string,
    password: string
  ): Promise<AuthResponse> => {
    this.isUpdatingPassword = true;

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: UpdatePasswordWithEmailId,
          variables: {
            credentials: {
              token,
              password,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.updatePassword) {
            const success = res.data.updatePassword;

            if (success) {
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.updatePasswordError =
                "Something went wrong. Please try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.updatePasswordError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isUpdatingPassword = false;
          });
        });
    });
  };

  @action
  setNewPassword = async (
    currentPassword: string,
    newPassword: string
  ): Promise<AuthResponse> => {
    this.isUpdatingPassword = true;

    const email = this.user && this.user.email ? this.user.email : "";
    if (!email) {
      return Promise.resolve({ success: false, error: "No email found" });
    }

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: SetNewPassword,
          variables: {
            passwordInput: {
              email,
              currentPassword,
              newPassword,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.setNewPassword) {
            const success = res.data.setNewPassword;

            if (success) {
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.updatePasswordError =
                "Something went wrong. Please try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.updatePasswordError = err.message
              ? err.message
              : "Something went wrong. Please try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isUpdatingPassword = false;
          });
        });
    });
  };

  // Helper function to check reset password token validity since tokens expire
  checkUpdatePasswordPageValidity = async (
    token: string
  ): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .query({
          query: CheckUpdatePasswordTokenValidity,
          variables: {
            token,
          },
        })
        .then((res: any) => {
          if (res.data && res.data.checkUpdatePasswordPageValidity) {
            resolve({
              success: res.data.checkUpdatePasswordPageValidity ? true : false,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          resolve({
            success: false,
          });
        });
    });
  };

  @action
  updateUserDetails = async (user: UserDetails): Promise<AuthResponse> => {
    const id = this.user && this.user.id ? this.user.id : "";
    if (!id) {
      return Promise.resolve({ success: false, error: "No id found" });
    }

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: UpdateUserDetails,
          variables: {
            userDetailsInput: {
              ...user,
              id,
            },
          },
        })
        .then((res: any) => {
          if (res.data && res.data.updateUserDetails) {
            this.fetch();
            resolve({
              success: res.data.updateUserDetails,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          resolve({
            success: false,
          });
        });
    });
  };

  @action
  updateWorkspace = async (
    workspace: WorkspaceDetails
  ): Promise<AuthResponse> => {
    const id = this.user && this.user.id ? this.user.id : "";
    if (!id) {
      return Promise.resolve({ success: false, error: "No id found" });
    }

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: UpdateWorkspace,
          variables: {
            updateWorkspaceInput: {
              ...workspace,
            },
          },
        })
        .then((res: any) => {
          if (res.data && res.data.updateWorkspace) {
            this.fetch();
            resolve({
              success: res.data.updateWorkspace,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          resolve({
            success: false,
          });
        });
    });
  };

  @action
  verifyEmail = async (userId: string): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: VerifyEmail,
          variables: {
            userId,
          },
        })
        .then(async (res: any) => {
          if (res.data && res.data.verifyEmail) {
            await this.fetch();
            resolve({
              success: res.data.verifyEmail,
            });
          } else {
            resolve({
              success: false,
              error: "Email is already verified",
            });
          }
        })
        .catch((e) => {
          resolve({
            success: false,
            error: "Something went wrong",
          });
        });
    });
  };

  @action
  resendVerifyEmail = async (userId: string): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: ResendVerificationEmail,
          variables: {
            userId,
          },
        })
        .then((res: any) => {
          if (res.data && res.data.resendVerificationEmail) {
            this.fetch();
            resolve({
              success: res.data.resendVerificationEmail,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          resolve({
            success: false,
          });
        });
    });
  };

  // V1: Legacy Referral system
  @action
  referFriends = async (emails: string[], userId: string): Promise<boolean> => {
    invariant(userId, "Trying to refer friends without userId");

    return new Promise<boolean>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: ReferAFriend,
          variables: {
            referralInput: {
              emails,
              userId,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.referAFriend) {
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          console.log(err);
          runInAction(() => {
            // this.loginError = err.message;
          });

          resolve(false);
        })
        .finally(() => {
          console.log("finally");
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  // V1: Legacy Referral system
  @action
  referralLinkSignUp = async (
    referredBy: string,
    newUserId: string,
    isVerified?: boolean
  ): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: ReferralLinkSignUp,
          variables: {
            referralLinkSignUpInput: {
              referredBy,
              newUserId,
              isVerified,
            },
          },
        })
        .then((res: any) => {
          if (res.data && res.data.referralLinkSignUp) {
            this.fetch();
            resolve({
              success: res.data.referralLinkSignUp,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          resolve({
            success: false,
          });
        });
    });
  };

  // V2: Referral Program
  @action
  getReferralInfo = async (referralCode: string, product?: string) => {
    return new Promise<any>((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetReferralInfo,
          variables: {
            referralInfoArgs: {
              referralCode,
              product,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.getReferralInfo) {
            resolve({
              success: true,
              ...res.data.getReferralInfo,
            });
          } else {
            resolve({
              success: false,
              error:
                "Failed to process referral code. Please try again or contact support.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          resolve({
            success: false,
            error: err.message,
          });
        });
    });
  };

  @action
  getReferralLink = async () => {
    return new Promise<any>((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetReferralLink,
          variables: {
            product: "Copilot",
          },
        })
        .then((res) => {
          if (res.data && res.data.getReferralLink) {
            resolve({
              referralLink: res.data.getReferralLink,
            });
          } else {
            resolve({
              referralLink: "",
              error:
                "Failed to process referral code. Please try again or contact support.",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          resolve({
            referralLink: "",
            error: err.message,
          });
        });
    });
  };

  @action
  referralSignupCompleted = async (
    userId: string,
    referralCode: string,
    product: string
  ): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: ReferralSignupCompleted,
          variables: {
            userId,
            referralCode,
            product,
          },
        })
        .then((res: any) => {
          if (res.data && res.data.referralSignupCompleted) {
            this.fetch();
            resolve({
              success: res.data.referralSignupCompleted,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          resolve({
            success: false,
          });
        });
    });
  };

  @action
  completedReferralActionChat = async (
    pendingAction: EnumReferralPendingAction
  ): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: CompleteReferralAction,
          variables: {
            referralCompleteActionArgs: {
              pendingAction,
            },
          },
        })
        .then((res: any) => {
          if (res.data && res.data.completeReferralAction) {
            this.fetch();
            resolve({
              success: res.data.completeReferralAction,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          resolve({
            success: false,
          });
        });
    });
  };

  @action
  sendReferralInvites = async (emails: string[]): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: SendReferralEmailInvites,
          variables: {
            referralEmailInviteArgs: {
              emails,
              product: "Copilot",
            },
          },
        })
        .then((res: any) => {
          if (res.data && res.data.sendReferralEmailInvites) {
            resolve({
              success: res.data.sendReferralEmailInvites,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          resolve({
            success: false,
          });
        });
    });
  };

  @action
  loginWithEdlink = async (
    email: string,
    code: string
  ): Promise<AuthResponse> => {
    this.isLoggingIn = true;
    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: LoginWithEdlink,
          variables: {
            credentials: {
              email,
              code,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.loginWithEdlink) {
            const { accessToken, user } = res.data.loginWithEdlink;
            if (user) {
              // Store token and user in local storage
              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken;
                setTimeout(() => this.fetch(), 0);
              });
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.loginError = "Something went wrong. Try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  @action
  checkIfEdlinkUser = async (email: string): Promise<AuthResponse> => {
    this.isLoggingIn = true;

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: IsEdlinkUser,
          variables: {
            email,
          },
        })
        .then((res) => {
          if (res.data && res.data.isEdlinkUser) {
            resolve({
              success: true,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.loginError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoggingIn = false;
          });
        });
    });
  };

  checkEnterpriseOboardingValidity = async (
    userId: string
  ): Promise<AuthResponse> => {
    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .query({
          query: CheckEnterpriseOboardingValidity,
          variables: {
            userId,
          },
        })
        .then((res: any) => {
          if (res.data && res.data.checkEnterpriseOboardingValidity) {
            resolve({
              success: res.data.checkEnterpriseOboardingValidity ? true : false,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((e) => {
          resolve({
            success: false,
          });
        });
    });
  };

  @action
  finishEnterpriseOnboarding = async (
    userId: string,
    password: string
  ): Promise<AuthResponse> => {
    this.isUpdatingPassword = true;

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: FinishEnterpriseOnboarding,
          variables: {
            credentials: {
              userId,
              password,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.finishEnterpriseOnboarding) {
            const success = res.data.finishEnterpriseOnboarding;

            if (success) {
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            runInAction(() => {
              this.updatePasswordError = "Something went wrong. Try again.";
            });
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.updatePasswordError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isUpdatingPassword = false;
          });
        });
    });
  };

  // Check activation validity
  @action
  checkIfActivationValid = async (userId: string): Promise<AuthResponse> => {
    this.isCheckingActivationValidity = true;

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: IsActivateEligible,
          variables: {
            isActiveEligibleInput: {
              userId,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.isActivateEligible) {
            resolve({
              success: true,
            });
          } else {
            this.activationValidityError =
              "This user is not eligible for activation.";
            resolve({
              success: true,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.activationValidityError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isCheckingActivationValidity = false;
          });
        });
    });
  };

  @action
  activateUserWithPassword = async (
    userId: string,
    password: string
  ): Promise<AuthResponse> => {
    this.activatingUser = true;

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: ActivateUserWithPassword,
          variables: {
            activateUserWithPasswordInput: {
              userId,
              password,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.activateUserWithPassword) {
            const { accessToken, user } = res.data.activateUserWithPassword;

            if (user) {
              // Store token and user in local storage
              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken;

                setTimeout(() => this.fetch(), 0);
              });
            }

            resolve({
              success: true,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.activatingUserError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.activatingUser = false;
          });
        });
    });
  };

  @action
  activateUserWithSSO = async (
    userId: string,
    provider: string
  ): Promise<AuthResponse> => {
    this.activatingUser = true;

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: ActivateUserWithSSO,
          variables: {
            activateUserWithSSOInput: {
              userId,
              provider,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.activateUserWithSSO) {
            const { accessToken, user } = res.data.activateUserWithSSO;

            console.log("activateUserWithSSO", res.data.activateUserWithSSO);

            if (user) {
              // Store token and user in local storage
              runInAction(() => {
                this.userId = user.id;
                this.token = accessToken;

                if (this.user) {
                  this.user.updateFromJson(omit(user, "__typename"));
                }
              });
            }

            resolve({
              success: true,
            });
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          runInAction(() => {
            this.activatingUserError = err.message
              ? err.message
              : "Something went wrong. Try again.";
          });

          resolve({
            success: false,
            error: "Something went wrong. Try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.activatingUser = false;
          });
        });
    });
  };

  @action
  subscribeToNewsletter = async (email: string): Promise<AuthResponse> => {
    this.isSubscribingToNewsletter = true;

    return new Promise<AuthResponse>((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: SubscribeToNewsletter,
          variables: {
            newsletterArgs: {
              email,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.subscribeToNewsletter) {
            const success = res.data.subscribeToNewsletter;

            if (success) {
              resolve({
                success: true,
              });
            } else {
              resolve({
                success: false,
              });
            }
          } else {
            resolve({
              success: false,
            });
          }
        })
        .catch((err) => {
          resolve({
            success: false,
            error: err.message || "Something went wrong. Please try again.",
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isSubscribingToNewsletter = false;
          });
        });
    });
  };

  @action
  refreshSubscriptionUser = async (): Promise<boolean> => {
    // Fetch the user data
    this.refreshingUserSubscription = true;

    try {
      invariant(
        this.userId,
        "Trying to fetch without User without id initiated"
      );

      const { data, error } = await this.apolloClient.query({
        query: RefreshSubscriptionUser,
        variables: {
          where: {
            id: this.userId,
          },
        },
      });

      if (error) {
        throw new Error(error.message);
      }

      invariant(data, "Could not fetch authenticated user.");

      runInAction(async () => {
        // Add the abilities we fetch for the user using this.addAbilities
        const { user } = data;

        invariant(user, "User must exist");

        // Save user without __typename
        if (this.user) {
          this.user.updateFromJson(omit(user, "__typename"));
        } else {
          this.user = new User(omit(user, "__typename"), this);
        }
      });

      return true;
    } catch (err: any) {
      // Check if user has been suspended
      console.log("Err inside ", err);
      // Else set error
      this.error = err.message;

      return false;
    } finally {
      this.refreshingUserSubscription = false;
    }
  };

  @action
  logout = async (saveRoute = false) => {
    // if this logout was forced from an protected route then
    // save the current path so we can go back there once signed in
    if (saveRoute) {
      const pathName = window.location.pathname;

      if (!NON_REDIRECT_PATHS.includes(pathName)) {
        LocalStorage.save("redirectAfterLogin", pathName);
      }
    }

    // If there is no auth token stored there is nothing else to do
    if (!this.validSession) {
      return;
    }

    // @ts-ignore
    window!.Intercom("shutdown");

    // clear all credentials from cache (and local storage via autorun)
    runInAction(() => {
      this.user = null;
      this.userId = null;
      this.token = null;

      // Clear all other data
      this.rootStore.logout();
    });
  };
}
