import { gql } from "../../__generated__/gql";

export const GetDemoConversation = gql(`
  query GetDemoConversation(
    $conversationId: String!
  ) {
    demoConversation(conversationId: $conversationId) {
      createdAt   
      data             
      deletedAt                 
      id                         
      title                     
      updatedAt                
      urlId                     
    }
  }
`);

export const GetDemoConversationEntries = gql(`
  query GetDemoConversationEntries(
    $conversationId: String!
  ) {
    demoConversationEntries(conversationId: $conversationId) {
        conversationId
        createdAt
        deletedAt
        entry
        id
        speaker
        updatedAt
        userId              
    }
  }
`);

export const GetDemoAiTool = gql(`
    query Get(
        $toolId: String!
    ) {
      demoAiTool(toolId: $toolId) {
            createdAt
            id
            data
            initData
            title 
            toolId
            updatedAt
            urlId
            version
        }
    }
`);

export const GetDemoAiToolSteps = gql(`
    query GetDemoAiToolSteps(
        $toolId: String!
    ) {
      demoAiToolSteps(toolId: $toolId) {
            aiToolId
            createdAt
            data
            id 
            stepId
            updatedAt
        }
    }
`);

export const RetrieveSandboxUses = gql(`
    query RetrieveSandboxUses(
        $clientId: String!
    ) {
      retrieveSandboxUses(clientId: $clientId) {
            chatUses
            advancedCurriculumToolUses
            instantResourceUses
            imageGeneratorUses
        }
    }
`);
