import { gql } from "../../__generated__/gql";

export const GetUsers = gql(`
    query GetUsers($where: UserWhereInput!, $orderBy: [UserOrderByInput!]) {
        users(where: $where, orderBy: $orderBy) {
            avatarUrl
            createdAt
            deletedAt
            district {
                id
            }
            edlinkId
            email
            firstName
            gradeLevels
            id 
            identifiers
            lastName
            middleName
            schools {
                id
            }
            updatedAt
            username 
        }
    }
`);
