import { gql } from "../../__generated__/gql";

export const GetBotConversation = gql(`
  query GetBotConversation(
    $where: BotConversationWhereUniqueInput!
  ) {
    botConversation(where: $where) {
      createdAt
      data
      deletedAt
      id
      title
      updatedAt
      urlId
    }
  }
`);

export const GetBotConversations = gql(`
  query GetBotConversations(
    $where: BotConversationWhereInput!
  ) {
    botConversations(where: $where) {
      createdAt
      data
      deletedAt
      id
      title
      updatedAt
      urlId
    }
  }
`);

export const GetBotConversationByUrlId = gql(`
  query GetBotConversationByUrlId(
    $where: BotConversationUrlIdInput!
  ) {
    botConversationByUrlId(where: $where) {
      createdAt
      data
      deletedAt
      id
      title
      updatedAt
      urlId
    }
  }
`);

export const GetChatByUrlId = gql(`
  query GetChatByUrlId(
    $chatId: String!
  ) {
    getChatByUrlId(chatId: $chatId) {
      conversationEntries{
        createdAt
        deletedAt
        entry
        id
        speaker
        updatedAt
        user {
          id
          firstName
          lastName
          avatarUrl
        }
      }
      createdAt   
      data             
      deletedAt                 
      id                         
      title                     
      updatedAt                
      urlId                     
      botConversationParticipants {
        createdAt
        deletedAt
        id
        updatedAt
        owner 
        permissions
        user {
          id
          firstName
          lastName
          avatarUrl
        }
      }   
    }
  }
`);

export const GetBotConversationEntries = gql(`
    query GetBotConversationEntries($where: BotConversationEntryWhereInput!) {
    botConversationEntries(where: $where) 
        {
            conversation {
                id
            }
            createdAt
            deletedAt
            entry
            id
            speaker
            updatedAt
            user {
                id
            }
        }
    }
`);

export const GetBotConversationParticipants = gql(`
    query GetBotConversationParticipants($where: BotConversationParticipantWhereInput!) {
    botConversationParticipants(where: $where) 
        {
          createdAt
          deletedAt
          id
          updatedAt
          owner 
          permissions
          user {
            id
            firstName
            lastName
            avatarUrl
          }
        }
    }
`);
