import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, computed, observable, runInAction } from "mobx";
import { NewBotConversation } from "../graphql/botConversation/botConversation.mutations";
import {
  GetBotConversationByUrlId,
  GetChatByUrlId,
} from "../graphql/botConversation/botConversation.queries";
import BotConversation from "../models/BotConversation";
import { NewBotConversationInput } from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class BotConversationStore extends BaseStore<BotConversation> {
  @observable
  isLoading = false;

  @observable
  isLoadingCourseResources = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, BotConversation, apolloClient);
    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  setBotConversations = (botConversations: BotConversation[]) => {
    botConversations.forEach((botConversation) => {
      this.add(botConversation);
    });
  };

  @action
  fetchConversationAndChatByUrlId = (urlId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetChatByUrlId,
          variables: {
            chatId: urlId,
          },
        })
        .then((res) => {
          const conversation = res.data.getChatByUrlId;
          resolve(conversation);
        })
        .catch((e) => {
          reject(null);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
        });
    });
  };

  @action
  fetchByUrlId = (urlId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetBotConversationByUrlId,
          variables: {
            where: {
              urlId,
            },
          },
        })
        .then((res) => {
          const conversation = res.data.botConversationByUrlId;

          invariant(conversation, "Conversation not found");

          const sanitizeBotConversation = {
            createdAt: conversation.createdAt,
            data: conversation.data,
            deletedAt: conversation.deletedAt,
            id: conversation.id,
            title: conversation.title,
            updatedAt: conversation.updatedAt,
            urlId: conversation.urlId,
          };

          // Fetch the entries for this conversation
          this.rootStore.conversationEntries.fetchBotConversationEntries(
            conversation.id
          );

          this.rootStore.conversationParticipants.fetchConversationParticipants(
            conversation.id
          );

          this.rootStore.aiFeedback.fetchAiFeedbackForParentEntityId(
            conversation.id
          );

          this.add(sanitizeBotConversation);

          resolve(true);
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
        });
    });
  };

  @action
  async createNewConversation(
    data: NewBotConversationInput
  ): Promise<BotConversation> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: NewBotConversation,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.newBotConversation) {
        throw new Error("No data returned from newBotConversation mutation");
      }

      const convo = res.data.newBotConversation;

      const sanitizedBotConversation = {
        createdAt: convo.createdAt,
        data: convo.data,
        id: convo.id,
        title: convo.title,
        urlId: convo.urlId,
        updatedAt: convo.updatedAt,
      };

      return this.add(sanitizedBotConversation);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @computed
  get sortedData(): BotConversation[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }

  getByUrlParam = (urlId: string): BotConversation | undefined => {
    return this.sortedData.find((calendar) => urlId.endsWith(calendar.urlId));
  };
}
