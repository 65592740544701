import clsx from "clsx";
import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useToastsContext } from "../../contexts/toasts";
import {
  CreateRefreshToken,
  HandleApiSignOut,
} from "../../graphql/auth/auth.mutations";
import useIsMobile from "../../hooks/useIsMobile";
import useStores from "../../hooks/useStores";
import Button from "../Button";
import "./integrations.css";
import useUserAuditLogs from "../../hooks/useUserAuditLogs";
import posthog from "posthog-js";
import { useTranslation } from "react-i18next";
const OneDrive = "/imgs/blocks/onedrive.png";

type Details = {
  name: string;
  email: string;
};

function OneDriveIntegration(props: any) {
  const { auth } = useStores();

  const { addToast } = useToastsContext();

  const { t } = useTranslation();

  const params = useParams();
  const [searchParams] = useSearchParams();

  const isMobile = useIsMobile();

  const { createUserAuditLog } = useUserAuditLogs();

  const redirectFrom = params.redirectFrom;
  const code = searchParams.get("code");

  const [details, setDetails] = useState<Details | null>(null);
  const loadIntegrationDetails = useCallback(() => {
    const integrations = auth.user?.integrations;
    if (integrations && integrations["one-drive"]) {
      setDetails({
        name: integrations["one-drive"].name,
        email: integrations["one-drive"].email,
      });
    }
  }, []);

  useEffect(() => {
    if (!auth.user || !auth.user.id) {
      return;
    }
    if (code && redirectFrom === "one-drive") {
      auth.apolloClient
        .mutate({
          mutation: CreateRefreshToken,
          variables: {
            tokenInput: {
              code,
              userId: auth.user.id,
              type: "one-drive",
            },
          },
        })
        .then((res: any) => {
          console.log(res);
          if (res && res.data && res.data.createRefreshToken) {
            // addToast("One Drive Enabled.", {
            //   type: "success",
            // });
            window.location.href = "/settings/profile";
          } else {
            addToast(t("Something went wrong. Please try again later."), {
              type: "error",
            });
          }
        });
    } else {
      loadIntegrationDetails();
    }
  }, [code, redirectFrom, auth]);

  async function handleSignIn() {
    if (auth && auth.user && auth.user.id) {
      posthog.capture("OneDrive Integration Clicked");

      createUserAuditLog({
        actionId: "one-drive-integration-clicked",
        actionType: "MISC",
        details: {},
      });

      const clientId = "55864141-cda0-498a-897a-57677e16d372";
      const scope = "files.readwrite offline_access User.Read";
      const redirectUri = `${
        import.meta.env.MODE === "development"
          ? "http://localhost:8081"
          : import.meta.env.VITE_APP_URL
      }/settings/profile/one-drive`;
      const url =
        "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=" +
        clientId +
        "&scope=" +
        scope +
        "&response_type=code&redirect_uri=" +
        redirectUri;
      window.location.href = url;
    }
  }

  const handleSignOut = async () => {
    if (auth && auth.user && auth.user.id) {
      const userId: string = auth.user.id;
      auth.apolloClient
        .mutate({
          mutation: HandleApiSignOut,
          variables: {
            tokenInput: {
              userId,
              type: "one-drive",
            },
          },
        })
        .then((res: any) => {
          if (res && res.data && res.data.handleApiSignOut) {
            addToast(t("OneDrive Disabled."), {
              type: "success",
            });
            auth.fetch().then(() => {
              loadIntegrationDetails();
            });
          } else {
            addToast(t("Something went wrong. Please try again later."), {
              type: "error",
            });
          }
        });
    }
  };

  return (
    <div className="border-slate6 mt-3 w-full rounded-xl border-[1px] p-5">
      <div className="flex-col text-left">
        <div className="flex items-center">
          <img loading="lazy" src={OneDrive} className="w-6 object-contain" />
          <div className="text-slate11 flex-col justify-center pl-3 text-[15px] font-medium">
            {t("Microsoft OneDrive")}
          </div>
        </div>
        <div
          className={clsx(
            isMobile ? "flex-col" : "flex",
            "w-full items-center justify-between",
            details && "pt-4"
          )}
        >
          {details ? (
            <div>
              {details.name ? (
                <div className="text-slate12 text-[14px] font-semibold">
                  {details.name}
                </div>
              ) : null}{" "}
              {details.email ? (
                <div className="text-slate11 text-[14px] font-medium">
                  {details.email}
                </div>
              ) : null}
            </div>
          ) : null}
          {details ? (
            <Button
              theme="secondary"
              type="button"
              buttonText={t("Sign out")}
              padding="large"
              rounded="medium"
              width="225"
              onClick={handleSignOut}
              trackingName="OneDrive Integration Removed"
            />
          ) : (
            <button
              onClick={() => handleSignIn()}
              className={clsx(
                "mt-5 inline-flex w-[225px] justify-center rounded-lg border py-2 px-4 pl-6 text-sm shadow-sm"
              )}
            >
              <img
                loading="lazy"
                className="my-1 h-4"
                src={"/imgs/logos/microsoftLogo.png"}
                alt="Microsoft Logo"
              />
              <span className="ml-[12px] w-40 pt-[1px] font-[15px]">
                {t("Sign in with Microsoft")}
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(OneDriveIntegration);
