import { ApolloClient } from "@apollo/client";
import { action, autorun, computed, observable } from "mobx";
import Drive from "../models/Drive";
import LocalStorage from "../utils/LocalStorage";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

type LocalLocalStorageData = {
  sortBy?: string;
  sortDirection?: string;
  view?: string;
};

const STORE_NAME = "DRIVES";

export default class DrivesStore extends BaseStore<Drive> {
  @observable
  isLoading = false;

  @observable
  sortBy = "updatedAt";

  @observable
  sortDirection = "desc";

  @observable
  view = "grid";

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Drive, apolloClient);
    this.rootStore = rootStore;
    this.apolloClient = apolloClient;

    const data: LocalLocalStorageData = LocalStorage.retrieve(STORE_NAME) || {};

    this.rehydrateStore(data);

    autorun(() => {
      LocalStorage.save(STORE_NAME, this.toLocalLocalStorageData);
    });
  }

  @action
  rehydrateStore(data: LocalLocalStorageData) {
    this.sortBy = data.sortBy || "updatedAt";
    this.sortDirection = data.sortDirection || "desc";
    this.view = data.view || "grid";
  }

  @computed
  get toLocalLocalStorageData() {
    return {
      sortBy: this.sortBy,
      sortDirection: this.sortDirection,
      view: this.view,
    };
  }

  @action
  setSortBy = (sortBy: string) => {
    this.sortBy = sortBy;
  };

  @action
  setSortDirection = (sortDirection: string) => {
    this.sortDirection = sortDirection;
  };

  @action
  setView = (view: string) => {
    this.view = view;
  };
}
