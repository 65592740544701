import { gql } from "../../__generated__/gql";

export const GetAuthUser = gql(/* Graphql */ `
    query GetAuthUser($where: UserWhereUniqueInput!) {
        user(where: $where) {
            id
            email
            firstName
            lastName
            username
            districtRoles
            avatarUrl
            userRole
            isOnboarded
            createdAt
            onboardedAt
            authProvider
            updatedAt
            verifiedAt
            integrations
            districtRoles
            language
            freeTrialStart
            freeTrialEnd
            drives {
                id
                createdAt
                district {
                    id
                }
                driveType
                user {
                    id
                }
                updatedAt
            }
            district {
                id
                urlId
                name
                createdBy {
                    id
                }
                payments
                users {
                    id
                    email
                    firstName
                    lastName
                    avatarUrl
                    createdAt
                    updatedAt
                    districtRoles
                }
                drives {
                    id
                    createdAt
                    district {
                        id
                    }
                    driveType
                    user {
                        id
                    }
                    updatedAt
                }
                workspaceType
            }
            botConversationParticipants {
                createdAt
                deletedAt
                id
                updatedAt
                owner 
                permissions
                user {
                  id
                  firstName
                  lastName
                  avatarUrl
                }
                botConversation {
                    createdAt
                    deletedAt
                    id
                    title
                    updatedAt
                    urlId
                }
            }
            aiToolParticipants {
                aiTool {
                    id
                    title
                    toolId
                    urlId
                    createdAt
                    updatedAt
                }
                createdAt
                deletedAt
                id
                updatedAt
                owner 
                permissions
                user {
                    id
                    firstName
                    lastName
                    avatarUrl
                    email
                }
            }
            invites {
                inviteType
                status
            }
            toolCount
            chatCount
            instantResourcesCount
            imageGeneratorCount
            enterpriseUser
            invitedCollaborators
            teamMemberCount
            subscriptionType
            subscriptionInterval
            enterprisePlan {
                isEnterpriseAccount
                isLicenseActive
                subscriptionType
            }
            referralActionsPending {
                useChat
                finishOnboarding
                createSlideshow
            }
            copilotFreeTrialEnd
        }
    }
`);

export const CheckUpdatePasswordTokenValidity = gql(/* Graphql */ `
    query CheckUpdatePasswordTokenValidity($token: String!) {
    checkUpdatePasswordPageValidity(token: $token)
  }
`);

export const getStripeCustomerManaementUrl = gql(/* Graphql */ `
    query getStripeCustomerManagementURL($customerId: String!) {
        getStripeCustomerManagementURL(customerId: $customerId)
    }
`);

export const CheckEnterpriseOboardingValidity = gql(`
    query CheckEnterpriseOboardingValidity($userId: String!) {
        checkEnterpriseOboardingValidity(userId: $userId) 
    }
`);

export const VerifyEmailHash = gql(/* Graphql */ `
    query verifyEmailHash($emailHashInput: EmailHashInput!) {
        verifyEmailHash(emailHashInput: $emailHashInput) {
            match
            isOnboarded
        }
    }
`);

// get invites query
export const GetInvites = gql(`
    query GetInvites($where: InviteWhereInput) {
        invites(where: $where) {
            id
            email
            createdAt
            inviteType
            status
            invitedBy {
                id
                firstName
                lastName
                email
                district {
                    id
                    name
                    createdBy {
                        id
                    }
                }
            }
        }
    }
`);

export const GetInvitesForEmail = gql(`
    query GetInvitesForEmail($where: InviteWhereInput) {
        invitesForEmail(where: $where) {
            id
            email
            createdAt
            inviteType
            status
            invitedBy {
                id
                firstName
                lastName
                email
                district {
                    id
                    name
                    createdBy {
                        id
                    }
                }
            }
        }
    }
`);

export const GetUserByEmail = gql(/* Graphql */ `
    query GetUserByEmail($userEmailInput: UserEmailInput!) {
        getUserByEmail(userEmailInput: $userEmailInput) {
            id
            email
            firstName
            lastName
            username
            avatarUrl
            userRole
            isOnboarded
            createdAt
            authProvider
            updatedAt
            integrations
            districtRoles
            district {
                id
                urlId
                name
                createdBy {
                    id
                }
                payments
            }
        }
    }
`);

export const GetGoogleDrive = gql(`
  query GetGoogleDrive($googleDriveInput: GoogleDriveInput!) {
    getGoogleDrive(googleDriveInput: $googleDriveInput) {
      id
      name
      mimeType
      webViewLink
      webContentLink
      iconLink
      thumbnailLink
      modifiedTime
    }
  }
`);

export const ExportGoogleDriveItem = gql(`
  query ExportGoogleDriveToPdf($googleDriveExportInput: GoogleDriveExportInput!) {
    exportGoogleDriveToPdf(googleDriveExportInput: $googleDriveExportInput) {
        id
        key
        acl
        contentType
        size
        content
        assetType
        user {
            id
        }
        course {
            id
        }
        resource {
            id
        }
        createdAt
        updatedAt
        deletedAt
        expiresAt
        vectorEmbeddings
    }
  }
`);

export const ExportOneDriveItem = gql(`
  query ExportOneDriveToPdf($oneDriveExportInput: OneDriveExportInput!) {
    exportOneDriveToPdf(oneDriveExportInput: $oneDriveExportInput) {
        id
        key
        acl
        contentType
        size
        content
        assetType
        user {
            id
        }
        course {
            id
        }
        resource {
            id
        }
        createdAt
        updatedAt
        deletedAt
        expiresAt
        vectorEmbeddings
    }
  }
`);

export const GetOneDrive = gql(`
  query GetOneDrive($oneDriveInput: OneDriveInput!) {
    getOneDrive(oneDriveInput: $oneDriveInput)
  }
`);

// Referral program
export const GetReferralLink = gql(`
    query GetRefferalLink(
        $product: String!
    ) {
        getReferralLink(product: $product)
    }
`);

export const GetReferralInfo = gql(`
    query GetReferralInfo(
        $referralInfoArgs: ReferralInfoArgs!
    ) {
        getReferralInfo(referralInfoArgs: $referralInfoArgs) {
            referrerName
            product
            freeTrialDays
            benefitsInDollars
        }
    }
`);
