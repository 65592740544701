import { gql } from "../../__generated__/gql";

export const NewDemoConversation = gql(`
  mutation DemoBotConversation($data: DemoConversationInput!) {
    newDemoConversation(data: $data) {
      conversationEntries
      createdAt
      data
      id
      title
      updatedAt
      urlId
    }
  }
`);

export const NewDemoConversationEntry = gql(`
  mutation NewDemoConversationEntry($data: DemoConversationInput!) {
    newDemoConversationEntry(data: $data) {
      conversationId
      createdAt
      deletedAt
      entry
      id
      speaker
      updatedAt
      userId
    }
  }
`);

export const InitDemoAiTool = gql(`
  mutation InitDemoAiTool($data: InitDemoToolInput!) {
    initDemoAiTool(data: $data) {
      createdAt
      id
      data
      initData
      title 
      toolId
      updatedAt
      urlId
      version
    }
  }
`);

export const DemoAiToolFieldAutofill = gql(`
  mutation DemoAiToolFieldAutofill($where: DemoAiAutofillInput!) {
    demoAiToolFieldAutofill(where: $where) 
  }
`);

export const DemoGenerateStepOutput = gql(`
  mutation DemoGenerateStepOutput($where: DemoGenerateStepOutputInput!) {
    demoGenerateStepOutput(where: $where) 
  }
`);

export const DemoCompleteToolStep = gql(`
  mutation DemoCompleteToolStep($data: DemoCompleteToolStepInput!) {
    demoCompleteToolStep(data: $data)  {
      createdAt
      id
      data
      initData
      title
      toolId
      updatedAt
      urlId
      version
    }
  }
`);

// export const AiToolProcessLink = gql(`
//   mutation AiToolProcessLink($args: ProcessLinkInput!) {
//     aiToolProcessLink(args: $args)
//   }
// `);

export const DemoAiToolFinalOutput = gql(`
  mutation DemoAiToolFinalOutput($where: DemoFinalStepOutputInput!) {
    demoAiToolFinalOutput(where: $where) 
  }
`);

export const DemoAiChatInterfaceInput = gql(`
  mutation DemoAiChatInterfaceInput($where: DemoAiChatInterfaceInput!) {
    demoAiChatInterfaceInput(where: $where) 
  }
`);

export const DemoAiChatInterfaceSuggestionResponse = gql(`
  mutation DemoAiChatInterfaceSuggestionResponse($where: DemoAiChatInterfaceSuggestionResponseInput!) {
    demoAiChatInterfaceSuggestionResponse(where: $where) 
  }
`);
