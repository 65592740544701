import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useModalContext } from "../contexts/modals";
import Pricing from "../components/Pricing";
import { homeRoute } from "../utils/routeHelper";
import ButtonExtraLarge from "../components/ButtonExtraLarge";
import { Footer } from "../components/LandingPage/Footer";
import { colorsMap300 } from "../colors-list";
import clsx from "clsx";
import useIsMobile from "../hooks/useIsMobile";
import {
  AnnouncementIcon,
  Chatnotification1Icon,
  VideocallIcon,
} from "../icon-imports";

export function createArticleCard(
  title: string,
  slug: string,
  author: string,
  readTime: string,
  imageUrl: string,
  imageAlt: string,
  excerpt: string
) {
  return (
    <Link
      to={slug}
      className="group bg-white hover:shadow-md hover:shadow-blue-300 text-gray-800 rounded-lg overflow-hidden shadow-lg border-solid border-[8px] border-white"
    >
      <div className="relative">
        <img
          className="w-full max-h-[180px] object-cover rounded-md shadow-inner"
          src={imageUrl}
          alt={imageAlt}
        />
        <div className="absolute inset-0 bg-black group-hover:opacity-0 opacity-10 rounded-md"></div>
      </div>
      <div className="p-6">
        {/* <div className="text-sm mb-2">{author}</div> */}
        <h3 className="text-2xl font-bold mb-3">{title}</h3>
        <div className="text-sm text-gray-600 mb-4">{readTime}</div>
        <p className="text-md mb-4">{excerpt}</p>
        <a href="#" className="text-blue-600 text-md font-semibold">
          Read more →
        </a>
      </div>
    </Link>
  );
}

const PaymentCancelPage: React.FC = () => {
  const { addModal } = useModalContext();

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  return (
    <React.Fragment>
      <div className="flex flex-col items-center p-12">
        <div
          onClick={() => {
            window.location.href =
              import.meta.env.VITE_APP_URL || "https://www.alayna.us";
          }}
          className="mx-auto flex w-auto cursor-pointer flex-row items-center justify-center"
        >
          <img
            loading="lazy"
            className="mr-3 h-8"
            src={"/imgs/logos/logo.png"}
            alt="Alayna logo"
          />
        </div>

        <h1 className="text-3xl text-slate12 font-semibold text-center mt-8">
          We would love if you reconsider joining our growing community of pro
          users...
        </h1>
        <h2 className="text-lg text-slate11 font-medium mt-8">
          Everything we do at Alayna is to help you become a better educator &
          make life easier for you. We are here to support you in every way
          possible.
        </h2>

        {/* Or horizontal divider */}
        <div className="flex items-center w-full max-w-4xl my-16">
          <div className="border-t border-gray-300 w-full"></div>
          <div className="mx-4 text-gray-400 min-w-[90px]">Learn more</div>
          <div className="border-t border-gray-300 w-full"></div>
        </div>

        <h2 className="text-xl text-slate11 font-semibold mb-8">
          Watch - Why Alayna is the #1 AI Platform for Educators
        </h2>
        <div>
          {/* Replace with your video embed code */}
          <iframe
            width="800"
            height="400"
            src="https://www.youtube.com/embed/HzJReNYzNuI"
            title="The #1 ChatGPT Alternative for Teachers | Alayna AI (alayna.us)"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen={true}
          ></iframe>
        </div>

        <div className="mt-12">
          <div className="flex flex-col items-center w-full">
            <h2 className="text-xl text-slate11 font-semibold mt-8">
              Read - How Alayna can transform your teaching
            </h2>
            <div className="max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
              {createArticleCard(
                " Make your Resource Prep 50x quicker using Gen AI",
                "/blog/make-resource-prep-50x-quicker-gen-ai",
                "BY Annie Sexton",
                "10 MIN READ",
                "/imgs/blogs/teacher-enjoying-beach-2.webp",
                "Illustration showing how to save hours of prep work with AI",
                "Unlock the potential of AI-powered teaching tools to streamline your lesson preparation process, effortlessly create or find teaching resources tailored to your lessons and save hours of work. Moreover, leverage AI for differentiation and scaffolding to cater to diverse student needs without adding extra effort."
              )}
              {createArticleCard(
                "Transform Lesson Planning with AI: A Comprehensive Guide for Educators",
                "/blog/how-ai-transforms-lesson-planning-for-teachers",
                "BY Emily Chang",
                "15 MIN READ",
                "/imgs/blogs/lesson-planning-cover.webp",
                "Illustration of AI Lesson Planning",
                "Learn how to use AI to create personalized lesson plans + Leave with a ready-to-use lesson plan 🚀"
              )}
              {createArticleCard(
                "The #1 Alternative to ChatGPT for Educators",
                "/blog/top-chatgpt-alternative-for-educators",
                "BY Chris Fidao",
                "12 MIN READ",
                "/imgs/blogs/top-alternative-gpt.webp",
                "Illustration of an AI teaching assistant",
                "Learn why Alayna is the preferred AI teaching assistant for educators offering a more advanced, private, and reliable alternative to ChatGPT."
              )}
            </div>
          </div>
        </div>

        <div className="mt-6 md:mt-12">
          <div className="flex flex-col items-center w-full">
            <h2 className="text-xl text-slate11 font-semibold mt-8">
              Additional Perks for Alayna Pro Users
            </h2>
            <div className="max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
              {/* Render 3 cards */}
              {/* Card 1: Dedicated Support */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-yellow-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["yellow"],
                      opacity: 0.3,
                    }}
                  />
                  {React.cloneElement(<Chatnotification1Icon />, {
                    className: clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-yellow-500`
                    ),
                  })}
                </div>
                <h3 className="mt-4 text-xl font-semibold text-slate12">
                  Priority Support
                </h3>
                <p className="text-slate11 mt-4">
                  Get your queries resolved faster with our priority support
                  team.
                </p>
              </div>
              {/* Card 2: Early Access to upcoming Features  */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-blue-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["blue"],
                      opacity: 0.3,
                    }}
                  />
                  {React.cloneElement(<AnnouncementIcon />, {
                    className: clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-blue-500`
                    ),
                  })}
                </div>
                <h3 className="text-xl mt-4 font-semibold text-slate12">
                  Early Access to New Features
                </h3>
                <p className="text-slate11 mt-4">
                  Be the first to try out our new features before anyone else.
                </p>
              </div>
              {/* Card 3: Exclusive Webinars */}
              <div className="bg-white shadow-lg rounded-lg p-6 border border-slate8">
                <div className="p-2 relative h-12 w-12 flex justify-center items-center">
                  <div
                    className={`absolute inset-0 h-full w-full rounded-lg text-pink-500 shadow-lg`}
                    style={{
                      backgroundColor: colorsMap300["pink"],
                      opacity: 0.3,
                    }}
                  />
                  {React.cloneElement(<VideocallIcon />, {
                    className: clsx(
                      isMobile ? "h-4 w-4" : "h-8 w-8",
                      `text-pink-500`
                    ),
                  })}
                </div>
                <h3 className="text-xl mt-4 font-semibold text-slate12">
                  Exclusive Webinars
                </h3>
                <p className="text-slate11 mt-4">
                  Attend our exclusive webinars with industry experts and
                  educators.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-center w-full mt-24 mb-24">
          <h2 className="text-3xl text-slate12 font-semibold mt-8">
            Are you ready to take the next step?
          </h2>
          <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 justify-center md:space-x-8 mt-8">
            <ButtonExtraLarge
              theme="primary"
              type="button"
              buttonText={"Yes, take me to Pricing"}
              onClick={() => {
                addModal({
                  content: (
                    <div className="relative overflow-y-scroll h-full px-8">
                      <Pricing />
                    </div>
                  ),
                  size: "lg",
                });
              }}
              rounded={"medium"}
            />
            <ButtonExtraLarge
              theme="secondary"
              type="button"
              buttonText={"No, Go to Home Page"}
              onClick={() => {
                navigate(homeRoute());
              }}
              rounded={"medium"}
            />
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default PaymentCancelPage;
