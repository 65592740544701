import { action, computed, observable } from "mobx";
import Attribute from "./decorators/Attribute";
import DemoAiToolStep from "./DemoAiToolStep";
import PersistModel from "./PersistModel";

class DemoAiTool extends PersistModel {
  @observable
  isUpdating: boolean = false;

  @Attribute
  @observable
  id: string;

  @observable
  data?: any | undefined;

  @Attribute
  @observable
  initData: any;

  @Attribute
  @observable
  toolId: any;

  @Attribute
  @observable
  title: string;

  @Attribute
  @observable
  urlId: string;

  @Attribute
  @observable
  version?: number | null;

  // Add code to manage autofill from AI
  // userId-stepId-fieldId
  @observable
  autofillFieldStatusMap: {
    [key: string]: string;
  } = {};

  @observable
  autofillFieldTokenMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};

  @observable
  autofillFieldCompleteMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};

  @observable
  autofillFieldRunIDMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};

  @observable
  stepOutputStatusMap: {
    [key: string]: string;
  } = {};

  @observable
  stepInitStatusMap: {
    [key: string]: string;
  } = {};

  @observable
  stepOutputTokenMap: {
    [key: string]: {
      [count: number]: string;
    };
  } = {};

  @action
  setTitle = (title: string) => {
    this.title = title;
  };

  @action
  setAutofillFieldStatusMap = async (
    stepId: string,
    fieldId: string,
    userId: string,
    status: string,
    runId?: string
  ) => {
    const updateFieldStatusMap = {
      ...this.autofillFieldStatusMap,
    };

    const updateFieldTokenMap = {
      ...this.autofillFieldTokenMap,
    };

    const updateFieldCompleteMap = {
      ...this.autofillFieldCompleteMap,
    };

    const updateFieldRunIDMap = {
      ...this.autofillFieldRunIDMap,
    };

    const key = `${stepId}-${fieldId}`;

    // We remove the status & field => move response to complete field
    if (status === "END") {
      delete updateFieldStatusMap[key];

      const copyStream = updateFieldTokenMap[key];

      delete updateFieldTokenMap[key];

      updateFieldCompleteMap[key] = copyStream;

      this.autofillFieldTokenMap = updateFieldTokenMap;
      this.autofillFieldCompleteMap = updateFieldCompleteMap;

      // Update the runID map
      if (runId) {
        updateFieldRunIDMap[key] = runId;
        this.autofillFieldRunIDMap = updateFieldRunIDMap;
      }
    } else {
      updateFieldStatusMap[key] = status;
    }

    this.autofillFieldStatusMap = updateFieldStatusMap;
  };

  @action
  setAutofillFieldTokenMap = async (
    stepId: string,
    fieldId: string,
    userId: string,
    tokenCount: number,
    token: string
  ) => {
    const updateAutofillTokenMap = {
      ...this.autofillFieldTokenMap,
    };

    const key = `${stepId}-${fieldId}`;

    // It may not exist
    const updateTokenMap = this.autofillFieldTokenMap[key]
      ? { ...this.autofillFieldTokenMap[key] }
      : {};

    // Now we set the token
    updateTokenMap[tokenCount] = token;

    // Now we update main autofill map with token map
    updateAutofillTokenMap[key] = updateTokenMap;

    this.autofillFieldTokenMap = updateAutofillTokenMap;
  };

  @action
  setStepOutputStatusMap = async (stepId: string, status: string) => {
    const updateStepOutputStatusMap = {
      ...this.stepOutputStatusMap,
    };

    const updateStepOutputTokenMap = {
      ...this.stepOutputTokenMap,
    };

    // We remove the status & field => move response to complete field
    if (status === "END") {
      delete updateStepOutputStatusMap[stepId];
      delete updateStepOutputTokenMap[stepId];

      this.stepOutputTokenMap = updateStepOutputTokenMap;
    } else {
      updateStepOutputStatusMap[stepId] = status;
    }

    this.stepOutputStatusMap = updateStepOutputStatusMap;
  };

  @action
  setStepInitStatusMap = async (stepId: string, status: string) => {
    const updateStepInitStatusMap = {
      ...this.stepInitStatusMap,
    };

    // We remove the status & field => move response to complete field
    if (status === "END") {
      delete updateStepInitStatusMap[stepId];
    } else {
      updateStepInitStatusMap[stepId] = status;
    }

    this.stepInitStatusMap = updateStepInitStatusMap;
  };

  @action
  setStepOutputTokenMap = async (
    stepId: string,
    tokenCount: number,
    token: string
  ) => {
    const updateStepOutputTokenMap = {
      ...this.stepOutputTokenMap,
    };

    // It may not exist
    const updateTokenMap = this.stepOutputTokenMap[stepId]
      ? { ...this.stepOutputTokenMap[stepId] }
      : {};

    // Now we set the token
    updateTokenMap[tokenCount] = token;
    // Now we update main autofill map with token map
    updateStepOutputTokenMap[stepId] = updateTokenMap;
    this.stepOutputTokenMap = updateStepOutputTokenMap;
  };

  @action
  clearAutofillFieldCompleteMap = () => {
    this.autofillFieldCompleteMap = {};
  };

  @action
  clearAutofillFieldRunIDMap = () => {
    this.autofillFieldRunIDMap = {};
  };

  @computed
  get toolSteps(): DemoAiToolStep[] {
    return this.store.rootStore.demoAiToolStepsStore.sortedData.filter(
      (s: DemoAiToolStep) => s.aiToolId === this.id
    );
  }

  @action
  getToolStepById = (stepId: string): DemoAiToolStep | undefined => {
    const { demoAiToolStepsStore } = this.store.rootStore;

    return demoAiToolStepsStore.sortedData.find(
      (toolStep: DemoAiToolStep) =>
        toolStep.aiToolId === this.id && toolStep.stepId === stepId
    );
  };
}

export default DemoAiTool;
