import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, computed, observable } from "mobx";
import {
  ConvertMarkdownToPDF,
  ResourceGeneratorEdit,
  UpdateAiTool,
} from "../graphql/aiTools/aiTools.mutation";
import {
  DemoCompleteToolStep,
  InitDemoAiTool,
} from "../graphql/demo/demo.mutations";
import { GetDemoAiTool } from "../graphql/demo/demo.queries";
import AiTool from "../models/AiTool";
import { EnumAiToolType } from "../models/AiToolParticipant";
import DemoAiTool from "../models/DemoAITool";
import {
  AiToolUpdateInput,
  AiToolWhereUniqueInput,
  DemoCompleteToolStepInput,
  EnumAiToolParticipantPermissions,
  InitDemoToolInput,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class DemoAiToolsStore extends BaseStore<DemoAiTool> {
  @observable
  isLoading = false;

  @observable
  generatingPDF = false;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, DemoAiTool, apolloClient);
    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
  }

  setAiTools = (aiTools: AiTool[]) => {
    aiTools.forEach((aiTools) => {
      this.add(aiTools);
    });
  };

  @action
  async fetchByUrlId(urlId: string): Promise<DemoAiTool> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.query({
        query: GetDemoAiTool,
        variables: {
          toolId: urlId,
        },
      });

      if (!res.data || !res.data.demoAiTool) {
        throw new Error("No data returned from demoAiTool mutation");
      }

      const a = res.data.demoAiTool;

      const sanitizedAiTool = {
        id: a.id,
        data: a.data,
        initData: a.initData,
        title: a.title,
        toolId: a.toolId,
        urlId: a.urlId,
        version: a.version,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
      };

      // Fetch steps for this tool
      if (a.toolId !== "ai-image-generator") {
        this.rootStore.demoAiToolStepsStore.fetchAiToolSteps(a.id);
      }

      return this.add(sanitizedAiTool);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @action
  save(args: Partial<DemoAiTool>, attributes?: boolean): Promise<DemoAiTool> {
    const { newlyCreated, id, ...rest } = args;

    if (!id || newlyCreated) {
      return this.initTool(rest as InitDemoToolInput);
    } else if (attributes) {
      return this.updateTool(
        { id } as AiToolWhereUniqueInput,
        rest as AiToolUpdateInput
      );
    } else {
      return this.updateTool(
        { id } as AiToolWhereUniqueInput,
        rest as AiToolUpdateInput
      );
    }
  }

  @action
  async initTool(data: InitDemoToolInput): Promise<DemoAiTool> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: InitDemoAiTool,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.initDemoAiTool) {
        throw new Error("No data returned from initDemoAiTool mutation");
      }

      const a = res.data.initDemoAiTool;

      const sanitizedAiTool = {
        id: a.id,
        data: a.data,
        initData: a.initData,
        toolId: a.toolId,
        title: a.title,
        urlId: a.urlId,
        version: a.version,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
      };

      return this.add(sanitizedAiTool);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @action
  async updateTool(
    where: AiToolWhereUniqueInput,
    data: AiToolUpdateInput
  ): Promise<DemoAiTool> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: UpdateAiTool,
        variables: {
          where,
          data,
        },
      });

      if (!res.data || !res.data.updateAiTool) {
        throw new Error("No data returned from updateAiTool mutation");
      }

      const a = res.data.updateAiTool;

      if (a.aiToolParticipants) {
        a.aiToolParticipants.forEach((p) => {
          invariant(p.aiTool, "aiToolParticipant must have aiTool");
          invariant(p.user, "aiToolParticipant must have user");
          invariant(p.permissions, "aiToolParticipant must have permissions");

          const aiToolType =
            p.aiTool.toolId === "ai-differentiated-resource-generator"
              ? EnumAiToolType.INSTANT_RESOURCES
              : p.aiTool.toolId === "ai-image-generator"
              ? EnumAiToolType.IMAGE_GENERATOR
              : EnumAiToolType.CURRICULUM_CREATOR;

          const sanitizeParticipant = {
            id: p.id,
            aiToolId: p.aiTool.id,
            createdAt: p.createdAt,
            owner: p.owner,
            permissions: p.permissions as EnumAiToolParticipantPermissions[],
            updatedAt: p.updatedAt,
            userId: p.user.id,
            firstName: p.user.firstName,
            lastName: p.user.lastName,
            email: p.user.email,
            avatarUrl: p.user.avatarUrl,
            aiToolType,
          };

          this.rootStore.aiToolParticipantsStore.add(sanitizeParticipant);
        });
      }

      if (a.aiToolSteps) {
        a.aiToolSteps.forEach((s) => {
          invariant(s.aiTool, "aiToolStep must have aiTool");

          const sanitizeAiToolStep = {
            id: s.id,
            aiToolId: s.aiTool.id,
            createdAt: s.createdAt,
            data: s.data,
            stepId: s.stepId,
            updatedAt: s.updatedAt,
          };

          this.rootStore.aiToolStepsStore.add(sanitizeAiToolStep);
        });
      }

      const sanitizedAiTool = {
        id: a.id,
        data: a.data,
        initData: a.initData,
        toolId: a.toolId,
        title: a.title,
        urlId: a.urlId,
        version: a.version,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
      };

      return this.add(sanitizedAiTool);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @action
  async completeToolStep(data: DemoCompleteToolStepInput): Promise<DemoAiTool> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: DemoCompleteToolStep,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.demoCompleteToolStep) {
        throw new Error("No data returned from demoCompleteToolStep mutation");
      }

      const a = res.data.demoCompleteToolStep;

      this.rootStore.aiToolStepsStore.fetchAiToolSteps(a.id);

      const sanitizedAiTool = {
        id: a.id,
        data: a.data,
        initData: a.initData,
        toolId: a.toolId,
        title: a.title,
        urlId: a.urlId,
        version: a.version,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
      };

      return this.add(sanitizedAiTool);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @action
  async convertMarkdownToPDF(
    markdown: string,
    fileName: string
  ): Promise<string> {
    this.generatingPDF = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: ConvertMarkdownToPDF,
        variables: {
          markdown,
          fileName,
        },
      });

      if (!res.data || !res.data.convertMarkdownToPDF) {
        throw new Error("No data returned from convertMarkdownToPDF mutation");
      }

      this.generatingPDF = false;

      return res.data.convertMarkdownToPDF;
    } catch (e) {
      console.log("Error", e);
      this.generatingPDF = false;
      throw e;
    }
  }

  @action
  async resourceGeneratorEdit(
    id: string,
    fieldId: string,
    data: any
  ): Promise<boolean> {
    try {
      const res = await this.apolloClient.mutate({
        mutation: ResourceGeneratorEdit,
        variables: {
          where: {
            id,
            fieldId,
            data,
          },
        },
      });

      if (!res.data || !res.data.resourceGeneratorEdit) {
        throw new Error("No data returned from resourceGeneratorEdit mutation");
      }

      return res.data.resourceGeneratorEdit;
    } catch (e) {
      console.log("Error", e);
      throw e;
    }
  }

  @computed
  get sortedData(): DemoAiTool[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }

  @computed
  get curriculumToolUses(): DemoAiTool[] {
    return orderBy(
      Array.from(this.data.values()).filter(
        (tool) =>
          tool.toolId !== "ai-differentiated-resource-generator" &&
          tool.toolId !== "ai-image-generator"
      ),
      "updatedAt",
      "desc"
    );
  }

  @computed
  get resourceGeneratorUses(): DemoAiTool[] {
    return orderBy(
      Array.from(this.data.values()).filter(
        (tool) => tool.toolId === "ai-differentiated-resource-generator"
      ),
      "updatedAt",
      "desc"
    );
  }

  @computed
  get imageGeneratorUses(): DemoAiTool[] {
    return orderBy(
      Array.from(this.data.values()).filter(
        (tool) => tool.toolId === "ai-image-generator"
      ),
      "updatedAt",
      "desc"
    );
  }

  getByUrlParam = (urlId: string): DemoAiTool | undefined => {
    return this.sortedData.find((tool) => urlId.endsWith(tool.urlId));
  };
}
