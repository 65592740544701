//

import { Editor } from "@tiptap/react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import useStores from "../../hooks/useStores";
import Resource from "../../models/Resource";
import { EnumResourceResourceType } from "../../__generated__/graphql";
import { EditorContext, EditorProviderProps } from "./EditorContext";

export const EditorProvider: React.FC<EditorProviderProps> = observer(
  ({ children }) => {
    const [lessonPlanSchema] = useState<any | null>(null);

    const [toolbarTab, setToolbarTab] = useState<string | undefined>(undefined);

    const [addBlockPopoverOpen, setAddBlockPopoverOpen] = useState(false);

    const [activeSection, setActiveSection] = useState<string | null>(null);

    const [editor, setEditor] = useState<Editor | null>(null);

    const [showInsertLinkPopover, setShowInsertLinkPopover] = useState(false);

    const [isUploading, setIsUploading] = useState(false);

    const [resourceId, setResourceId] = useState<string | null>(null);

    const [resourceType, setResourceType] =
      useState<EnumResourceResourceType | null>(null);

    const { resources, auth } = useStores();

    const [activeResource, setActiveResource] = useState<Resource | null>(null);

    console.log("Editor in context", editor);

    // Set the active resource
    useEffect(() => {
      //
      if (!resourceId) {
        setActiveResource(null);
      }

      if (resourceId && resources.sortedData) {
        const resource = resources.sortedData.find(
          (resource) => resource.id === resourceId
        );

        if (resource) {
          setActiveResource(resource);
        }
      }

      return () => {
        setActiveResource(null);
      };
    }, [resources.sortedData, resourceId]);

    // resourceId : Status
    const [aiAssistantStatus, setAiAssistantStatus] = useState<{
      [key: string]: string;
    }>({});

    // resourceId : Response
    const [aiAssistantResponse, setAiAssistantResponse] = useState<{
      [key: string]: string;
    }>({});

    const [aiAssistantComplete, setAiAssistantComplete] = useState<{
      [key: string]: string;
    }>({});

    const [editorInitializeValue, setEditorInitializeValue] = useState<
      string | null
    >(null);

    useEffect(() => {
      console.log("Editor Initialize Value", editorInitializeValue);
      console.log("Editor", editor);

      if (!editorInitializeValue) {
        return;
      }

      if (editor && editorInitializeValue) {
        setTimeout(() => {
          console.log("Setting Editor Content");
          editor.commands.setContent(editorInitializeValue);
          setEditorInitializeValue(null);
          console.log("Editor Initialize Value Reset!");
        }, 2000);
      }
    }, [editor, editorInitializeValue]);

    useEffect(() => {
      if (!activeResource) {
        return;
      }

      let updateAutofillFieldsStatus: any = {};

      // Update the aiAutofillFieldsStatus
      Object.keys(activeResource.aiAssistantStatusMap).forEach((key) => {
        updateAutofillFieldsStatus[key] =
          activeResource.aiAssistantStatusMap[key];
      });

      setAiAssistantStatus(updateAutofillFieldsStatus);
    }, [activeResource, activeResource?.aiAssistantStatusMap]);

    useEffect(() => {
      if (!activeResource) {
        return;
      }

      let updateAiAssistantTokenMap: any = {};

      // Update the aiAutofillFieldTokenMap
      Object.keys(activeResource.aiAssistantTokenMap).forEach((key) => {
        const tokenMap = activeResource.aiAssistantTokenMap[key];

        // Reduce the token map into a resposne
        const response = Object.entries(toJS(tokenMap))
          .sort((a, b) => parseInt(a[0]) - parseInt(b[0])) // Sort based on tokenCount
          .map((entry) => entry[1]) // Get the tokens
          .join(""); // Join the tokens to construct the string

        updateAiAssistantTokenMap[key] = response;
      });

      setAiAssistantResponse(updateAiAssistantTokenMap);

      // Update the aiAssistantTokenMap
    }, [activeResource, activeResource?.aiAssistantTokenMap]);

    useEffect(() => {
      if (!activeResource) {
        return;
      }

      let updateAiAssistantCompleteMap: any = {};

      if (Object.keys(activeResource.aiAssistantCompleteMap).length === 0)
        return;

      // Update the aiAssistantCompleteMap
      Object.keys(activeResource.aiAssistantCompleteMap).forEach((key) => {
        const tokenMap = activeResource.aiAssistantCompleteMap[key];

        // Reduce the token map into a resposne
        const response = Object.entries(toJS(tokenMap))
          .sort((a, b) => parseInt(a[0]) - parseInt(b[0])) // Sort based on tokenCount
          .map((entry) => entry[1]) // Get the tokens
          .join(""); // Join the tokens to construct the string

        // Ensure current resource is the same as the key
        if (key === resourceId) {
          updateAiAssistantCompleteMap[key] = response;
        }
      });

      setAiAssistantComplete(updateAiAssistantCompleteMap);

      activeResource.clearAiAssistantCompleteMap();
    }, [activeResource, activeResource?.aiAssistantCompleteMap, auth]);

    const editorContext = {
      toolbarTab,
      setToolbarTab,
      resourceId,
      setResourceId,
      resourceType,
      setResourceType,
      lessonPlanSchema,
      addBlockPopoverOpen,
      setAddBlockPopoverOpen,
      activeSection,
      setActiveSection,
      editor,
      setEditor,
      isUploading,
      setIsUploading,
      showInsertLinkPopover,
      setShowInsertLinkPopover,
      editorInitializeValue,
      setEditorInitializeValue,
      aiAssistantStatus,
      aiAssistantResponse,
      aiAssistantComplete,
      setAiAssistantComplete,
      activeResource,
    };

    return (
      <EditorContext.Provider value={editorContext}>
        {children}
      </EditorContext.Provider>
    );
  }
);
