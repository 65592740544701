import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { action, observable, runInAction } from "mobx";
import { GetUsers } from "../graphql/users/users.queries";
import DistrictUser from "../models/DistrictUser";
import {
  EnumSubscriptionInterval,
  EnumUserSubscriptionType,
  SortOrder,
  StripeCheckoutInput,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";
import {
  CreateStripeCheckoutSession,
  UpgradeSubscription,
} from "../graphql/user/user.mutations";

export default class UsersStore extends BaseStore<DistrictUser> {
  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, DistrictUser, apolloClient);
  }

  @observable
  isSaving: boolean = false;

  @action
  fetchDistrictUsers = (districtId: string) => {
    this.isLoading = true;

    return new Promise((resolve, reject) => {
      this.apolloClient
        .query({
          query: GetUsers,
          variables: {
            where: {
              district: {
                id: districtId,
              },
            },
            orderBy: {
              createdAt: SortOrder.Desc,
            },
          },
        })
        .then((res) => {
          const districtUsers = res.data.users;

          if (!districtUsers) {
            reject(false);
          }

          runInAction("Populate District Users", () => {
            districtUsers.forEach((user) => {
              invariant(user.district, "Subject must have a district");
              invariant(user.schools, "Subject must have schools");
              invariant(user.firstName, "User must have a first name");
              invariant(user.lastName, "User must have a last name");

              const sanitizeUser = {
                avatarUrl: user.avatarUrl,
                createdAt: user.createdAt,
                deletedAt: user.deletedAt,
                districtId: user.district.id,
                edlinkId: user.edlinkId,
                email: user.email,
                firstName: user.firstName,
                gradeLevels: user.gradeLevels,
                id: user.id,
                identifiers: user.identifiers,
                lastName: user.lastName,
                middleName: user.middleName,
                schools: user.schools.map((school) => school.id),
                updatedAt: user.updatedAt,
                username: user.username,
              };

              this.add(sanitizeUser);
            });
          });
        })
        .catch((e) => {
          reject(false);
        })
        .finally(() => {
          runInAction("Set loading to false", () => {
            this.isLoading = false;
          });
          resolve(true);
        });
    });
  };

  @action
  createStripeCheckoutSession = async (
    subscriptionType: EnumUserSubscriptionType,
    paymentInterval: EnumSubscriptionInterval
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: CreateStripeCheckoutSession,
          variables: {
            input: {
              subscriptionType,
              paymentInterval,
              numberOfSeats: 1,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.createStripeCheckoutSession) {
            resolve({
              data: res.data.createStripeCheckoutSession,
              error: null,
            });
          } else {
            resolve({
              data: null,
              error:
                "Failed to create checkout session. Please try again or contact our support team.",
            });
          }
        })
        .catch((e) => {
          resolve({
            data: null,
            error:
              "Failed to create checkout session. Please try again or contact our support team.",
          });
        });
    });
  };

  @action
  upgradeSubscription = async (
    subscriptionType: EnumUserSubscriptionType,
    paymentInterval: EnumSubscriptionInterval
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.apolloClient
        .mutate({
          mutation: UpgradeSubscription,
          variables: {
            input: {
              subscriptionType,
              paymentInterval,
              numberOfSeats: 1,
            },
          },
        })
        .then((res) => {
          if (res.data && res.data.upgradeSubscription) {
            resolve({
              data: res.data.upgradeSubscription,
              error: null,
            });
          } else {
            resolve({
              data: null,
              error:
                "Failed to upgrade subscription. Please try again or contact our support team.",
            });
          }
        })
        .catch((e) => {
          resolve({
            data: null,
            error:
              "Failed to upgrade subscription. Please try again or contact our support team.",
          });
        });
    });
  };

  getUsersForDistrict = (districtId: string): DistrictUser[] => {
    return this.sortedData.filter(
      (user) => user.districtId === districtId && !user.deletedAt
    );
  };
}
