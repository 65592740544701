import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useStores from "../hooks/useStores";
import { useToastsContext } from "../contexts/toasts";

const Referral: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth } = useStores();
  const { referralCode } = useParams();
  const [loading, setLoading] = useState(true);

  const { addToast } = useToastsContext();

  useEffect(() => {
    const fetchReferralData = async () => {
      const searchParams = new URLSearchParams(location.search);
      const productType = searchParams.get("product") || "Bundle";

      if (referralCode) {
        try {
          const {
            success,
            referrerName,
            product,
            freeTrialDays,
            benefitsInDollars,
          } = await auth.getReferralInfo(
            referralCode, // required
            productType // optional
          );

          console.log("Success", success);
          console.log("referralCode", referralCode);
          console.log("referralProduct", product);
          console.log("referrerName", referrerName);
          console.log("referralDays", freeTrialDays);
          console.log("referralDollarAmount", benefitsInDollars);

          if (success) {
            localStorage.setItem("referralCode", referralCode);
            localStorage.setItem("referralProduct", product);
            localStorage.setItem("referrerName", referrerName);
            localStorage.setItem("referralDays", freeTrialDays);
            localStorage.setItem("referralDollarAmount", benefitsInDollars);

            navigate("/signup");
          } else {
            addToast("Referral code not found. Navigating to signup.", {
              type: "error",
            });
            navigate("/signup");
          }
        } catch (error) {
          console.error("Error fetching referral data:", error);
          setTimeout(() => {
            navigate("/signup");
          }, 3000);
        }
      } else {
        navigate("/signup");
      }
      setLoading(false);
    };

    fetchReferralData();
  }, [location, navigate, auth]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return null;
};

export default Referral;
