import clsx from "clsx";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToastsContext } from "../../contexts/toasts";
import useStores from "../../hooks/useStores";
import { useTranslation } from "react-i18next";
import ButtonLarge from "../ButtonLarge";

function EmailVerificationMessage() {
  const { addToast } = useToastsContext();
  const { auth } = useStores();
  const user = auth.user;
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user && user.verifiedAt) {
      navigate("/");
    }
  }, [user]);

  if (!user) return null;

  return (
    <div
      className={clsx(
        // styles.appBackground,
        "flex min-h-[100vh] flex-col items-center justify-center text-center space-y-10 sm:mx-auto sm:w-full sm:max-w-md"
      )}
      style={{ backgroundColor: "#fff" }}
    >
        <div className="text-2xl font-semibold text-black">
          {t('Verify your Email')}
        </div>
        <p>
          {t("We sent a verification email to your email address. Please check your inbox and click on the link to verify your email.")}
        </p>
        <ButtonLarge
          type="button"
          theme="primary"
          rounded="medium"
          onClick={() => {
            auth.resendVerifyEmail(user.id);
            addToast(
              t("Verification email has been sent again, please check your inbox."),
              {
                type: "success",
              }
            );
          }}
          buttonText={t('Resend Verification Email')}
        />
    </div>
  );
}
export default EmailVerificationMessage;
