import { ApolloClient } from "@apollo/client";
import invariant from "invariant";
import { orderBy } from "lodash";
import { action, computed, observable } from "mobx";
import {
  CreateAsset,
  DeleteAsset,
  LinkForEmbedding,
  UpdateAsset,
} from "../graphql/assets/assets.mutations";
import Asset from "../models/Asset";
import {
  AssetCreateInput,
  AssetUpdateInput,
  AssetWhereUniqueInput,
  CourseUpdateInput,
  CourseWhereUniqueInput,
} from "../__generated__/graphql";
import BaseStore from "./BaseStore";
import RootStore from "./RootStore";

export default class AssetsStore extends BaseStore<Asset> {
  @observable
  isLoading = false;

  @observable
  isLoadingCourseResources = false;

  @observable
  showGoogleSidebar: boolean;

  @observable
  showOneDriveSidebar: boolean;

  constructor(rootStore: RootStore, apolloClient: ApolloClient<any>) {
    super(rootStore, Asset, apolloClient);
    this.rootStore = rootStore;
    this.apolloClient = apolloClient;
    this.showGoogleSidebar = false;
    this.showOneDriveSidebar = false;
  }

  @action
  setShowGoogle(show: boolean) {
    this.showGoogleSidebar = show;
    this.showOneDriveSidebar = false;
  }

  @action
  setShowOneDrive(show: boolean) {
    this.showOneDriveSidebar = show;
    this.showGoogleSidebar = false;
  }

  // Function to save the course to the database
  @action
  save(args: any): Promise<Asset> {
    const { newlyCreated, id, ...rest } = args;

    if (!id || newlyCreated) {
      return this.create(rest as AssetCreateInput);
    } else {
      return this.update(
        rest as CourseUpdateInput,
        { id } as CourseWhereUniqueInput
      );
    }
  }

  @action
  async create(data: AssetCreateInput): Promise<Asset> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: CreateAsset,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.createAsset) {
        throw new Error("No data returned from createAsset mutation");
      }

      const a = res.data.createAsset;

      invariant(a.user, "Asset must have a user.");
      invariant(a.assetType, "Asset must have an assetType.");

      const sanitizedAsset = {
        id: a.id,
        key: a.key,
        acl: a.acl,
        size: a.size,
        contentType: a.contentType,
        assetType: a.assetType,
        content: a.content,
        userId: a.user.id,
        courseId: a.course ? a.course.id : null,
        resourceId: a.resource ? a.resource.id : null,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
        deletedAt: a.deletedAt,
        vectorEmbeddings: a.vectorEmbeddings,
      };

      return this.add(sanitizedAsset);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @action
  async processLinkWithEmbedding(data: AssetCreateInput): Promise<Asset> {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: LinkForEmbedding,
        variables: {
          data,
        },
      });

      if (!res.data || !res.data.linkForEmbedding) {
        throw new Error("No data returned from linkForEmbedding mutation");
      }

      const a = res.data.linkForEmbedding;

      invariant(a.user, "Asset must have a user.");
      invariant(a.assetType, "Asset must have an assetType.");

      const sanitizedAsset = {
        id: a.id,
        key: a.key,
        acl: a.acl,
        size: a.size,
        contentType: a.contentType,
        assetType: a.assetType,
        content: a.content,
        userId: a.user.id,
        courseId: a.course ? a.course.id : null,
        resourceId: a.resource ? a.resource.id : null,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
        deletedAt: a.deletedAt,
        vectorEmbeddings: a.vectorEmbeddings,
      };

      return this.add(sanitizedAsset);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @action
  async update(data: AssetUpdateInput, where: AssetWhereUniqueInput) {
    this.isSaving = true;

    try {
      const res = await this.apolloClient.mutate({
        mutation: UpdateAsset,
        variables: {
          data,
          where,
        },
      });

      if (!res.data || !res.data.updateAsset) {
        throw new Error("No data returned from updateAsset mutation");
      }

      const a = res.data.updateAsset;

      invariant(a.user, "Asset must have a user.");
      invariant(a.assetType, "Asset must have an assetType.");

      const sanitizedAsset = {
        id: a.id,
        key: a.key,
        acl: a.acl,
        size: a.size,
        contentType: a.contentType,
        assetType: a.assetType,
        content: a.content,
        userId: a.user.id,
        courseId: a.course ? a.course.id : null,
        resourceId: a.resource ? a.resource.id : null,
        createdAt: a.createdAt,
        updatedAt: a.updatedAt,
        deletedAt: a.deletedAt,
        vectorEmbeddings: a.vectorEmbeddings,
      };

      return this.add(sanitizedAsset);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  // Delete asset from database
  @action
  async deleteAsset(assetId: string) {
    this.isSaving = true;
    try {
      const deleted = await this.apolloClient.mutate({
        mutation: DeleteAsset,
        variables: {
          where: {
            id: assetId,
          },
        },
      });

      if (!deleted.data || !deleted.data.deleteAsset) {
        throw new Error("No data returned from deleteAsset mutation");
      }

      const a = deleted.data.deleteAsset;

      this.remove(a.id);
    } catch (e) {
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  @computed
  get sortedData(): Asset[] {
    return orderBy(Array.from(this.data.values()), "updatedAt", "desc");
  }
}
