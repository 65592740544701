import React, { useEffect, useMemo, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import { CheckCircleIcon, MinusIcon } from "@heroicons/react/20/solid";
import { observer } from "mobx-react";
import useStores from "../../hooks/useStores";
import ButtonLarge from "../ButtonLarge";
import posthog from "posthog-js";
import { useTranslation } from "react-i18next";
import { useModalContext } from "../../contexts/modals";
import { useToastsContext } from "../../contexts/toasts";
import { Spinner } from "../LoadingIndicators/Spinner";
import {
  EnumSubscriptionInterval,
  EnumUserSubscriptionType,
} from "../../__generated__/graphql";
import { toJS } from "mobx";

import * as Dialog from "@radix-ui/react-dialog";

type Tier = {
  name: string;
  color: string;
  id: string;
  href: string;
  priceMonthly: string;
  priceAnnual: string;
  discountedPriceMonthly?: string;
  discountedPriceAnnual?: string;
  discountPercentage?: string;
  description: string;
  mostPopular: boolean;
  buttonText: string;
};

type Feature = {
  name: string;
  tiers: { [key: string]: boolean | string };
  comingSoon?: boolean;
};

type Frequency = {
  value: string;
  label: string;
  priceSuffix: string;
};

type Section = {
  name: string;
  features: Feature[];
  comingSoon?: boolean;
};

function PricingComponent() {
  const { auth, users } = useStores();

  const { addModal } = useModalContext();

  const { addToast } = useToastsContext();

  const user = toJS(auth.user?.toGQLAttributes());

  console.log("User", user);

  const { t } = useTranslation();

  // Track posthog event if PricingComponent is viewed inside the app (not on landing page)
  useEffect(() => {
    posthog.capture("Viewed Pricing inside app");
  }, []);

  if (!user) {
    return null;
  }

  const frequencies: Frequency[] = [
    { value: "monthly", label: t("Monthly"), priceSuffix: t("/month") },
    {
      value: "annually",
      label: t("Annually"),
      priceSuffix: t("/month (billed annually)"),
    },
  ];

  const tiers: Tier[] = [
    {
      name: "Basic",
      description: t(
        "A free tier designed for individual teachers exploring the benefits of AI."
      ),
      id: "tier-free",
      color: "pink",
      href: "#",
      priceMonthly: "Free",
      priceAnnual: "Free",
      mostPopular: false,
      buttonText: t("Get started now"),
    },
    {
      name: "Pro",
      description: t(
        "Ideal for teachers seeking to maximize benefits & time-saved with Alayna."
      ),
      id: "tier-pro",
      color: "teal",
      href: "#",
      priceAnnual:
        `$${import.meta.env.VITE_APP_PRO_PLAN_ANNUAL_PRICE}` || "$12",
      priceMonthly:
        `$${import.meta.env.VITE_APP_PRO_PLAN_MONTHLY_PRICE}` || "$144",
      discountedPriceAnnual: import.meta.env
        .VITE_APP_PRO_PLAN_ANNUAL_PRICE_DISCOUNTED
        ? `$${import.meta.env.VITE_APP_PRO_PLAN_ANNUAL_PRICE_DISCOUNTED}`
        : undefined,
      discountedPriceMonthly: import.meta.env
        .VITE_APP_PRO_PLAN_MONTHLY_PRICE_DISCOUNTED
        ? `$${import.meta.env.VITE_APP_PRO_PLAN_MONTHLY_PRICE_DISCOUNTED}`
        : undefined,
      discountPercentage: import.meta.env.VITE_APP_ANNUAL_DISCOUNT_PERCENTAGE
        ? `Save ${import.meta.env.VITE_APP_ANNUAL_DISCOUNT_PERCENTAGE}%`
        : undefined,
      mostPopular: true,
      buttonText: t("Get started now"),
    },
    {
      name: "Enterprise",
      description: t(
        "Tailored for educational institutions, offering comprehensive AI solutions with volume discounts."
      ),
      id: "tier-enterprise",
      color: "yellow",
      href: "#",
      priceMonthly: "Custom",
      priceAnnual: "Custom",
      mostPopular: false,
      buttonText: t("Get a Quote"),
    },
  ];

  const sections: Section[] = [
    {
      name: t("Chat + Quick Tools"),
      features: [
        {
          name: t("Unlimited uses"),
          tiers: { Basic: true, Pro: true, Enterprise: true },
        },
        {
          name: t("Unlimited Chat length"),
          tiers: {
            Basic: "4 messages / chat",
            Pro: true,
            Enterprise: true,
          },
        },
        {
          name: t(
            "Unlimited File Attachments from Device, Google Drive & Microsoft OneDrive"
          ),
          // (PDF, Word, Google Docs, etc.)
          tiers: { Basic: t("5 uses / month"), Pro: true, Enterprise: true },
        },
        {
          name: t("File upload Size Limit"),
          tiers: { Basic: "10 MB.", Pro: "25 MB.", Enterprise: "50 MB." },
        },
        {
          name: t(
            "Web browsing mode (Highly accurate responses with sources & citations)"
          ),
          tiers: { Basic: t("5 uses / month"), Pro: true, Enterprise: true },
        },
        {
          name: t("Save to Alayna Drive"),
          tiers: { Basic: true, Pro: true, Enterprise: true },
        },
        {
          name: t("Export as PDF, Google Docs & Forms"),
          tiers: { Basic: t("Limited"), Pro: true, Enterprise: true },
        },
      ],
    },
    {
      name: t("Instant Resources"),
      features: [
        {
          name: t("Unlimited uses"),
          tiers: { Basic: true, Pro: true, Enterprise: true },
        },
        {
          name: t(
            "Resources created (Leveled Reading passage, MCQs, Vocabulary, etc."
          ),
          tiers: { Basic: "12", Pro: "23", Enterprise: "23" },
        },
        {
          name: t("Download as PDF"),
          tiers: { Basic: true, Pro: true, Enterprise: true },
        },
        {
          name: t("Differentiate by Reading Level/Language (Multiple tracks)"),
          tiers: { Basic: true, Pro: true, Enterprise: true },
        },
        {
          name: t("Edit/Rewrite Resources with AI"),
          tiers: { Pro: true, Enterprise: true },
        },
        {
          name: t("Export to Google Docs & Forms"),
          tiers: { Basic: t("Limited"), Pro: true, Enterprise: true },
        },
      ],
    },
    {
      name: t("Advanced Curriculum Creator"),
      features: [
        {
          name: t("Unlimited uses"),
          tiers: { Basic: t("3 uses / month"), Pro: true, Enterprise: true },
        },
        {
          name: t(
            "Standards Library (1 million+ worldwide standards) to align curriculum"
          ),
          tiers: { Basic: true, Pro: true, Enterprise: true },
        },
        {
          name: t("Save to Alayna Drive"),
          tiers: { Basic: true, Pro: true, Enterprise: true },
        },
        { name: t("Download as PDF"), tiers: { Pro: true, Enterprise: true } },
        {
          name: t("Export to Google Docs & Forms"),
          tiers: { Basic: t("Limited"), Pro: true, Enterprise: true },
        },
      ],
    },
    {
      name: t("Image Generator"),
      features: [
        {
          name: t("Unlimited uses"),
          tiers: { Basic: t("5 uses / month"), Pro: true, Enterprise: true },
        },
        // {
        //   name: t("Edit images"),
        //   tiers: { Pro: true, Enterprise: true },
        //   comingSoon: true,
        // },
      ],
    },
    {
      name: t("Collaborative Workspace (Drive & Document Editor)"),
      features: [
        {
          name: t("Unlimited Docs"),
          tiers: { Basic: true, Pro: true, Enterprise: true },
        },
        {
          name: t("AI Editor plugin"),
          tiers: { Basic: true, Pro: true, Enterprise: true },
        },
        {
          name: t("Share Docs publicly"),
          tiers: { Basic: true, Pro: true, Enterprise: true },
        },
        {
          name: t("Share to LMS"),
          tiers: { Enterprise: true },
          comingSoon: true,
        },
        { name: t("Download as PDF"), tiers: { Pro: true, Enterprise: true } },
        {
          name: t("Shared Drive for Organization"),
          tiers: { Enterprise: true },
        },
      ],
    },
    {
      name: t("School/District/Organization wide"),
      features: [
        { name: t("LMS/SIS Integrations"), tiers: { Enterprise: true } },
        { name: t("Single Sign-On"), tiers: { Enterprise: true } },
        {
          name: t("Admin Dashboard with Analytics"),
          tiers: { Enterprise: true },
        },
        {
          name: t("Free Teacher Professional Development"),
          tiers: { Enterprise: true },
        },
        {
          name: t("Office Hours + Dedicated Support"),
          tiers: { Enterprise: true },
        },
        { name: t("Custom Learning Standards"), tiers: { Enterprise: true } },
        { name: t("White-labeling"), tiers: { Enterprise: true } },
        {
          name: t("Curriculum Integrations"),
          tiers: { Enterprise: true },
          comingSoon: true,
        },
      ],
    },
  ];

  const [frequency, setFrequency] = useState<string>(frequencies[1].value);
  const [isLoading, setIsLoading] = useState(false);

  const activeFrequency: Frequency = useMemo(() => {
    return frequencies.find((f) => f.value === frequency) || frequencies[1];
  }, [frequency]);

  const disableSwitchingCycle = useMemo(() => {
    return user.subscriptionType && user.subscriptionInterval === "Annually";
  }, [user]);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<{
    type: EnumUserSubscriptionType;
    interval: EnumSubscriptionInterval;
  } | null>(null);

  const handleUpgradeOrCheckout = async (isBundle: boolean) => {
    const planType = isBundle
      ? EnumUserSubscriptionType.CopilotSlidesBundle
      : EnumUserSubscriptionType.Copilot;
    const planInterval =
      frequency === "monthly"
        ? EnumSubscriptionInterval.Monthly
        : EnumSubscriptionInterval.Annually;

    if (user.subscriptionType) {
      setSelectedPlan({ type: planType, interval: planInterval });
      setShowConfirmDialog(true);
    } else {
      await processPlanChange(planType, planInterval);
    }
  };

  const processPlanChange = async (
    type: EnumUserSubscriptionType,
    interval: EnumSubscriptionInterval
  ) => {
    setIsLoading(true);
    setShowConfirmDialog(false);
    try {
      if (user.subscriptionType) {
        // Upgrade subscription
        const { data, error } = await users.upgradeSubscription(type, interval);

        if (data) {
          addToast(t("Subscription updated successfully"), { type: "success" });
          auth.refreshSubscriptionUser(); // Refresh user data
        } else if (error) {
          addToast(error, { type: "error" });
        }
      } else {
        // Create new subscription
        const { data, error } = await users.createStripeCheckoutSession(
          type,
          interval
        );

        if (error) {
          addToast(error, { type: "error" });
        } else {
          window.location.href = data;
        }
      }
    } catch (error) {
      addToast(t("An error occurred. Please try again."), { type: "error" });
    } finally {
      setIsLoading(false);
      setShowConfirmDialog(false);
    }
  };

  if (auth.refreshingUserSubscription) {
    return (
      <div className="mt-16 flex justify-center">
        <div className="text-center">
          <Spinner size={16} color="blue" />
          <div className="text-slate11 ml-2 text-sm font-medium">
            {t("Fetching updated subscription...")}
          </div>
        </div>
      </div>
    );
  }

  const isBundleActive =
    user.subscriptionType === "CopilotSlidesBundle" &&
    user.subscriptionInterval ===
      (frequency === "monthly" ? "Monthly" : "Annually");

  return (
    <React.Fragment>
      <div className="mt-16 flex justify-center">
        <RadioGroup
          value={frequency}
          onChange={setFrequency}
          disabled={disableSwitchingCycle}
          className="grid grid-cols-2 gap-x-1 rounded-lg p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
        >
          <RadioGroup.Label className="sr-only">
            Payment frequency
          </RadioGroup.Label>
          {frequencies.map((option) => (
            <RadioGroup.Option
              key={option.value}
              value={option.value} // Pass the value string
              className={({ checked }) =>
                clsx(
                  checked ? "bg-blue-600 text-white" : "text-gray-500",
                  "cursor-pointer rounded-lg px-3 py-2 flex items-center justify-center flex-shrink-0"
                )
              }
            >
              <span className="text-lg">{option.label}</span>
              {option.value === "annually" &&
                import.meta.env.VITE_APP_ANNUAL_DISCOUNT_PERCENTAGE && (
                  <span className="ml-3 inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-600 ring-1 ring-inset ring-gray-500/10">
                    {import.meta.env.VITE_APP_ANNUAL_DISCOUNT_PERCENTAGE}%{" "}
                    {t("less")}
                  </span>
                )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>

      {/* Information Alert if already subscribed to Slides */}
      {user.subscriptionType === "Slides" && (
        <div className="flex justify-center">
          <div className="mt-16 text-cente rounded-lg w-full max-w-7xl relative overflow-hidden">
            <div className="bg-blue-100 p-4 rounded-md">
              <div className="text-blue11 text-lg">
                You are currently subscribed to Alayna's Slides Generator. You
                can upgrade to the bundle to unlock Alayna Copilot Pro.
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Confirmation Dialog */}
      <Dialog.Root
        open={showConfirmDialog}
        onOpenChange={(open) => setShowConfirmDialog(open)}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0 z-[100000]" />
          <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none z-[100001]">
            <Dialog.Title className="text-lg font-bold mb-4">
              {t("Confirm Plan Upgrade")}
            </Dialog.Title>
            <Dialog.Description className="mb-4">
              {t(
                "Are you sure you want to upgrade to the {{planName}} - {{interval}} plan?",
                {
                  planName:
                    selectedPlan?.type ===
                    EnumUserSubscriptionType.CopilotSlidesBundle
                      ? "AI Copilot + Slides Generator Bundle"
                      : "AI Copilot Pro",
                  interval:
                    selectedPlan?.interval === EnumSubscriptionInterval.Monthly
                      ? t("Monthly")
                      : t("Yearly"),
                }
              )}
              <br />
              <br />
              Note, you will be charged a{" "}
              <a
                target="_blank"
                href="https://docs.stripe.com/billing/subscriptions/prorations"
                className="text-blue-600 underline"
              >
                prorated amount
              </a>{" "}
              based upon the remaining duration of your current plan.
            </Dialog.Description>
            <div className="flex justify-end gap-4">
              <ButtonLarge
                type="button"
                theme="secondary"
                onClick={() => setShowConfirmDialog(false)}
                buttonText={t("Cancel")}
                rounded="medium"
              />
              <ButtonLarge
                type="button"
                theme="primary"
                onClick={() => {
                  if (selectedPlan) {
                    processPlanChange(selectedPlan.type, selectedPlan.interval);
                  }
                }}
                buttonText={t("Confirm Upgrade")}
                rounded="medium"
              />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>

      {/* New section for AI Copilot + Slides Generator Bundle */}
      <div className="flex justify-center">
        <div className="mt-16 text-center bg-slate3 rounded-lg w-full max-w-7xl p-6 relative overflow-hidden">
          {/* Gradient border pseudo-element */}
          <div
            className="absolute inset-0 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-lg"
            style={{ padding: "5px" }}
          >
            <div className="h-full w-full bg-slate3 rounded-lg"></div>
          </div>

          {/* Content */}
          <div className="relative z-10 py-4">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t("AI Copilot + Slides Generator Bundle")}
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t(
                "Get the best of both products with our special bundle offer!"
              )}
            </p>
            <div className="mt-10 flex flex-col items-center justify-center">
              <div className="flex items-baseline">
                <span className="text-5xl font-bold tracking-tight text-gray-900">
                  {frequency === "monthly" ? "$11.99" : "$119"}
                </span>
                <span className="text-lg font-semibold leading-6 tracking-wide text-gray-600 ml-2">
                  {frequency === "monthly" ? t("/month") : t("/year")}
                </span>
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <span className="line-through mr-2 text-3xl">
                  {frequency === "monthly" ? "$15" : "$147"}
                </span>
                <span className="text-green-600 font-semibold text-2xl">
                  {frequency === "monthly" ? t("Save 33%") : t("Save 19%")}
                </span>
              </div>
              <div className="flex gap-4">
                <ButtonLarge
                  fullWidth={false}
                  theme="primary"
                  type="button"
                  className="mt-8"
                  width="200"
                  onClick={async () => {
                    if (!isBundleActive) {
                      await handleUpgradeOrCheckout(true);
                    }
                  }}
                  disabled={isLoading || isBundleActive}
                  rounded="medium"
                  buttonText={
                    isLoading
                      ? t("Processing...")
                      : isBundleActive
                      ? t("Current Plan")
                      : t("Get the Bundle")
                  }
                />

                <ButtonLarge
                  fullWidth={false}
                  theme="secondary"
                  type="button"
                  className="mt-8"
                  onClick={() => {
                    window.open(
                      "https://workspace.google.com/marketplace/app/alayna_ai_for_google_slides/503455951279",
                      "_blank"
                    );
                  }}
                  rounded="medium"
                  buttonText={t("Try the Slides Generator")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center mt-20 mb-10">
        {t("Alayna Copilot Plans & Feature Breakdown")}
      </h2>

      {/* xs to lg */}
      <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
        {tiers.map((tier) => {
          const isActive =
            tier.id === "tier-pro" &&
            user.subscriptionType === "Copilot" &&
            user.subscriptionInterval ===
              (frequency === "monthly" ? "Monthly" : "Annually");

          const isDisabled =
            user.subscriptionType === "Slides" ||
            user.subscriptionType === "CopilotSlidesBundle";

          console.log("Tier", tier.id);
          console.log("isActive", isActive);
          console.log("isDisabled", isDisabled);

          console.log(
            "isLoading || isActive || isDisabled",
            isLoading || isActive || isDisabled
          );

          return (
            <section
              key={tier.id}
              className={clsx(
                tier.mostPopular
                  ? "rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200"
                  : "",
                "p-8"
              )}
            >
              <h3
                id={tier.id}
                className={`mt-6 text-sm font-semibold text-${tier.color}-600`}
              >
                {tier.name}
              </h3>
              <p className="mt-2 flex items-baseline gap-x-1 text-gray-900">
                <span className="text-4xl font-bold">
                  {frequency === "monthly"
                    ? tier.discountedPriceMonthly || tier.priceMonthly
                    : tier.discountedPriceAnnual || tier.priceAnnual}
                </span>
                <span className="text-sm font-semibold">
                  {activeFrequency.priceSuffix}
                </span>
                {tier.discountPercentage &&
                  activeFrequency.value === "annually" && (
                    <span className="ml-2 text-sm text-green-600">
                      {tier.discountPercentage}
                    </span>
                  )}
              </p>
              <ButtonLarge
                fullWidth={true}
                theme={tier.id === "tier-pro" ? "primary" : "secondary"}
                type="button"
                className="mt-8"
                onClick={async () => {
                  if (tier.id === "tier-pro") {
                    await handleUpgradeOrCheckout(false);
                    // Upgrade to Copilot Pro
                  } else if (tier.id === "tier-enterprise") {
                    window.open(
                      "https://calendly.com/hrishi-vora/alayna-intro",
                      "_blank"
                    );
                  }
                }}
                rounded="medium"
                disabled={isLoading || isActive || isDisabled}
                buttonText={
                  isLoading
                    ? t("Processing...")
                    : isActive
                    ? t("Current Plan")
                    : tier.id === "tier-pro" &&
                      user.subscriptionType === "Copilot"
                    ? t("Upgrade")
                    : tier.id === "tier-pro"
                    ? "Subscribe"
                    : tier.buttonText
                }
              />
              <ul
                role="list"
                className="mt-10 space-y-8 text-sm leading-6 text-gray-900"
              >
                {sections.map((section) => (
                  <li key={section.name}>
                    {/* Feature name */}
                    <h4 className="font-semibold text-gray-900 text-xl mb-4">
                      {section.name}
                    </h4>
                    {/* Feature list */}
                    <ul role="list" className="space-y-4">
                      {section.features.map((feature) =>
                        feature.tiers[tier.name] ? (
                          <li key={feature.name} className="flex gap-x-3">
                            <CheckCircleIcon
                              className="h-6 w-5 flex-none text-green-600"
                              aria-hidden="true"
                            />
                            <span>
                              {feature.name}{" "}
                              {typeof feature.tiers[tier.name] === "string" ? (
                                <span className="text-sm leading-6 text-gray-500">
                                  ({feature.tiers[tier.name]})
                                </span>
                              ) : null}
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          );
        })}
      </div>

      {/* lg+ */}
      <div className="isolate mt-20 hidden lg:block">
        <div className="relative">
          {tiers.some((tier) => tier.mostPopular) ? (
            <div className="absolute inset-x-0 inset-y-0 -z-10 flex">
              <div
                className="flex w-1/4"
                aria-hidden="true"
                style={{
                  marginLeft: `${
                    (tiers.findIndex((tier) => tier.mostPopular) + 1) * 25
                  }%`,
                }}
              >
                <div className="w-full border-x border-t border-gray-900/10 bg-gray-400/5" />
              </div>
            </div>
          ) : null}
          <table className="w-full table-fixed border-separate text-left">
            <caption className="sr-only">Pricing plan comparison</caption>
            <colgroup>
              <col className="w-1/4" />
              <col className="w-1/4" />
              <col className="w-1/4" />
              <col className="w-1/4" />
            </colgroup>
            <thead className="sticky top-0 z-10 border-b border-gray-900/10 border-solid opacity-80">
              <tr>
                <td />
                {tiers.map((tier) => (
                  <th
                    key={tier.id}
                    scope="col"
                    className={clsx(
                      "px-6 py-6 xl:px-8 xl:pt-8 border-b border-gray-900/10",
                      tier.mostPopular ? "bg-gray-100" : "bg-white"
                    )}
                  >
                    <div
                      className={`mt-6 text-4xl font-semibold text-${tier.color}-600`}
                    >
                      {tier.name}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <span className="sr-only">Price</span>
                </th>
                {tiers.map((tier) => {
                  const isActive =
                    tier.id === "tier-pro" &&
                    user.subscriptionType === "Copilot" &&
                    user.subscriptionInterval ===
                      (frequency === "monthly" ? "Monthly" : "Annually");

                  const isDisabled =
                    (user.subscriptionType === "Slides" ||
                      user.subscriptionType === "CopilotSlidesBundle") &&
                    tier.id === "tier-pro";

                  return (
                    <td key={tier.id} className="px-6 pt-2 xl:px-8">
                      <div className="flex flex-col">
                        <div className="flex items-baseline gap-x-1 mt-4 text-gray-900">
                          {frequency === "monthly" &&
                          tier.discountedPriceMonthly ? (
                            <span className="text-2xl font-semibold leading-6 text-slate11 mr-1 line-through">
                              {tier.priceMonthly}
                            </span>
                          ) : tier.discountedPriceAnnual ? (
                            <span className="text-2xl font-semibold leading-6 text-slate11 mr-1 line-through">
                              {tier.priceAnnual}
                            </span>
                          ) : null}

                          <span className="text-2xl font-bold">
                            {frequency === "monthly"
                              ? tier.discountedPriceMonthly || tier.priceMonthly
                              : tier.discountedPriceAnnual || tier.priceAnnual}
                          </span>
                          {tier.id === "tier-pro" && (
                            <span className="text-sm font-semibold leading-6">
                              {activeFrequency.priceSuffix}
                            </span>
                          )}
                        </div>
                        <div className="h-4 mt-2">
                          {/* Show badge for discount percentage */}
                          {tier.discountPercentage && (
                            <div className="ml-2 inline-flex items-center px-2 py-1 rounded-full bg-pink-50 text-md font-semibold text-pink-600">
                              {t("Back to School offer - Limited time")}
                            </div>
                          )}
                        </div>
                      </div>
                      <p className="text-sm leading-6 text-gray-900 mt-8">
                        {tier.description}
                      </p>

                      <ButtonLarge
                        fullWidth={true}
                        theme={tier.id === "tier-pro" ? "primary" : "secondary"}
                        type="button"
                        className="mt-8"
                        onClick={async () => {
                          if (tier.id === "tier-pro") {
                            await handleUpgradeOrCheckout(false);
                            //  Upgrade to copilot Pro
                          } else if (tier.id === "tier-enterprise") {
                            window.open(
                              "https://calendly.com/hrishi-vora/alayna-intro",
                              "_blank"
                            );
                          }
                        }}
                        rounded="medium"
                        disabled={isLoading || isActive || isDisabled}
                        buttonText={
                          isLoading
                            ? t("Processing...")
                            : isActive
                            ? t("Current Plan")
                            : tier.id === "tier-pro" &&
                              user.subscriptionType === "Copilot"
                            ? t("Upgrade")
                            : tier.id === "tier-pro"
                            ? "Subscribe"
                            : tier.buttonText
                        }
                      />
                    </td>
                  );
                })}
              </tr>

              {/* Features */}
              {sections.map((section, sectionIdx) => (
                <React.Fragment key={section.name}>
                  <tr>
                    <th
                      scope="colgroup"
                      colSpan={4}
                      className={clsx(
                        sectionIdx === 0 ? "pt-8" : "pt-16",
                        "pb-4 text-xl font-semibold leading-6 text-gray-900"
                      )}
                    >
                      {section.name}
                      {section.comingSoon && (
                        <span className="ml-2 inline-flex items-center px-3 py-1 rounded-full bg-pink-50 text-sm font-semibold text-pink-600">
                          {t("Coming soon")}
                        </span>
                      )}
                      <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th
                        scope="row"
                        className="py-4 text-md font-normal leading-6 text-gray-900"
                      >
                        {feature.name}
                        {feature.comingSoon && (
                          <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full bg-blue-50 text-xs font-semibold text-blue-600">
                            {t("Coming soon")}
                          </span>
                        )}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                      </th>
                      {tiers.map((tier) => (
                        <td key={tier.id} className="px-6 py-4 xl:px-8">
                          {typeof feature.tiers[tier.name] === "string" ? (
                            <div className="text-center text-md leading-6 text-gray-500">
                              {feature.tiers[tier.name]}
                            </div>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckCircleIcon
                                  className="mx-auto h-6 w-6 text-green-600"
                                  aria-hidden="true"
                                />
                              ) : (
                                <MinusIcon
                                  className="mx-auto h-6 w-6 text-gray-400"
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">
                                {feature.tiers[tier.name] === true
                                  ? "Included"
                                  : "Not included"}{" "}
                                in {tier.name}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}

export default observer(PricingComponent);
