import { useCallback, useState } from "react";
import { useModalContext } from "../../contexts/modals";
import { useToastsContext } from "../../contexts/toasts";
import useStores from "../../hooks/useStores";
import { useTranslation } from "react-i18next";

export default function Password() {
  const { auth } = useStores();
  const { addToast } = useToastsContext();
  const { closeModalAll } = useModalContext();

  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");

  const submit = useCallback(async () => {
    // validation checks for course...
    if (newPassword.trim() === "") {
      addToast(t("Password cannot be empty."), { type: "error" });
      return;
    }
    if (newPassword.length < 12) {
      addToast(t("Password must be at least 12 characters."), {
        type: "error",
      });
      return;
    }
    if (newPassword !== confirmNewPassword) {
      addToast(t("Passwords do not match."), { type: "error" });
      return;
    }
    if (currentPassword.trim() === "") {
      addToast(t("Current password cannot be empty."), { type: "error" });
      return;
    }
    const updated = await auth.setNewPassword(currentPassword, newPassword);
    if (updated && updated.success) {
      closeModalAll();
      addToast(t("Password updated successfully."), { type: "success" });
    } else {
      addToast(t("Your current password is incorrect. Please try again."), {
        type: "error",
      });
    }
  }, [newPassword, confirmNewPassword, currentPassword]);

  return (
    <div className="flex justify-center">
      <div className="w-[400px] max-w-md flex-col">
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          {t("Current Password")}
        </label>
        <div className="mt-1">
          <input
            value={currentPassword}
            onChange={(e) => {
              setCurrentPassword(e.target.value);
            }}
            type="password"
            name="password"
            id="password"
            autoComplete="current-password"
            required={true}
            className="focus:ring-primary-500 focus:border-primary-500 mb-5 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
          />
        </div>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          {t("New Password")}
        </label>
        <div className="mt-1">
          <input
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            type="password"
            name="password"
            id="password"
            autoComplete="current-password"
            required={true}
            className="focus:ring-primary-500 focus:border-primary-500 mb-5 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
          />
        </div>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          {t("Confirm New Password")}
        </label>
        <div className="mt-1">
          <input
            onChange={(e) => {
              setConfirmNewPassword(e.target.value);
            }}
            value={confirmNewPassword}
            type="password"
            name="password"
            id="password"
            autoComplete="current-password"
            required={true}
            className="focus:ring-primary-500 focus:border-primary-500 mb-5 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
          />
        </div>
        <div className="pt-7 text-right">
          <button
            // disabled={isSubmitting}
            onClick={() => {
              submit();
            }}
            type="button"
            className="bg-primary-700 hover:bg-primary-700 focus:ring-primary-500 inline-flex items-center rounded-md border border-transparent px-6 py-2 text-sm font-medium leading-4 text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            {t("Submit")}
          </button>
        </div>
      </div>
    </div>
  );
}
