import { observable } from "mobx";
import Attribute from "./decorators/Attribute";

import PersistModel from "./PersistModel";

class DemoAiToolStep extends PersistModel {
  @Attribute
  @observable
  id: string;

  @Attribute
  @observable
  data: any;

  @Attribute
  @observable
  aiToolId: string;

  @Attribute
  @observable
  stepId: string;
}

export default DemoAiToolStep;
