import { ApolloClient } from "@apollo/client";
import { action, autorun, computed, observable } from "mobx";
import { v4 as uuidV4 } from "uuid";
import { RetrieveSandboxUses } from "../graphql/demo/demo.queries";
import LocalStorage from "../utils/LocalStorage";
import { EnumUserLanguage } from "../__generated__/graphql";

const STORE_NAME = "DEMO";

export default class DemoStore {
  @observable
  isLoading = false;

  @observable
  clientId: string;

  apolloClient: ApolloClient<any>;

  @observable
  language: string = EnumUserLanguage.En;

  @observable
  chatUses: number;

  @observable
  advancedCurriculumToolUses: number;

  @observable
  instantResourceUses: number;

  @observable
  imageGeneratorUses: number;

  @computed
  get validSession(): boolean {
    return !!this.clientId;
  }

  //

  @action
  setChatUses = (count: number) => {
    this.chatUses = count;
  };

  @action
  setAdvancedCurriculumToolUses = (count: number) => {
    this.advancedCurriculumToolUses = count;
  };

  @action
  setInstantResourceUses = (count: number) => {
    this.instantResourceUses = count;
  };

  @action
  setImageGeneratorUses = (count: number) => {
    this.imageGeneratorUses = count;
  };

  // We are storing differentiated resource generator init status model
  @observable
  differentiatedResourcesInitModelMap: {
    [key: string]: {
      id: string;
      processedCount: number;
      totalCount: number;
      logs: string[];
      status: string;
      error?: string | null;
      aiFlaggedContent?: boolean | null;
    };
  } = {};

  constructor(apolloClient: ApolloClient<any>) {
    // Rehydrate
    const data: Partial<DemoStore> = LocalStorage.retrieve(STORE_NAME) || {};

    this.apolloClient = apolloClient;

    // Set the values for the keys we have in storage
    this.clientId = data.clientId || uuidV4();
    this.language = data.language || EnumUserLanguage.En;
    this.chatUses = data.chatUses || 0;
    this.advancedCurriculumToolUses = data.advancedCurriculumToolUses || 0;
    this.instantResourceUses = data.instantResourceUses || 0;
    this.imageGeneratorUses = data.imageGeneratorUses || 0;

    autorun(() => {
      LocalStorage.save(STORE_NAME, this.toLocalLocalStorageData);
    });

    autorun(() => {
      if (this.clientId) {
        this.retrieveSandboxUses();
      }
    });
  }

  @computed
  get toLocalLocalStorageData() {
    return {
      clientId: this.clientId,
      language: this.language,
      chatUses: this.chatUses,
      advancedCurriculumToolUses: this.advancedCurriculumToolUses,
      instantResourceUses: this.instantResourceUses,
      imageGeneratorUses: this.imageGeneratorUses,
    };
  }

  // We are storing differentiated resource generator init status model
  @action
  setDifferentiatedResourcesToolInit(
    id: string,
    processedCount: number,
    totalCount: number,
    logs: string[],
    status: string,
    error?: string | null,
    aiFlaggedContent?: boolean | null
  ) {
    let updateDifferentiatedResourceMap = {
      ...this.differentiatedResourcesInitModelMap,
    };

    if (updateDifferentiatedResourceMap[id]) {
      updateDifferentiatedResourceMap[id] = {
        ...updateDifferentiatedResourceMap[id],
        processedCount,
        totalCount,
        logs,
        status,
        error,
        aiFlaggedContent,
      };
    } else {
      updateDifferentiatedResourceMap[id] = {
        id,
        processedCount,
        totalCount,
        logs,
        status,
        error,
        aiFlaggedContent,
      };
    }

    this.differentiatedResourcesInitModelMap = updateDifferentiatedResourceMap;
  }

  @action
  removeDifferentiatedResourcesToolInit(id: string) {
    let updateDifferentiatedResourceMap = {
      ...this.differentiatedResourcesInitModelMap,
    };

    if (updateDifferentiatedResourceMap[id]) {
      delete updateDifferentiatedResourceMap[id];
    }

    this.differentiatedResourcesInitModelMap = updateDifferentiatedResourceMap;
  }

  @action
  async retrieveSandboxUses(): Promise<Boolean> {
    this.isLoading = true;

    try {
      const res = await this.apolloClient.query({
        query: RetrieveSandboxUses,
        variables: {
          clientId: this.clientId,
        },
      });

      if (!res.data || !res.data.retrieveSandboxUses) {
        throw new Error("No data returned from demoAiTool mutation");
      }

      const {
        chatUses,
        advancedCurriculumToolUses,
        instantResourceUses,
        imageGeneratorUses,
      } = res.data.retrieveSandboxUses;

      this.chatUses = chatUses;
      this.advancedCurriculumToolUses = advancedCurriculumToolUses;
      this.instantResourceUses = instantResourceUses;
      this.imageGeneratorUses = imageGeneratorUses;

      return true;
    } catch (e) {
      throw e;
    } finally {
      this.isLoading = false;
    }
  }

  @action
  clear = () => {
    this.clientId = uuidV4();
    this.language = EnumUserLanguage.En;
    this.chatUses = 0;
    this.advancedCurriculumToolUses = 0;
    this.instantResourceUses = 0;
    this.imageGeneratorUses = 0;
  };
}
